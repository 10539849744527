import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import Sidebar from '../../components/sidebar';
import Stastics from '../../components/stasticsHeader';
import Header from '../../components/header';
import { GetReport, GetLinksReport, GetPortsReport } from '../../actions/reports';
import { GetPorts } from '../../actions/ports';

const ReportsPageBrokenLinks = () => {

  const [brokenLinksStartDate, setbrokenLinksStartDate] = useState(new Date());
  const [brokenLinksEndDate, setbrokenLinksEndDate] = useState(new Date());

  const dispatch = useDispatch();
  const linksreport = useSelector(state => state.report.links_report);

  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;
 
  function generateLinksReport() {
    dispatch(GetLinksReport(brokenLinksStartDate, brokenLinksEndDate));
  }

  
  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            
            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <p className="card-title">Broken Links Report</p>
                  <div className="card-body">
                    <div className="table-responsive">


                      <div className="boxinside">
                        <span style={{ "fontSize": "14px" }}>Start date</span><br />
                        <DatePicker selected={brokenLinksStartDate} onChange={(date) => setbrokenLinksStartDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <div className="boxinside" style={{ "marginLeft": "10px", "marginRight": "10px" }} >
                        <span style={{ "fontSize": "14px" }}>End date</span><br />
                        <DatePicker selected={brokenLinksEndDate} onChange={(date) => setbrokenLinksEndDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <p className="boxinside">
                        <br />
                        <button type="button" onClick={generateLinksReport} className="btn btn-inverse-primary btn-fw">Generate Report</button>
                      </p>

                    </div>

                    <div style={{ "BorderBottom": " 1px solid #ccc", "margin": "20px 0" }}>

                      
                      <h6>{(Object.keys(linksreport).length === 0 && linksreport.constructor === Object)? "" : "Fixed Links : " + linksreport.totalNumberOfFixedLinks }</h6>
                      <h6>{(Object.keys(linksreport).length === 0 && linksreport.constructor === Object)? "" : "Broken Links : " + linksreport.totalNumberOfBrokenLinks }</h6>
                      { /*
                      <h5>
                       Average Down Time : {getAverageTotalDownTime()} 
                      </h5>
                      */}
                    </div>

                    <div style={{ "marginTop ": "10px" }} className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Broken Link</th>
                            <th>http code</th>
                            <th>Record Date</th>
                            <th>Fixed Date</th>
                            <th>Duration To Be Fixed</th>

                          </tr>
                        </thead>
                        <tbody>
                          {(Object.keys(linksreport).length === 0 && linksreport.constructor === Object)
                            ?
                            <></>
                            :
                            linksreport.fixedLinks.map(item => {

                              const createdAt = new Date(`${item.created}`);
                              const updatedAt = new Date(`${item.updated}`);

                              return (
                                <tr key={item._id}>
                                  <td>{item.brokenlink}</td>
                                  <td>{item.code}</td>
                                  <td>{
                                         createdAt.getDate() + "-"+ (parseInt(createdAt.getMonth()) + 1) + "-"+ createdAt.getFullYear() + " At "+
                                         createdAt.getHours()+"hrs:"+  createdAt.getMinutes() + "min" 
                                      }
                                  </td>
                                  <td>{
                                         updatedAt.getDate() + "-"+ (parseInt(updatedAt.getMonth()) + 1) + "-"+ updatedAt.getFullYear() + " At "+
                                         updatedAt.getHours()+"hrs:"+ updatedAt.getMinutes() + "min" 
                                      }
                                  </td>
                                  <td>

                                    {(item.durationFixedAt.days == 0) ? "" : `${item.durationFixedAt.days} days `}
                                    {(item.durationFixedAt.hours == 0) ? "" : `${item.durationFixedAt.hours} hrs `}
                                    {(item.durationFixedAt.minutes == 0) ? "" : `${item.durationFixedAt.minutes} min `}
                                    {(item.durationFixedAt.seconds == 0) ? "" : `${item.durationFixedAt.seconds} sec`}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default ReportsPageBrokenLinks;