import React from 'react';
import { Link } from 'react-router-dom';


const Sidebar = (props) => {

    const { plan } = props;


    return (

        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                <li className="nav-item">
                    <Link className="nav-link" to="/">
                        <i className="mdi mdi-home menu-icon"></i>
                        <span className="menu-title">Dashboard</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/uptime">
                        <i className="mdi mdi-pulse menu-icon"></i>
                        <span className="menu-title">Uptime check</span>
                    </Link>
                </li>
                {(plan === "basic" || plan === "advanced" || plan === "complete")
                    ?
                    <li className="nav-item">
                        <Link className="nav-link" to="/ssl">
                            <i className="mdi mdi-key menu-icon"></i>
                            <span className="menu-title">SSL</span>
                        </Link>
                    </li>
                    :
                    ""
                }

                {(plan === "advanced" || plan === "complete")
                    ?
                    <li className="nav-item">
                        <Link className="nav-link" data-toggle="collapse" to="/links" aria-expanded="false" aria-controls="ui-basic">
                            <i className="mdi mdi-link-variant-off menu-icon"></i>
                            <span className="menu-title">Broken links </span>
                        </Link>
                    </li>
                    :
                    ""
                }

                {(plan === "advanced" || plan === "complete")
                    ?
                    <li className="nav-item">
                        <Link className="nav-link" to="/ports">
                            <i className="mdi mdi-server menu-icon"></i>
                            <span className="menu-title">Ports</span>
                        </Link>
                    </li>
                    :
                    ""
                }

                {(plan === "complete")
                    ?
                    <li className="nav-item">
                        <Link className="nav-link" to="/searchranking">
                            <i className="mdi mdi-view-headline menu-icon"></i>
                            <span className="menu-title">Keyword Search Rank</span>
                        </Link>
                    </li>
                    :
                    ""
                }
                {/* <li className="nav-item">
                    <Link className="nav-link" to="/socialmedia">
                        <i className="mdi mdi-laptop-chromebook menu-icon"></i>
                        <span className="menu-title">Social media mentioning</span>
                    </Link>
                </li> */}

               {/*<li class="nav-item">
                    <Link className="nav-link" to="/phones">
                    <i className="mdi mdi-cellphone-basic menu-icon"></i>
                        <span className="menu-title">Phones</span>
                    </Link>
                </li>*/}

                <li className="nav-item">
                    <Link className="nav-link" to="/heatmap">
                    <i className="mdi mdi-map menu-icon"></i>
                        <span className="menu-title">Heat Maps</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                        <i className="mdi mdi-chart-pie menu-icon"></i>
                        <span className="menu-title">Reports</span>
                        <i className="menu-arrow text-white"></i>
                    </a>
                    <div className="collapse" id="ui-basic">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className="nav-link" to="/report_uptime">
                                    <span className="menu-title">Down Time</span>
                                </Link>
                            </li>

                            {(plan === "basic" || plan === "advanced" || plan === "complete")
                                ?
                                <li className="nav-item">
                                    <Link className="nav-link" to="/report_ssl">
                                        <span className="menu-title">SSL</span>
                                    </Link>
                                </li>
                                :
                                ""
                            }
                            {(plan === "advanced" || plan === "complete")
                                ?
                                <li className="nav-item">
                                    <Link className="nav-link" to="/report_brokenlinks">
                                        <span className="menu-title">Broken Links</span>
                                    </Link>
                                </li>
                                :
                                ""
                            }

                            {(plan === "advanced" || plan === "complete")
                                ?
                                <li className="nav-item">
                                    <Link className="nav-link" to="/report_ports">
                                        <span className="menu-title">Ports/Servers</span>
                                    </Link>
                                </li>
                                :
                                ""
                            }
                            {(plan === "complete")
                                ?
                                <li className="nav-item">
                                    <Link className="nav-link" to="/report_searchrank">
                                        <span className="menu-title">Search Ranking</span>
                                    </Link>
                                </li>
                                :
                                ""
                            }

                        </ul>
                    </div>
                </li>



                <li className="nav-item">
                    <a className="nav-link" data-toggle="collapse" href="#ui-settings" aria-expanded="false" aria-controls="ui-basic">
                        <i className="mdi mdi-settings menu-icon"></i>
                        <span className="menu-title">Settings</span>
                        <i className="menu-arrow text-white"></i>
                    </a>
                    <div className="collapse" id="ui-settings">
                        <ul className="nav flex-column sub-menu">
                            <li className="nav-item">
                                <Link className="nav-link" to="/payment">
                                    <span className="menu-title">Payments</span>
                                </Link>
                                <Link className="nav-link" to="/account">
                                    <span className="menu-title">Account</span>
                                </Link>
                                <Link className="nav-link" to="/subscriptions">
                                    <span className="menu-title">Subscription</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

            </ul>

        </nav>

    )
}
export default Sidebar;