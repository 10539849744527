import axios from 'axios';

async function reportError(action, error) {
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if (responseMessage === "error logged successfully") {
        //console.log("error repotred successfully");
    }
}


export const GetReport = (startDate, endDate) => async (dispatch) => {
    try {
        const response = await axios.post('api/reports/downtipmereport', { startDate, endDate }, { withCredentials: true });
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        } else {

            if (responseMessage === "no uptimes found") {
                dispatch({
                    type: 'DOWN_REPORT',
                    payload: {}
                });

            } else {
                dispatch({
                    type: 'DOWN_REPORT',
                    payload: response.data
                });
            }
        }
    }
    catch (err) {
        reportError("GetReport", err);
    }
}



export const GetLinksReport = (startDate, endDate) => async (dispatch) => {

    try {
        const response = await axios.post('api/reports/linksreport', { startDate, endDate }, { withCredentials: true });
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        } else {
            if (responseMessage === "no brokenlinks found") {
                dispatch({
                    type: 'LINKS_REPORT',
                    payload: {}
                });

            } else {
                dispatch({
                    type: 'LINKS_REPORT',
                    payload: response.data
                });
            }
        }
    }
    catch (err) {
        reportError("GetLinksReport", err);
    }
}




export const GetPortsReport = (startDate, endDate, server) => async (dispatch) => {
    try {
        const response = await axios.post('api/reports/portsreport', { START_DATE: startDate, END_DATE: endDate, hostServer: server }, { withCredentials: true });
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        } else {
            if (responseMessage === "no down time found") {
                dispatch({
                    type: 'PORTS_REPORT',
                    payload: {}
                });
            } else {
                dispatch({
                    type: 'PORTS_REPORT',
                    payload: response.data
                });
            }
        }
    }
    catch (err) {
        reportError("GetPortsReport", err);
    }
}
