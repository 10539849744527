import axios from 'axios';



async function reportError(action, error) {
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if (responseMessage === "error logged successfully") {
        // console.log("error repotred successfully");
    }
}

export const GenerateClientTrackingtCode = () => async (dispatch) => {

    try {
        const response = await axios.post('api/heatmap/generateClientTrackingCode', { withCredentials: true });
        const responseMessage = response.data.message;
        const responseError = response.data.error;

        console.log(" Response message : ", responseMessage)

        if (responseMessage === "error occured") {
            // flags an error to be shown
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000);
            //error content 
            dispatch({
                type: 'GENERATE_CLIENT_CODE_ERROR',
                payload: responseError
            });
            setTimeout(() => {
                dispatch({
                    type: 'GENERATE_CLIENT_CODE_ERROR',
                    payload: ""
                })
            }, 80000)

        } else {
            dispatch({
                type: 'CLIENTCODE_AND_URL',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("AddPhone", err);
    }
}

export const Visualize = (heatMapTrackingInfo) => async (dispatch) => {
    try {
        const {trackingCode, pageUrl } = heatMapTrackingInfo;
        dispatch({
            type :"SHOW_VISUALIZE",
            payload : true 
        })
       const response = await axios.post('/api/heatmap/visualize', { clientId: trackingCode, pageUrl: pageUrl,  eventType: "mousemove" }, { withCredentials: true });
        const responseMessage = response.data.message;

        if (responseMessage === "success") {
            console.log(response.data.data );
            dispatch({
                type: "VISUALIZE",
                payload: response.data.data 
            });
        }
    }
    catch (error) {
        console.error('Error visualizing heatmap data:', error);
    }
};


export const GetClientTrackingCode = () => async (dispatch) => {

    try {
        const response = await axios.post('api/heatmap/getClientTrackingCode', { withCredentials: true });
        const responseMessage = response.data.message;
        const responseError = response.data.error;
        if (responseMessage === "error occured") {
            // flags an error to be shown
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000);
            //error content 
            dispatch({
                type: 'GENERATE_CLIENT_CODE_ERROR',
                payload: responseError
            });
            setTimeout(() => {
                dispatch({
                    type: 'GENERATE_CLIENT_CODE_ERROR',
                    payload: ""
                })
            }, 80000)

        }
        else if (responseMessage === "no tracking code found") {
            dispatch({
                type: 'CLIENTCODE',
                payload: "none"
            });
        }

        else {
            dispatch({
                type: 'CLIENTCODE_AND_URL',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetPort", err);
    }
}

export const IsTracking = () => async (dispatch, getState) => {

    try {
        const response = await axios.post('api/heatmap/isTracking', { withCredentials: true });
        const responseMessage = response.data.message;
        const responseError = response.data.error;

        if (responseMessage === "error occured") {
            // flags an error to be shown
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000);
            //error content 
            dispatch({
                type: 'GENERATE_CLIENT_CODE_ERROR',
                payload: responseError
            });
            setTimeout(() => {
                dispatch({
                    type: 'GENERATE_CLIENT_CODE_ERROR',
                    payload: ""
                })
            }, 80000);
        }
        if (responseMessage === "no interactions found") {
            dispatch({
                type: "IS_TRACKING",
                payload: false
            });
        }
        if (responseMessage === "interactions found") {
            dispatch({
                type: 'IS_TRACKING',
                payload: true
            });
        }
    }
    catch (err) {
        reportError("GetPort", err);
    }
}


