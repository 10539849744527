import axios from 'axios';
import { passwordStrength } from 'check-password-strength';


async function reportError(action, error){
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if(responseMessage === "error logged successfully"){
       // console.log("error repotred successfully");
    }
}

// clear login UI messages
export const CleanLoginMessages = () => {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_LOGIN_UI_MESSAGES',
            payload: false
        })
    }
}
// Login action
export const Login = (user) => async dispatch => {
    try {

        const response = await axios.post('api/account/login', { user}, { withCredentials: true });
        const responseMessage = response.data.message;

       // console.log("--------------------------");
       // console.log(responseMessage);

       // console.log("response : ", responseMessage)
        //console.log(response.data);

        if (responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload : true
            });
            setTimeout(() => {
                dispatch({ 
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload : false 
                })
            }, 9000)
        }
        if (responseMessage === "login failed"){
            dispatch({
                type: 'LOGIN_FAILED',
            });
            setTimeout(() => {
                dispatch({ type: 'CLEAR_LOGIN_UI_MESSAGES' })
            }, 3000);
        }

        if(responseMessage === "Login successful" ){
            // get protected data 
            try {
                const res = await axios.get('api/account/getCurrentUser', { withCredentials: true });
               // console.log("=========getting current use============")
               // console.log(res.data);

                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: res.data.currentUser
                });
          
            } catch (err) {
                //console.log("could not fetch the user")
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload : true
                });
                setTimeout(() => {
                    dispatch({ 
                        type: 'TECHNICAL_SYSTEM_ERROR',
                        payload : false 
                    })
                }, 9000);
            }
        }
    }
    catch(err) {
        reportError("Login", err);
    }
}
// Logout Action 
export const Logout = () => {
    return (dispatch) => {
        dispatch({
            type: "LOGOUT"
        });
    }
}

// Forgot password
export const ActivateForgotPasswordPage = () => {
    return (dispatch) => {
        dispatch({
            type: 'SET_FORGOT_PASSWORD_PAGE',
            payload: true
        });
    }
}
//Does Email Exists
export const doesEmailExist = (email) => async dispatch => {
    try {
        const response = await axios.post('api/account/email_exists', { email });
        const responseMessage = response.data.message;
        if (responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload : true
            });
            setTimeout(() => {
                dispatch({ 
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload : false 
                })
            }, 9000)
        }
        if (responseMessage === "email exists") {
            dispatch({
                type: 'DOES_EMAIL_EXISTS',
                payload: true
            });
            dispatch({
                type: 'SET_FORGOT_PASSWORD_PAGE',
                payload: false
            });
            dispatch({
                type: "ITS_PASSWORD_RESET_MESSAGE",
            });
        }
        if (responseMessage === "email not exists") {
            dispatch({
                type: 'DOES_EMAIL_EXISTS',
                payload: false
            });
        }
    } catch (err) {
        reportError("doesEmailExist", err);
    }

}


//Register action step 1
export const RegistrationStepOne = (data) => async dispatch => {


    try {
        //clear any previous set state
        dispatch({ type: 'EMAIL_CONFIRMATION_WRONG', payload: "" });
        dispatch({ type: 'REGISTRATION_SUCCESS', payload: false });
        dispatch({ type: 'SET_CONFIRMATION_PAGE_TRUE', payload: true });

        const response = await axios.post('api/account/email_code_generation', { email: data.email });
        const responseMessage = response.data.Message;

        if (responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload : true
            });
            setTimeout(() => {
                dispatch({ 
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload : false 
                })
            }, 9000)
        }
        if (responseMessage === "code successfully send") { 
            dispatch({
                type: "REGISTRATION_STEP_ONE",
                payload: data
            });
        } else {
            dispatch({
                type: "EMAIL_CODE_GENERATION_FAILED",
            });
        }
    }
    catch(err) {
        reportError("RegistrationStepOne", err);
    }
}
//Register action 
export const Register = (user, code) => async dispatch => {

    try {
        const response = await axios.post('api/account/register', { user, code });
        const responseMessage = response.data.message;
        const updatedUser = response.data.user;

        if (responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload : true
            });
            setTimeout(() => {
                dispatch({ 
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload : false 
                })
            }, 9000)
        }
        if (responseMessage === "code does not match") {
            dispatch({
                type: 'EMAIL_CONFIRMATION_WRONG',
                payload: 'wrong'
            });
        }

        if (responseMessage === "Registeration successfully") {
            dispatch({
                type: 'REGISTRATION_SUCCESS',
                payload: updatedUser
            });
        }
    }
    catch (err) {
        //console.log(err);
        reportError("Register", err);
    }

}
//Register action 
export const ChangePassword = (passwords) => async (dispatch, getState) => {

    const user = getState().auth.currentUser;

    try {
        const response = await axios.post('api/account/changepassword', { user, passwords });
        const responseMessage = response.data.message;
        if (responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload : true
            });
            setTimeout(() => {
                dispatch({ 
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload : false 
                })
            }, 9000)
        }
        if (responseMessage === "password not found") {
            dispatch({
                type: 'WRONG_PASSWORD_PROVIDED',
            });
            setTimeout(() => {
                dispatch({ type: 'CLEAR_WRONG_PASSWORD_PROVIDED' });
            }, 3000)
        }
        if (responseMessage === "password changed successfully") {
            dispatch({
                type: 'PASSWORD_CHANGE_SUCCESS'
            });
            setTimeout(() => {
                dispatch({ type: 'CLEAR_PASSWORD_CHANGE_SUCCESS' });
            }, 30000)
        }
    }
    catch (err) {
        reportError("ChangePassword", err);
    }


}


//Go to login page 
export const GotoLoginPage = () => {
    return (dispatch) => {
        dispatch({
            type: "GOTOLOGINPAGE"
        })
    }
}
// Go to register page 
/*export const GotoRegisterPage = () => {
    return (dispatch) => {
        dispatch({
            type: "GOTOREGISTERPAGE"
        });
    }
}*/


// Registration actions 
export const PasswordNotMatch = () => {
    return (dispatch) => {
        dispatch({
            type: 'PASSWORD_NOT_MATCH',
        });
        setTimeout(() => {
            dispatch({ type: 'CLEAR_PASSWORD_NOT_MATCH' })
        }, 10000)
    }
}

export const checkPasswordstrength = (password) => {
    return (dispatch) => {
        if (password.length <= 6) {
            dispatch({
                type: 'CLEAR_PASSWORD_STRENGTH'
            })
            dispatch({
                type: 'PASSWORD_SHORT',
                payload: true
            });
            /*setTimeout(() => {
                dispatch({ 
                    type: 'CLEAR_PASSWORD_ERROR' 
                })
            }, 10000)*/
        } else {
            const strength = passwordStrength(`${password}`).value;
            dispatch({
                type: 'CLEAR_PASSWORD_SHORT'
            })
            dispatch({
                type: 'PASSWORD_STRENGTH',
                payload: `${strength}`
            });
            /*setTimeout(() => {
                dispatch({ 
                    type: 'CLEAR_PASSWORD_STRENGTH' 
                })
            }, 10000)*/
        }
    }
}

export const PasswordWarning = () => {
    return (dispatch) => {
        dispatch({
            type: 'PASSWORD_WARNING',
            payload: true
        });
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_PASSWORD_WARNING'
            })
        }, 10000)
    }
}

export const PasswordOK = () => {

    return (dispatch) => {
        dispatch({
            type: 'PASSWORD_OK',
            payload: true
        });
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_PASSWORD_OK'
            })
        }, 10000);
    }
}

export const PasswordErrorSame = () => {


    return (dispatch) => {
        dispatch({
            type: 'PASSWORD_ERROR_SAME',
        });
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_PASSWORD_ERROR_SAME'
            })
        }, 10000)
    }
}

export const PasswordError = (Error) => {

    return (dispatch) => {
        dispatch({
            type: 'PASSWORD_ERROR',
            payload: Error
        });
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_PASSWORD_ERROR'
            })
        }, 10000)
    }
}

export const EmailError = (Error) => {

    return (dispatch) => {
        dispatch({
            type: 'EMAIL_ERROR',
            payload: Error
        });
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_EMAIL_ERROR'
            })
        }, 10000)
    }
}
export const PlanError = (Error) => {

    return (dispatch) => {
        dispatch({
            type: 'PLAN_ERROR',
            payload: Error
        });
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_PLAN_ERROR'
            })
        }, 10000)
    }
}

export const EmailConfirmationError = (Error) => {

    return (dispatch) => {
        dispatch({
            type: 'EMAIL_CONFIRMATION_ERROR',
            payload: Error
        });
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_EMAIL_CONFIRMATION_ERROR'
            })
        }, 1500)
    }
}


export const CompanyError = () => {
    return (dispatch) => {
        dispatch({
            type: "COMPANY_ERROR"
        });
        setTimeout(() => {
            dispatch({
                type: "CLEAR_COMPANY_ERROR"
            })
        }, 10000)
    }
}


export const ItsPasswordResetMessage = () => {
    return (dispatch) => {
        dispatch({
            type: "ITS_PASSWORD_RESET_MESSAGE"
        });

    }
}
export const CountryError = () => {
    return (dispatch) => {
        dispatch({
            type: "COUNTRY_ERROR"
        });
        setTimeout(() => {
            dispatch({
                type: "CLEAR_COUNTRY_ERROR"
            })
        }, 10000)
    }
}


