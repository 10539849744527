import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { PaystackButton } from 'react-paystack'
import allData from 'country-currency-list';
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { passwordStrength } from 'check-password-strength';
import { ChangePassword, PasswordErrorSame, PasswordNotMatch, PasswordError, PasswordOK, PasswordWarning, checkPasswordstrength } from '../actions/auth';
import { VerifyPayment } from '../actions/payment';
import axios from "axios";



const PaymentsPage = () => {

  let navigate = useNavigate();

  const dispatch = useDispatch();
  const user_state = useSelector(state => state.auth);
  const isLoading = useSelector(state => state.payments.loading);
  const paymentsucess = useSelector(state => state.payments.paymentsucess);
  const paymentSuccess = useSelector(state => state.payments.paymentSuccess);
  const { plan, email, notificationNumber, company } = user_state.currentUser;


  if(paymentsucess === true){
    //return navigate("/");
  }

  if (plan === "complete") {
    var amount = 1067;
    var fullamount = 12806;
    var planCode = "PLN_5duj7xj8hhkvjb1";
  }
  else if (plan === "advanced") {
    var amount =  448;
    var fullamount = 5382;
    var planCode = "PLN_3dl40c4qflkmdyx"
  }
  else if (plan === "basic") {
    var amount = 230;
    var fullamount = 2760;
    var planCode = "PLN_tgk3d2hoqu8zxco";
  } else {
    var amount = 0;
    var fullamount = 0 * 12;
  }

  const componentProps = {
    email : email,
    amount : amount,
    metadata: {
      name : company,
    },
    publicKey : "pk_test_16b8c8f100d52d068b5007aec8fe54bc71648acc",
    text: "Pay Now",
    currency : "ZAR",
    plan : planCode,
    onSuccess: ( data ) =>{
      console.log(data.reference)
      const { reference } = data;
      dispatch(VerifyPayment(reference));
    },
    onClose: () => alert("Wait! are you sure you want to exit payment"),
  }


  /*const convertCurrency = async (from, to, amount) => {
    const response = await axios.get(`https://api.frankfurter.app/latest?amount=${amount}&from=${from}&to=${to}`);
    const result = response.data.rates[to];
    return result;
  };
  const currencies = ['USD', 'EUR', 'GBP', 'PLN', 'CAD', 'JPY', 'EGY', 'LSL'];

  const ConvertToZAR = async () => {
    const convertedAmount = await convertCurrency('USD', 'ZAR', annualAmount);
    return convertedAmount;
  };
  ConvertToZAR().then(result => {
    setZARAmountAnnually(result);
  })*/


  function _Pay(e) {
    //alert(ZARAmountAnnually);
   // dispatch(Pay(ZARAmountAnnually));
  }


  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title">Payment settings</div>
                  <div className="card-body">
                    <div className="card-description wrapperLineh" style={{ "marginTop": "10px", "marginBottom": "5px", "paddingTop": "0" }}>
                      <div className="row">

                        <div className="col-md-6">
                          {
                             (isLoading) ? <span style={{fontSize: "22px", marginBottom:"25px"}} className="badge badge-info">Loading please wait.........</span> : ""
                          }
                          {
                             (paymentSuccess) ? <span style={{fontSize: "19px", marginBottom:"25px"}} className="badge badge-success">Payment successful <Link to="/">click here</Link> to go to the dashboard</span> : ""
                          }
                        
                          {/*<h5>Change Currency</h5>
                          <select
                            className="form-control form-control-sm"
                            onChange={(e) => { _convertCurrency(e.target.value) }}
                          >
                            {currency_.map(item => {
                              return (
                                <option
                                  value={item.currency}
                                >
                                  {item.countryName} - {item.currency}
                                </option>
                              )
                            })}
                          </select>
                          <hr />*/}
                          <hr /><br />
                          <div className='row'>
                            <h5 className='col-md-6' style={{"fontSize":"22px"}}>Plan : {plan} </h5>
                            <p className='col-md-6' style={{"fontSize":"22px"}}>ZAR {amount} / month</p>
                            <h6 className='col-md-12'style={{"fontSize":"22px"}}>Billed annually </h6>
                            <br /><br />

                            <hr />
                            <h5 className='col-md-6' style={{"fontSize":"22px"}}>Total </h5>
                            <p className='col-md-6' style={{"fontSize":"22px"}}>ZAR {fullamount} </p>
                          </div>
                          <hr />

                          <PaystackButton className="btn btn-inverse-primary btn-fw" {...componentProps} />
                          

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title">Invoices</div>
                  <div className="card-body">
                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />

          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default PaymentsPage;