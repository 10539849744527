import React from 'react';
import { Link } from 'react-router-dom';


const SidebarGettingStarted = (props) => {

   // console.log(props.setup.setup)

    function renderMenu(){
        if(props.setup.setup ===""){
         return(
                <></>
         )   
        }else{
<ul className="nav">
                <li className="nav-item">
                    <Link className="nav-link" to="/">
                        <i className="mdi mdi-home menu-icon"></i>
                        <span className="menu-title">Dashboard</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/uptime">
                        <i className="mdi mdi-pulse menu-icon"></i>
                        <span className="menu-title">Uptime check</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/ssl">
                        <i className="mdi mdi-key menu-icon"></i>
                        <span className="menu-title">SSL</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" data-toggle="collapse" to="/links" aria-expanded="false" aria-controls="ui-basic">
                        <i className="mdi mdi-link-variant-off menu-icon"></i>
                        <span className="menu-title">Broken links </span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/searchranking">
                        <i className="mdi mdi-view-headline menu-icon"></i>
                        <span className="menu-title">Search Ranking</span>
                    </Link>
                </li>
               {/* <li className="nav-item">
                    <Link className="nav-link" to="/socialmedia">
                        <i className="mdi mdi-laptop-chromebook menu-icon"></i>
                        <span className="menu-title">Social media mentioning</span>
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link className="nav-link" to="/ports">
                        <i className="mdi mdi-server menu-icon"></i>
                        <span className="menu-title">Port Monitoring</span>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/reports">
                        <i className="mdi mdi-chart-pie menu-icon"></i>
                        <span className="menu-title">Reports</span>
                    </Link>
                </li>

            </ul>
        }
    }

    return (

        <nav className="sidebar sidebar-offcanvas" id="sidebar">
           {renderMenu()}
        </nav>

    )
}
export default SidebarGettingStarted;