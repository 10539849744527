import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/sidebar';

import Header from '../components/header';
//import { AddPort, GetPorts, UpdatePort, GetPort, DeletePort } from '../actions/ports';



const PerformanceAduitsPage = () => {



const dispatch = useDispatch();
const audits = useSelector(state => state.sitehealth.audits) || [];
console.log("==============Audits============")
console.log(audits);

const user_state = useSelector(state => state.auth);
const { plan } = user_state.currentUser;
//check if there was any system error
const SYSTEM_ERROR = useSelector(state => state.system_errors.system_error);

//const [portName, setPortName] = useState("");
//const [portNumber, setPortNumber] = useState("");

 
  useEffect(() => {
    //dispatch(GetPorts());
    //if (port.length > 0) {
    //firstInput(port[0].portName)
    //}
  }, []);

  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title">Audits</div>
                  <div className="card-body">
                    <div className="col-md-12">
                      {
                        (SYSTEM_ERROR)
                          ?
                          <div
                            style={{
                              "fontSize": "50%", "border": "1px solid red", "padding": "5px",
                              "backgroundColor": "red", "color": "#fff", "borderRadius": "5px",
                              "marginBottom": "10px"
                            }}>
                            System Error has occured, and system has already sent the error to support.
                            please refresh your browser and try again and see if the error persists.
                          </div>
                          :
                          ""
                      }
                    </div>
                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>

                     
                      <div>
                        <br /><br /><br />
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Title</th>
                              <th>Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {audits.map(item => {
                              return (
                                <tr key={item._id}>
                                  <td>
                                    {item.title}
                                  </td>
                                  <td>
                                    {item.description}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default PerformanceAduitsPage;