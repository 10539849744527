import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { Get_SSL_ERRORS, GetSSLErrorsByDate } from '../actions/ssl';

const SSLPage = () => {


  const [status, setStatus] = useState('all');
  const [startDate, setStartDate] = useState(new Date());

  const dispatch = useDispatch();
  const errors = useSelector(state => state.ssl_errors_list.errors);

  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;



  function generateReport() {

    dispatch(GetSSLErrorsByDate(startDate));
  }

  useEffect(() => {
    dispatch(Get_SSL_ERRORS());
  }, []);


  return (
    <>
       <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <p className="card-title">SSL</p>
                  <div className="card-body">
                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>

                      <div className="boxinside">
                        <span style={{ "fontSize": "14px" }}>Date</span>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <div className="boxinside" style={{"marginLeft":"10px"}}>
                        <button type="button" onClick={generateReport} className="btn btn-inverse-primary btn-fw">Search</button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>

                            <th>Errors</th>
                            <th>Date detected</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {errors.map(item => {
                            return (
                              <tr key={item._id}>
                                <td>{item.error}</td>
                                <td>{item.captured}</td>
                                <td>{item.time}:00</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default SSLPage;