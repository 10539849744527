import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams, useNavigate } from "react-router-dom"
import App from './App';
import Auth, { Register } from './actions/auth';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import EmailConfirmation from './pages/confirmemail';
import ForgotPassword from './pages/forgotpassword';




const Islogged = () => {



    // check the current user
    const user_state = useSelector(state => state.auth);
    const location = useLocation();


    const [queryParameters] = useSearchParams();
    const plan = queryParameters.get("plan");


    function checkLogin() {
        // if the current user is logged in return dashboard


        //console.log(" registration success : " + user_state.registrationSuccess)
        //console.log(" confirmation page active : " + user_state.confirmationPageActive)
        //console.log(" forgot password page active : " + user_state.forgotPasswordPageActive)


        if (user_state.currentUser !== null && user_state.currentUser !== undefined) {
            return (
                <App />
            )
        }
        // else if(user_state.registrationSuccess === true){
        //   return (
        //     <LoginPage />
        //)
        // }

        else if (user_state.forgotPasswordPageActive === true && user_state.registrationSuccess === false) {
            return (
                <ForgotPassword />
            )
        }

        else if (user_state.confirmationPageActive === true && user_state.registrationSuccess === false) {
            return (
                <EmailConfirmation />
            )
        }

        else if (location.pathname === '/register' && user_state.registrationSuccess === false) {
            return (
                <RegisterPage plan={plan} />
            )
        }



        // else the url is register go to register

        // if the user is null return a login page
        else if (user_state.currentUser === null) {
            return (
                <LoginPage />
            )
        }
    }

    return (
        <h2>
            {checkLogin()}
        </h2>
    )
}
export default Islogged;