export default (state = { client_tracking_info: {}, ERROR : false, error_occured : "", is_tracking : false, show_visualize : false, heatmapData: [] }, action) => {

    if (action.type === "CLIENTCODE_AND_URL"){
        return {
            ...state,
            client_tracking_info: action.payload
        }
    }else if(action.type === "TECHNICAL_SYSTEM_ERROR"){
        return {
            ...state,
            ERROR : action.payload
        }
    }
    else if(action.type ==="GENERATE_CLIENT_CODE_ERROR"){
        return {
            ...state,
            error_occured : action.payload
        }
    }
    else if(action.type === "CLIENTCODE_FOUND"){
        return {
            ...state,
            client_tracking_code : "none"
        }
    }
    else if(action.type ==="IS_TRACKING"){
        return {
            ...state,
            is_tracking : action.payload
        }
    }
    else if(action.type === "VISUALIZE"){
        return {
            ...state,
            heatmapData: action.payload 
        }
    }
    else if(action.type === "SHOW_VISUALIZE"){
return {
    ...state,
    show_visualize : action.payload
}
    }
    else {
        return state;
    }
}