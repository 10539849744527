import axios from 'axios';

async function reportError(action, error) {
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if (responseMessage === "error logged successfully") {
       // console.log("error repotred successfully");
    }
}


export const UpTimeChart = () => async (dispatch) => {
    try {
        //Initial request
        const response = await axios.post('api/uptime/uptimechart', { withCredentials: true });
        const responseMessage = response.data.uptimeErrorMessage;

        if (responseMessage === 'no uptimes found') {
            dispatch({
                type: 'UPTIME_CHART',
                payload: []
            });
        } else {
            dispatch({
                type: 'UPTIME_CHART',
                payload: response.data
            });
        }



        // Request every hour
        async function Request() {
        
            const response = await axios.post('api/uptime/uptimechart', { withCredentials: true });
            const responseMessage = response.data.uptimeErrorMessage;


            if (responseMessage === 'no uptimes found') {
                dispatch({
                    type: 'UPTIME_CHART',
                    payload: []
                });
            } else {
                dispatch({
                    type: 'UPTIME_CHART',
                    payload: response.data
                });
            }
        }
        setInterval(Request, 5000);
    }
    catch (err) {
        reportError("UpTimeChart", err);
    }
}


export const TheStatus = () => async (dispatch) => {
    try {
        //Initial request
        const response = await axios.post('api/uptime/status', { withCredentials: true });
        const responseMessage = response.data.uptimeErrorMessage;

        if (responseMessage === 'no uptimes found') {
            dispatch({
                type: 'UPTIME_STATUS',
                payload: [{ status: 'DOWN' }]
            });
        } else {
            dispatch({
                type: 'UPTIME_STATUS',
                payload: response.data
            });
        }
        // Request every hour
        async function Request() {
            const response = await axios.post('api/uptime/status', { withCredentials: true });
            const responseMessage = response.data.uptimeErrorMessage;

            if (responseMessage === 'no uptimes found') {
                dispatch({
                    type: 'UPTIME_STATUS',
                    payload: [{ status: 'DOWN' }]
                });
            } else {
                dispatch({
                    type: 'UPTIME_STATUS',
                    payload: response.data
                });
            }
        }
        setInterval(Request, 5000);
    }
    catch (err) {
        reportError("TheStatus", err);
    }
}

export const GetUpTime = () => async (dispatch) => {
    try {
        const response = await axios.post('api/uptime/getuptime', { withCredentials: true });
        const responseMessage = response.data.message;
  
        if (responseMessage === "no uptimes found") {
            dispatch({
                type: 'UPTIMES',
                payload: []
            });

        } else {
            dispatch({
                type: 'UPTIMES',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetUpTime", err);
    }
}

export const GetUpTIMEByStatus = (status, DATE) => async (dispatch) => {

    try {
        const response = await axios.post('api/uptime/getuptimesbystatus', { status, DATE }, { withCredentials: true });
        const responseMessage = response.data.message;
        if (responseMessage === "no uptimes found") {

            dispatch({
                type: 'UPTIMES',
                payload: []
            });

        } else {
            dispatch({
                type: 'UPTIMES',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetUpTIMEByStatus", err);
    }
}

export const GetReport = () => async (dispatch) => {

    try {
        const response = await axios.post('api/uptime/generatereport', { withCredentials: true });
        const responseMessage = response.data.message;
        //console.log(responseMessage);

        if (responseMessage === "no uptimes found") {
            dispatch({
                type: 'UPTIMES',
                payload: []
            });

        } else {
            dispatch({
                type: 'UPTIMES',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetReport", err);
    }
}
