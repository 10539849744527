export default (state = { stastics : []}, action) => {

    if (action.type === "STASTICS") {
        return {
            ...state,
            stastics: action.payload
        }
    }
    else {
        return state;
    }

}