import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import Sidebar from '../../components/sidebar';
import Stastics from '../../components/stasticsHeader';
import Header from '../../components/header';
import { GetReport, GetLinksReport, GetPortsReport } from '../../actions/reports';
import { GetPorts } from '../../actions/ports';

const ReportsPagePorts = () => {


  const [status, setStatus] = useState('all');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [server, setServer] = useState("select server");
  const [brokenLinksStartDate, setbrokenLinksStartDate] = useState(new Date());
  const [brokenLinksEndDate, setbrokenLinksEndDate] = useState(new Date());

  const dispatch = useDispatch();
  const downtimereport = useSelector(state => state.report.down_report);
  const linksreport = useSelector(state => state.report.links_report);
  const portsreport = useSelector(state => state.report.ports_report);
  const ports = useSelector(state => state.ports_list.ports);

  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;
  
 
  function generatePortReport(){
    if(server !== "select server"){
      dispatch(GetPortsReport(brokenLinksStartDate, brokenLinksEndDate, server));
    }else{
      alert("Please select server")
    }
    
  }

  useEffect(() => {
    dispatch(GetPorts());
    //if (port.length > 0) {
      //firstInput(port[0].portName)
    //}
  }, []);


  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan}/>
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <p className="card-title">Servers</p>
                  <div className="card-body">
                    <div className="table-responsive">

    
                    <div className="boxinside" style={{"marginRight": "10px" }}>
                        <span style={{ "fontSize": "14px" }}>Server</span><br />
                        <select className="form-control" onChange={(e) => { setServer(e.target.value) }}>
                          <option value="select server">Select Server</option>
                          {ports.map(item => {
                              return (
                                  <option key={item._id} value={item.hostServer}>{item.portName}:{item.portNumber} </option>
                              )
                            })}
                        </select>
                      </div>

                      <div className="boxinside">
                        <span style={{ "fontSize": "14px" }}>Start date</span><br />
                        <DatePicker selected={brokenLinksStartDate} onChange={(date) => setbrokenLinksStartDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <div className="boxinside" style={{ "marginLeft": "10px", "marginRight": "10px" }} >
                        <span style={{ "fontSize": "14px" }}>End date</span><br />
                        <DatePicker selected={brokenLinksEndDate} onChange={(date) => setbrokenLinksEndDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <p className="boxinside" style={{"marginTop":"15px"}}>
                        <br />
                        <button type="button" onClick={generatePortReport} className="btn btn-inverse-primary btn-fw">Generate Report</button>
                      </p>

                    </div>

                    <div style={{ "BorderBottom": " 1px solid #ccc", "margin": "20px 0" }}>

                      
                      <h6>{(Object.keys(portsreport).length === 0 && portsreport.constructor === Object)? "" : "Total Response Time : " + portsreport.TotalResponseTime }</h6>
                      <h6>{(Object.keys(portsreport).length === 0 && portsreport.constructor === Object)? "" : "Average Response Time : " + portsreport.AverageResponseTime }</h6>
                      { /*
                      <h5>
                       Average Down Time : {getAverageTotalDownTime()} 
                      </h5>
                      */}
                    </div>


                    <div style={{ "marginTop ": "60px" }} className="table-responsive">
                      <table className="table table-bordered table-striped">
                      <thead>
                          <tr>
                            <th>Server/Port</th>
                            <th>
                              <div style={{ "position": "relative" }}>
                                <div style={{ "border": "0px", "height": "5px", "marginLeft": "40px", "position": "absolute", "top": "8px" }}>Down</div>
                              </div>
                              <div style={{ "border": "0px" }}>
                                <div style={{ "backgroundColor": "red", "height": "30px", "width": "30px", "borderRadius": "15px" }}>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div style={{ "position": "relative" }}>
                              <div style={{ "border": "0px", "height": "5px", "marginLeft": "40px", "position": "absolute", "top": "8px" }}>UP</div>
                              </div>
                              <div style={{ "border": "0px" }}>
                                <div style={{ "backgroundColor": "green", "height": "30px", "width": "30px", "borderRadius": "15px" }}>
                                </div>
                              </div>
                            </th>
                            <th><div style={{ "border": "0px" }}>Down Time</div></th>

                          </tr>
                        </thead>
                        <tbody>
                          {(Object.keys(portsreport).length === 0 && portsreport.constructor === Object)
                            ?
                            <></>
                            :
                            portsreport.downtimers.map(item => {
                              const { Added, created } = item;

                              const localtime_added = new Date(Added);
                              const ly = localtime_added.getFullYear();
                              const lm = parseInt(localtime_added.getMonth()) + 1;
                              const ld = localtime_added.getDate();
                              const lh = localtime_added.getHours()
                              const lmi = localtime_added.getMinutes()

                              const localtime_created = new Date(created);
                              const cy = localtime_created.getFullYear();
                              const cm = parseInt(localtime_created.getMonth()) + 1;
                              //const cd = localtime_created.getDay();
                              const cd = localtime_created.getDate()
                              const ch = localtime_created.getHours();
                              const cmi = localtime_created.getMinutes();
                              localtime_created.getHours();
                              localtime_created.getMinutes();

                              return (
                                <tr key={item._id}>
                                  <td>{item.serverName}:{item.port}</td>
                                  <td>{`Date ${cd}/${cm}/${cy} `} {`${"     "}`}   {`Time ${ch}:${cmi}`}</td>
                                  <td>{`Date ${ld}/${lm}/${ly}`} {`${"     "}`}   {`Time ${lh}:${lmi}`}</td>
                                  <td>
                                    {(item.Duration.days == 0) ? "" : `${item.Duration.days} days `}
                                    {(item.Duration.hours == 0) ? "" : `${item.Duration.hours} hrs `}
                                    {(item.Duration.minutes == 0) ? "" : `${item.Duration.minutes} min `}
                                    {(item.Duration.seconds == 0) ? "" : `${item.Duration.seconds} sec`}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                   


                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default ReportsPagePorts;