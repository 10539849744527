export default (state = {
    currentUser: null,
    login_error: false,
    password_not_match: false,
    password_error: false,
    password_ok: false,
    password_warning: false,
    genereated_password_error: "",
    password_error_same : false,
    company_error: false,
    country_error: false,
    email_error: false,
    genereated_email_errors: "",
    plan_error :false,
    genereated_plan_errors: "",
    email_confirmation_error: false,
    genereated_email_confirmation_errors:"",
    registration_step_one: {},
    confirmationPageActive: false,
    registrationSuccess: false,
    old_password_not_match: false,
    password_change_success: false,
    password_ok: false,
    passwordwarning: false,
    password_short: false,
    password_strength: '',
    getting_started_checking: false,
    getting_started_url_invalid: false,
    confirmation_email_match : "",
    forgotPasswordPageActive : false,
    email_exists : false,
    its_password_reset_message: false
},
    action) => {

    if (action.type === "LOGIN_SUCCESS"){
        return {
            ...state,
            currentUser: action.payload
        }
    }
    if (action.type === "SET_FORGOT_PASSWORD_PAGE") {
        return {
            ...state,
            forgotPasswordPageActive: action.payload
        }
    }
    else if (action.type === "REGISTRATION_SUCCESS"){
        return {
            ...state,
            currentUser: null,
            registrationSuccess: action.payload
        }
    }
    else if (action.type === "EMAIL_CONFIRMATION_WRONG") {
        return {
            ...state,
            confirmation_email_match : action.payload
        }
    }
    else if (action.type === "CLEAR_LOGIN_UI_MESSAGES") {
        return {
            ...state,
            login_error: false
        }
    }
    else if (action.type === "LOGIN_FAILED") {
        return {
            ...state,
            login_error: true
        }
    }
    else if (action.type === "LOGOUT") {
        return {
            ...state,
            currentUser: null
        }
    }
    else if (action.type === "GOTOLOGINPAGE") {
        return {
            ...state,
            currentUser: null
        }
    }
    
    else if (action.type === "DOES_EMAIL_EXISTS") {
        return {
            ...state,
            email_exists: action.payload
        }
    }
    else if (action.type === "ITS_PASSWORD_RESET_MESSAGE") {
        return {
            ...state,
            its_password_reset_message: true,
        }
    }
    
    /*else if (action.type === "GOTOREGISTERPAGE") {
        return {
            ...state,
            currentUser: "goto_register_page"
        }
    }*/
    else if (action.type === "PASSWORD_NOT_MATCH") {
        return {
            ...state,
            password_not_match: true,
        }
    }
    else if (action.type === "CLEAR_PASSWORD_NOT_MATCH") {
        return {
            ...state,
            password_not_match: false
        }
    }
    else if (action.type === "PASSWORD_WARNING") {
        return {
            ...state,
            password_warning: true,
            genereated_password_warning: action.payload
        }
    }
    else if (action.type === "PASSWORD_OK") {
        return {
            ...state,
            password_ok: true,
            genereated_password_ok: action.payload
        }
    }
    else if (action.type === "PASSWORD_ERROR") {
        return {
            ...state,
            password_error: true,
            genereated_password_error: action.payload
        }
    }
    else if (action.type === "PASSWORD_ERROR_SAME") {
        return {
            ...state,
            password_error_same: true
        }
    }
    else if (action.type === "CLEAR_PASSWORD_ERROR_SAME") {
        return {
            ...state,
            password_error_same: false
        }
    }
    else if (action.type === "WRONG_PASSWORD_PROVIDED") {
        return {
            ...state,
            old_password_not_match: true
        }
    }
    else if (action.type === "CLEAR_WRONG_PASSWORD_PROVIDED") {
        return {
            ...state,
            old_password_not_match: false
        }
    }
    else if (action.type === "PASSWORD_CHANGE_SUCCESS") {
        return {
            ...state,
            password_change_success: true
        }
    }
    else if (action.type === "CLEAR_PASSWORD_CHANGE_SUCCESS") {
        return {
            ...state,
            password_change_success: false
        }
    }

    
    

   
   
    else if (action.type === "PASSWORD_SHORT") {
        return {
            ...state,
            password_short: true,
        }
    }
    else if (action.type === "CLEAR_PASSWORD_SHORT") {
        return {
            ...state,
            password_short: false,
        }
    }
    else if (action.type === "PASSWORD_STRENGTH") {
        return {
            ...state,
            password_strength: action.payload
        }
    }
    else if (action.type === "CLEAR_PASSWORD_STRENGTH") {
        return {
            ...state,
            password_strength: ""
        }
    }
    else if (action.type === "CLEAR_PASSWORD_ERROR") {
        return {
            ...state,
            password_error: false,
            genereated_password_error: ''
        }
    }
    else if (action.type === "CLEAR_PASSWORD_OK") {
        return {
            ...state,
            password_ok: false,
        }
    }
    else if (action.type === "CLEAR_PASSWORD_WARNING") {
        return {
            ...state,
            password_warning: false,
        }
    }
    else if (action.type === "COMPANY_ERROR") {
        return {
            ...state,
            company_error: true,
        }
    }
    else if (action.type === "CLEAR_COMPANY_ERROR") {
        return {
            ...state,
            company_error: false,
        }
    }
    else if (action.type === "COUNTRY_ERROR") {
        return {
            ...state,
            country_error: true,
        }
    }
    else if (action.type === "CLEAR_COUNTRY_ERROR") {
        return {
            ...state,
            country_error: false,
        }
    }
    else if (action.type === "EMAIL_ERROR") {
        return {
            ...state,
            email_error: true,
            genereated_email_errors: action.payload
        }
    }
    else if (action.type === "CLEAR_EMAIL_ERROR") {
        return {
            ...state,
            email_error: false,
            genereated_email_errors: []
        }
    }
    
    else if (action.type === "PLAN_ERROR") {
        return {
            ...state,
            plan_error: true,
            genereated_plan_errors: action.payload
        }
    }
    else if (action.type === "CLEAR_PLAN_ERROR") {
        return {
            ...state,
            plan_error: false,
            genereated_plan_errors: []
        }
    }
    else if (action.type === "EMAIL_CONFIRMATION_ERROR") {
        return {
            ...state,
            email_confirmation_error: true,
            genereated_email_confirmation_errors: action.payload
        }
    }
    else if (action.type === "CLEAR_EMAIL_CONFIRMATION_ERROR") {
        return {
            ...state,
            email_confirmation_error: false,
            genereated_email_confirmation_errors: [],
            confirmation_email_match : ""
        }
    }
    else if (action.type === "REGISTRATION_STEP_ONE"){
        return {
            ...state,
            registration_step_one: action.payload,
            confirmationPageActive : true
        }
    }
    else if (action.type === "SET_CONFIRMATION_PAGE_TRUE"){
        return {
            ...state,
            confirmationPageActive : action.payload,
        }
    }
    
    else if (action.type === "GETTING_STARTED_CHECKING") {
        return {
            ...state,
            getting_started_checking: true
        }
    }
    else if (action.type === "GETTING_STARTED_URL_INVALID") {
        return {
            ...state,
            getting_started_checking : false,
            getting_started_url_invalid: action.payload
        }
    }
    else {
        return state;
    }

}