import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Register, RegistrationStepOne } from "../actions/auth";
import HeaderEmpty from "../components/header-empty";
import { useNavigate } from 'react-router-dom';
import { EmailConfirmationError } from "../actions/auth";
// registration 2 
const EmailConfirmation = () => {

    let navigate = useNavigate();

    //instatiate useDispatch hook
    const dispatch = useDispatch();
    const registration_step_one_data = useSelector(state => state.auth.registration_step_one);
    const confirmation_email_match = useSelector(state => state.auth.confirmation_email_match);
    // Set state
    const [code, setCode] = useState("");
    const user_state = useSelector(state => state.auth);
    const { email_confirmation_error, genereated_email_confirmation_errors } = user_state;
    var errorFlag = [];

    // Handle on click register button
    function _Confirm(){

        if (code === "") {
            dispatch(EmailConfirmationError("Please enter the code sent to your email"));
            errorFlag.push("email confirmation error");
        }

        if (confirmation_email_match === "wrong"){
            console.log("wrong code provided.............................")
            dispatch(EmailConfirmationError("Wrong code provided"));
            errorFlag.push("email confirmation error");
        }

        if(errorFlag.length === 0) {
            console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
            console.log(registration_step_one_data);
            console.log(code);
            console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
            dispatch(Register(registration_step_one_data, code));
            return navigate("/login"); 
        }

    }
    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg" style={{ "backgroundColor": "#37517e", "paddingLeft": "100px" }}>
                        <div className="row flex-grow">
                            <div className="col-lg-9 d-flex align-items-center justify-content-left">
                                <div className="auth-form-transparent text-left p-3">
                                    <div className="brand-logo2" style={{ "marginTop": "10px", "marginBottom": "40px", "paddingLeft": "40px" }}>
                                        <img src="../../images/logo-small.png" width="250px" alt="logo" style={{}} /><br />
                                    </div>
                                    <div style={{ "backgroundColor": "#fff", "padding": "30px 40px", "borderRadius": "3px", "width": "90%" }}>
                                        <h4>Cofirm Your Email</h4>
                                        <p>Confirmation code was send to your email, please enter the code to confirma your email</p>
                                        <br />
                                        
                                        <input
                                            type="text"
                                            className="form-control form-control-lg border-left-2"
                                            placeholder="Enter confirmation"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                        />
                                        <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                            (email_confirmation_error)
                                                ?
                                                <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>{genereated_email_confirmation_errors}</span></div>
                                                :
                                                ''
                                        }
                                        </div>
                                        <br />
                                        <button
                                            onClick={_Confirm}
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                        >
                                            CONFIRM
                                        </button>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailConfirmation;