import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetStastics } from '../actions/stats';

import { GetSSLDaysLeft, GET_SSL_ERRORS_COUNT } from '../actions/ssl';

const Stastics = () => {
  const dispatch = useDispatch();
  const stastics = useSelector(state => state.stastics_list.stastics);
  
  useEffect(() => {
    dispatch(GetStastics());
  },[])

  return (
    <div className="row">
      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body dashboard-tabs p-0">
            <ul className="nav nav-tabs px-4" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">History</a>
              </li>


            </ul>
            {/* First tab content  */}
            <div className="tab-content py-0 px-0">
              <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                <div className="d-flex flex-wrap justify-content-xl-between">
                  <div className="d-none d-xl-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i className="mdi mdi-pulse icon-md mr-3 text-primary"></i>
                    <div className="d-flex flex-column justify-content-around">
                      <h5 className="mb-1 text-muted" style={{ "fontSize": "15px" }}>Down</h5>
                      <h6 className="mr-2 mb-0 text-muted" style={{ "fontSize": "12px" }}>{(stastics.length > 0 ) ? `${stastics[4].downtimes }  Recorded` : ''}</h6>
                    </div>
                  </div>
                  <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i className="mdi mdi-key mr-3 icon-md text-danger"></i>
                    <div className="d-flex flex-column justify-content-around">
                      <h5 className="mb-1 text-muted" style={{ "fontSize": "15px" }}>SSL Errors</h5>
                      <h6 className="mr-2 mb-0 text-muted" style={{ "fontSize": "12px" }}>{(stastics.length > 0 ) ? `${stastics[3].sslErrors}  Detected` : ''}</h6>
                    </div>
                  </div>
                  <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i className="mdi mdi-link-variant-off mr-3 icon-md text-success"></i>
                    <div className="d-flex flex-column justify-content-around">
                      <h5 className="mb-1 text-muted" style={{ "fontSize": "15px" }}>Broken Links</h5>
                      <h6 className="mr-2 mb-0 text-muted" style={{ "fontSize": "12px" }}>{(stastics.length > 0 ) ? `${stastics[2].links}  Found` : '' }</h6>
                    </div>
                  </div>
                 
                  <div className="d-flex py-3 border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i className="mdi mdi-server mr-3 icon-md text-danger"></i>
                    <div className="d-flex flex-column justify-content-around">
                      <h5 className="mb-1 text-muted" style={{ "fontSize": "15px" }}>Ports </h5>
                      <h6 className="mr-2 mb-0 text-muted" style={{ "fontSize": "12px" }}>{(stastics.length > 0 ) ? `${stastics[1].ports} Tracking` : '' }</h6>
                    </div> 
                  </div>
                  
                  <div className="d-flex border-md-right flex-grow-1 align-items-center justify-content-center p-3 item">
                    <i className="mdi mdi-bell-ring mr-3 icon-md text-warning"></i>
                    <div className="d-flex flex-column justify-content-around">
                      <h5 className="mb-1 text-muted" style={{"fontSize":"15px"}}>Notifications</h5>
                      <h6 className="mr-2 mb-0 text-muted" style={{"fontSize":"12px"}}>{(stastics.length > 0 ) ? `${stastics[0].notifications} Send`: ''} </h6>
                    </div>
                  </div>
                 
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Stastics;