import React from "react";
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';

const SocialMediaPage = () => {
    return(
        <>
         <Header />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                      
                        <div className="row">
            <div className="col-md-12 stretch-card">
              <div className="card">
                <div className="card-body">
                  <p className="card-title">Social Media </p>
                  <div className="table-responsive">
                      
                  </div>
                </div>
              </div>
            </div>
          </div>
                    </div>
                </div>{/* main-panel ends */}
            </div>{/* page-body-wrapper ends */}
        </>
    )
}

export default SocialMediaPage;