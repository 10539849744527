export default (state = { system_error : false }, action) => {
    if (action.type === "TECHNICAL_SYSTEM_ERROR"){
        return {
            ...state,
            system_error : action.payload
        }
    }
   
    else {
        return state;
    }

}