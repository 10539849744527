import { combineReducers } from 'redux';
import authReducer from './authReducer';
import linksReducer from './linksReducer';
import uptimeReducer from './uptimeReducer';
import sslReducer from './sslReducer';
import portsReducer from './portsReducer';
import searchrankReducer from './searchrankReducer';
import stasticsReducer from './stasticsReducer';
import reportsReducer from './reportsReducer';
import errorsReducer from './errorsReducer';
import paymentsREducer from './paymentsReducer';
import phonesReducer from './phonesReducer';
import sitehealthReducer from './sitehealthReducer';
import heatmapReducer from './heatmapReducer';

export default combineReducers ({
   auth : authReducer,
   links_list : linksReducer,
   uptime_list : uptimeReducer,
   ssl_errors_list : sslReducer,
   ports_list : portsReducer,
   search_rank_list: searchrankReducer,
   stastics_list : stasticsReducer,
   report : reportsReducer,
   system_errors : errorsReducer,
   payments : paymentsREducer,
   phonelist : phonesReducer,
   sitehealth : sitehealthReducer,
   heatmap : heatmapReducer
});