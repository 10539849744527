import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Dashboard from './pages/dashboard';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import UptimePage from './pages/uptime';
import SSLPage from './pages/ssl';
import LinksPage from './pages/links';
import SearchRankPage from './pages/search';
import SocialMediaPage from './pages/socialmedia';
import PortsPage from './pages/ports';
import SettingsPage from './pages/settings';
import ReportsPage from './pages/reports';
import RegisterPage2 from './pages/payment';
import PhrasePage from './pages/phrase';
import SystemStatusPage from './pages/systemstatus';
import GetStartedPage from './pages/gettingstarted';
import ReportsPageUptime from './pages/reports/reports_uptime';
import ReportsPageBrokenLinks from './pages/reports/reports_brokenlinks';
import ReportsPageSSL from './pages/reports/reports_ssl';
import ReportsPagePorts from './pages/reports/reports_ports';
import ReportsPageSearchrank from './pages/reports/reports_search_ranking';
import EmailConfirmation from './pages/confirmemail';
import ForgotPassword from './pages/forgotpassword';
import PaymentsPage from './pages/payments';
import SubscriptionPage from './pages/subscription';
import AccountPage from './pages/account';
import PhonesPage from './pages/phones';
import './App.css';
import PerformanceAduitsPage from './pages/performanceAduits';
import HeatMapsPage from './pages/heatmaps';




function App() {

  const user_state = useSelector(state => state.auth);
  const { plan } =  user_state.currentUser;

  return (
    
    //the browserRouter is on the top level inside index.js
    <Routes>
      <Route path="/" exact element={<Dashboard />} />
      <Route path="/login" exact element={<LoginPage />} />
      
      {/*After successful registration do not render the registration page again */}
      if(user_state.registrationSuccess === false){
        <Route path="/register" exact element={<RegisterPage />} />
      }

      <Route path="/register" exact element={<RegisterPage />} />
      <Route path="/uptime" exact element={<UptimePage />} />
      <Route path="/ssl" exact element={<SSLPage />} />
      <Route path="/links" exact element={<LinksPage />} />
      <Route path="/searchranking" exact element={<SearchRankPage />} />
      <Route path="/socialmedia" exact element={<SocialMediaPage />} />
      <Route path="/ports" exact element={<PortsPage />} />
      <Route path="/settings" exact element={<SettingsPage />} />
      <Route path="/status" exact element={<SystemStatusPage />} />
      <Route path="/reports" exact element={<ReportsPage />} />
      <Route path="/registration_step_2" exact element={<RegisterPage2 />} />
      <Route path="/phrases" exact element={<PhrasePage />} />
      <Route path="/start" exact element={<GetStartedPage />} />
      <Route path="/emailconfirmation" exact element={<EmailConfirmation />} />
      <Route path='/forgotpassword' exact element={<ForgotPassword />} />
      <Route path="/report_uptime" exact element={<ReportsPageUptime />} />
      <Route path="/report_brokenlinks" exact element={<ReportsPageBrokenLinks />} />
      <Route path="/report_ssl" exact element={<ReportsPageSSL />} />
      <Route path="/report_ports" exact element={<ReportsPagePorts />} />
      <Route path="/report_searchrank" exact element={<ReportsPageSearchrank />} />
      <Route path="/payment" exact element={<PaymentsPage />} />
      <Route path="/account" exact element={<AccountPage />} />
      <Route path="/Subscriptions" exact element={<SubscriptionPage />} />
      <Route path="/phones" exact element={<PhonesPage />} />
      <Route path="/audits" exact element={<PerformanceAduitsPage />} />
      <Route path="/heatmap" exact element={<HeatMapsPage />} />
    </Routes>
  );
}
export default App;
