import axios from 'axios';

async function reportError(action, error){
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if(responseMessage === "error logged successfully"){
        //console.log("error repotred successfully");
    }
}


export const AddSearchPhrase = (searchtext,location,searchEngine) => async (dispatch) => {
    try {
        const response = await axios.post('api/searchrank/add', { searchtext, location,searchEngine}, { withCredentials: true });
        dispatch({
            type: 'SEARCH_PHRASES',
            payload: response.data
        });
    }
    catch (err) {
        reportError("AddSearchPhrase", err);
    }
}


export const GetSearchPhrases = () => async (dispatch) => {
    
    try {
        const response = await axios.post('api/searchrank/get', { withCredentials: true });

        const responseMessage = response.data.message;
        

        if(responseMessage ==="no search phrases found"){
            dispatch({
                type: 'SEARCH_PHRASES',
                payload: []
            });
        }else{
            dispatch({
                type: 'SEARCH_PHRASES',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetSearchPhrases", err);
    }
}



export const GetPhrases = (phrase) => async (dispatch) => {
    try {
        //const response = await axios.post('api/searchrank/get', { currentUser, phrase });
        const response = await axios.post('api/searchrank/phrases', { phrase }, { withCredentials: true });

        const responseMessage = response.data.message;
   
        if(responseMessage ==="no search phrases found"){
            dispatch({
                type: 'PHRASES',
                payload: []
            });
        }else{
            dispatch({
                type: 'PHRASES',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetPhrases", err);
    }
}

export const SearchRankChart = () => async (dispatch)=>{ 
    try{
        const response = await axios.post('api/searchrank/chart', { withCredentials: true });
        const responseMessage = response.data.message;
        if(responseMessage === "no results found"){
            dispatch({
                type: 'SEARCH_RANK_CHART',
                payload : []
            });
        }else{
            dispatch({
                type: 'SEARCH_RANK_CHART',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("SearchRankChart", err);
    }
    
}



