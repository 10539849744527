import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ResponsivePie } from '@nivo/pie';
import 'react-tabs/style/react-tabs.css';
import { LinksChart } from '../actions/links';
import { UpTimeChart, TheStatus } from '../actions/uptime';
import { SearchRankChart } from '../actions/search';
import { GetPorts } from '../actions/ports';
import { GetSSLDaysLeft, GET_SSL_ERRORS_COUNT } from '../actions/ssl';


const DashboardAdvancedPlan = (props) => {

  console.log("=============props=================")
  console.log(props)
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LinksChart());
    dispatch(UpTimeChart());
    dispatch(TheStatus());
    dispatch(SearchRankChart());
    dispatch(GetPorts());
  }, [SearchRankChart]);

  const linksChartData = useSelector(state => state.links_list.links_chart_data);
  const uptimeChartData = useSelector(state => state.uptime_list.uptime_chart_data);
  const uptime_status = useSelector(state => state.uptime_list.uptime_status);
  const ports = useSelector(state => state.ports_list.ports);
  const user_state = useSelector(state => state.auth);
  const SSL = useSelector(state => state.ssl_errors_list.expiry);
  const DAYS_LEFT = useSelector(state => state.ssl_errors_list.days_left);
  const SSL_ERRORS = useSelector(state => state.ssl_errors_list.ssl_error_count);



  function upChart() {
    return (
      uptimeChartData.slice(0, 13).map(item => {
        return <>
          <div
            style={{ "display": "inline" }}
            className={
              (item.status === "UP") ? "circleUp boxinside" : "circleDown boxinside"
            }
            key={item._id}
          >
            <span
              style={{ "marginTop": "35px", "position": "absolute", "fontSize": "9px", "marginLeft": "1px" }}>
              {new Date(item.createdAt).getHours()}:00
            </span>
          </div>
        </>
      })
    )
  }

  function StatusText() {

    if (uptime_status.length > 0) {
      return (
        <>

          <div
            className={(uptime_status[0].status === "UP") ? "circleBlue" : "circleRed"}
          >
          </div>
          <p style={{ "textAlign": "center", "marginBottom": "30px", "marginTop": "40px" }}>
            STATUS : {(uptime_status[0].status === "UP") ? "UP" : "DOWN"}
          </p>
        </>
      )

    } else {
      return (
        <>
          <p style={{ "textAlign": "center", "marginBottom": "20px", "marginTop": "0px" }}>Down</p>
          <div className="circleRed"></div>
        </>
      )
    }
  }

  function getBrokenLinkPieChart() {
    return (
      <div style={{ height: '300px' }}>
        <div style={{ height: '100%', display: 'flex' }}>

          <ResponsivePie
            data={
              [
                {
                  "id": "Pending",
                  "label": "Pending links",
                  "value": linksChartData[1],
                  "color": "hsl(233, 70%, 50%)"
                },
                {
                  "id": "Fixed",
                  "label": "Fixed broken links",
                  "value": linksChartData[0],
                  "color": "hsl(153, 70%, 50%)"
                },


              ]
            }
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.2
                ]
              ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  2
                ]
              ]
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: 'ruby'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'c'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'go'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'python'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'scala'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'lisp'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'elixir'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'javascript'
                },
                id: 'lines'
              }
            ]}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
          />

        </div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(GetSSLDaysLeft());
    dispatch(GET_SSL_ERRORS_COUNT());
  }, []);


  function getSSLInfo() {

    if (SSL === 'none') {
      return (
        <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>Retreiving SSL infomation...</h3>
      )
    }
    else if (SSL === "ssl is not installed") {
      return <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>SSL NOT Detected</h3>
    }
    else {
      return (
        <div className="card-body row">
          <div className='col-md-7'>
            <p className='certificateExpiry'>Certificate  {(DAYS_LEFT > 0) ? " expires in" : ""} </p>
            <div className='expiryText'>
              <p>{(DAYS_LEFT > 0) ? <span style={{ "marginLeft": "60px", "paddingTop": "10px" }}>{`${DAYS_LEFT} days`}</span> : <span style={{ 'color': 'red' }}>Expired</span>}</p>
              <div className={(DAYS_LEFT > 0) ? "certificate_expires_underline" : "underline2"}></div>
            </div>
          </div>
          <div className='col-md-5'>
            <p className='certificateErrors'> {(SSL_ERRORS === 0) ? <span style={{ "marginLeft": "5px" }}>No errors</span> : 'Errors found'}</p>
            <div className='ssl-error'>
              {(SSL_ERRORS === 0) ? <i className='mdi mdi-check-circle text-success ml-4'></i> : <span style={{ "color": "red", "marginLeft": "35px" }}> {SSL_ERRORS} </span>}
              <div className={(SSL_ERRORS > 0) ? "underline2" : "certificate_expires_no_errors"}></div>
            </div>
          </div>
          {/*<p className='issued'>Certificate issued by <strong>Security Pack</strong> on <strong>12/23/2021</strong></p>*/}
        </div>
      );
    }
  }

  const ssl_errors = 0;
  const ssl_days = 500;

  return (
    <>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Up Time</p>
            <div className="card-body">
              {

                (props.status === "active") ?

                  <>
                    {StatusText()}
                    < hr style={{ "marginTop": "5px" }} />
                    <div className='wrapperLine'>
                      {upChart()}
                    </div>
                  </>
                  :
                  <di>
                    <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                      <span>Uptime service is not running</span>
                      <i
                        style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                        className="mdi mdi-alert-circle text-warning">
                      </i>
                    </p>

                    <p
                      style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                    >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                  </di>

              }


            </div>
          </div>
        </div>

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Broken links</p>
            <div className="card-body">
              {(props.status === "active") ?

                <>
                  {getBrokenLinkPieChart()}
                </>
                :
                <di>
                  <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                    <span>Broken links service is not running</span>
                    <i
                      style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                      className="mdi mdi-alert-circle text-warning">
                    </i>
                  </p>

                  <p
                    style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                  >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                </di>

              }
            </div>
          </div>
        </div>

      </div>



      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Ports</p>
            <div className="card-body">

              {

                (props.status === "active") ?

                  <>
                    <div className="col-md-12">
                      <div className="row">

                        {ports.map(item => {
                          return (
                            <div className='wrapperLine col-md-4'>
                              <div style={{ "borderBottom": "1px solid #fff", "marginBottom": "10px" }} key={item._id}>
                                <p
                                  className={(item.status === "true") ? "statusUp" : "statusDown"}
                                  style={{
                                    "fontSize": "17px",
                                    "padding": "25px",
                                    "paddingLeft": "15px",
                                  }}>
                                  {item.portName} : {item.portNumber}
                                </p>
                              </div>
                            </div>
                          )
                        })}

                        <div className='col-md-12'></div>
                        <div className='row' style={{ "marginTop": "50px", "borderTop": "1px solid #f4f4f4", "paddingTop": "20px" }}>
                          <hr />
                          <div className='MyrecTGreen'></div><span style={{ "fontSize": "12px", "marginRight": "10px", "marginTop": "2px", "fontWeight": "100" }}>Service up</span>
                          <div className='MyrecTRed'></div><span style={{ "fontSize": "12px", "marginRight": "10px", "marginTop": "2px", "fontWeight": "100" }}>Service down</span>
                        </div>
                      </div>
                    </div>
                  </>

                  :
                  <di>
                    <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                      <span>Ports service is not running</span>
                      <i
                        style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                        className="mdi mdi-alert-circle text-warning">
                      </i>
                    </p>

                    <p
                      style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                    >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                  </di>


              }

            </div>
          </div>
        </div>

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Secure Sockets Layer (SSL)</p>
            {(props.status === "active") ?

              <>
                {getSSLInfo()}
              </>
              :
              <di>
                <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                  <span>SSL service is not running</span>
                  <i
                    style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                    className="mdi mdi-alert-circle text-warning">
                  </i>
                </p>

                <p
                  style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
              </di>

            }
          </div>
        </div>
      </div>
    </>
  )
}
export default DashboardAdvancedPlan;