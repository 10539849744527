import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import Sidebar from '../../components/sidebar';
import Stastics from '../../components/stasticsHeader';
import Header from '../../components/header';
import { GetReport, GetLinksReport, GetPortsReport } from '../../actions/reports';
import { GetPorts } from '../../actions/ports';

const ReportsPageUptime = () => {



  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());


  const dispatch = useDispatch();
  const downtimereport = useSelector(state => state.report.down_report);
  //check if there was any system error
  const SYSTEM_ERROR = useSelector(state => state.system_errors.system_error);
  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;


  function generateReport() {
    dispatch(GetReport(startDate, endDate));
  }

  function getTotalDownTime() {
    if (Object.keys(downtimereport).length !== 0) {
      var DAYS, HOURS, MINUTES = ""
      if (downtimereport.TotalResponseTime.days == 0) {
        DAYS = ""
      } else {
        DAYS = `${downtimereport.TotalResponseTime.day}days `
      }
      if (downtimereport.TotalResponseTime.hours == 0) {
        HOURS = ""
      } else {
        HOURS = `${downtimereport.TotalResponseTime.hours}hrs `
      }
      if (downtimereport.TotalResponseTime.minutes == 0) {
        MINUTES = ""
      } else {
        MINUTES = `${downtimereport.TotalResponseTime.minutes}min `
      }
      const combined = DAYS + HOURS + MINUTES

      return (
        <>
          {combined}
        </>
      )
    }
  }


  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <p className="card-title">Down Time Report</p>
                  <div className="card-body">
                    <div className="col-md-12">
                      {
                        (SYSTEM_ERROR)
                          ?
                          <div
                            style={{
                              "fontSize": "50%", "border": "1px solid red", "padding": "5px",
                              "backgroundColor": "red", "color": "#fff", "borderRadius": "5px",
                              "marginBottom": "10px"
                            }}>
                            System Error has occured, and system has already sent the error to support.
                            please refresh your browser and try again and see if the error persists.
                          </div>
                          :
                          ""
                      }
                    </div>
                    <div className="table-responsive">


                      <div className="boxinside">
                        <span style={{ "fontSize": "14px" }}>Start date</span><br />
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <div className="boxinside" style={{ "marginLeft": "10px", "marginRight": "10px" }} >
                        <span style={{ "fontSize": "14px" }}>End date</span><br />
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <p className="boxinside" style={{"marginTop":"32px"}}>
                        <button type="button" onClick={generateReport} className="btn btn-inverse-primary btn-fw">Generate Report</button>
                      </p>

                    </div>

                    <div style={{ "BorderBottom": " 1px solid #ccc", "margin": "50px 0" }}>

                      <h6>Total Down Time : {getTotalDownTime()}
                      </h6>


                      { /*
                      <h5>
                       Average Down Time : {getAverageTotalDownTime()} 
                      </h5>
                      */}
                    </div>

                    <div style={{ "marginTop ": "60px" }} className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>
                              <div style={{ "position": "relative" }}>
                                <div style={{ "border": "0px", "height": "5px", "marginLeft": "40px", "position": "absolute", "top": "8px" }}>Down</div>
                              </div>
                              <div style={{ "border": "0px" }}>
                                <div style={{ "backgroundColor": "red", "height": "30px", "width": "30px", "borderRadius": "15px" }}>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div style={{ "position": "relative" }}>
                              <div style={{ "border": "0px", "height": "5px", "marginLeft": "40px", "position": "absolute", "top": "8px" }}>UP</div>
                              </div>
                              <div style={{ "border": "0px" }}>
                                <div style={{ "backgroundColor": "green", "height": "30px", "width": "30px", "borderRadius": "15px" }}>
                                </div>
                              </div>
                            </th>
                            <th><div style={{ "border": "0px" }}>Down Time</div></th>

                          </tr>
                        </thead>
                        <tbody>
                          {(Object.keys(downtimereport).length === 0 && downtimereport.constructor === Object)
                            ?
                            <></>
                            :
                            downtimereport.downtimers.map(item => {
                              const { Added, created } = item;

                              const localtime_added = new Date(Added);
                              const ly = localtime_added.getFullYear();
                              const lm = parseInt(localtime_added.getMonth()) + 1;
                              const ld = localtime_added.getDate();
                              const lh = localtime_added.getHours()
                              const lmi = localtime_added.getMinutes()

                              const localtime_created = new Date(created);
                              const cy = localtime_created.getFullYear();
                              const cm = parseInt(localtime_created.getMonth()) + 1;
                              //const cd = localtime_created.getDay();
                              const cd = localtime_created.getDate()
                              const ch = localtime_created.getHours();
                              const cmi = localtime_created.getMinutes();
                              localtime_created.getHours();
                              localtime_created.getMinutes();

                              return (
                                <tr key={item._id}>
                                  <td>{`Date ${cd}/${cm}/${cy} `} {`${"     "}`}   {`Time ${ch}:${cmi}`}</td>
                                  <td>{`Date ${ld}/${lm}/${ly}`} {`${"     "}`}   {`Time ${lh}:${lmi}`}</td>
                                  <td>
                                    {(item.Duration.days == 0) ? "" : `${item.Duration.days} days `}
                                    {(item.Duration.hours == 0) ? "" : `${item.Duration.hours} hrs `}
                                    {(item.Duration.minutes == 0) ? "" : `${item.Duration.minutes} min `}
                                    {(item.Duration.seconds == 0) ? "" : `${item.Duration.seconds} sec`}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default ReportsPageUptime;