import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import countryCodes from "country-codes-list";
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { AddSearchPhrase, GetSearchPhrases } from '../actions/search';
import { GetPhrases } from '../actions/search';
import { useNavigate } from 'react-router-dom';

const SearchRankPage = () => {

  const navigate = useNavigate();

  //console.log(codes)
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchEngine, setSearchEngine] = useState("");
  const [arrayCodes, setArrayCodes] = useState([]);

  const search_phrases = useSelector(state => state.search_rank_list.seach_phrases);
  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;

  function addSearchPhrase() {
    if (searchText !== "" && searchLocation !== "" && searchEngine !=="") {
      const location = searchLocation.toLocaleLowerCase();
      dispatch(AddSearchPhrase(searchText, location, searchEngine));
      setSearchText("");
    } else {
      alert("Search phrase or location is empty");
    }
  }

  useEffect(() => {
    dispatch(GetSearchPhrases());
  }, []);

  useEffect(() => {
    var arry = [];
    const codes = countryCodes.customList('countryCode');
    for (let Key in codes) {
      const obj = { shortCode: Key, country: codes[Key] }
      arry.push(obj)
      setArrayCodes(arry);
    }
  }, []);

  function phraseClick(e,phrase){
    e.preventDefault();
    dispatch(GetPhrases(phrase));
    navigate("/phrases");
  }

  return (
    <>
       <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
      <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                <p className="card-title">Search Ranking</p>
                  <div className="card-body">
                    <div className="boxinside">

                      <span style={{ "fontSize": "14px", "marginBottom": "50px" }}>Search Phrase</span>
                      <textarea
                        value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        className="form-control form-control-sm"
                        rows="1" cols="35"
                        style={{ "marginTop": "10px", "marginBottom": "10px" }}
                        placeholder="Enter search phrase"></textarea>



                      <span style={{ "fontSize": "14px", "marginBottom": "50px" }}>Search engine</span>
                      <select
                        className="form-control form-control-sm"
                        style={{ "marginTop": "10px", "marginBottom": "10px" }}
                        value={searchEngine}
                        onChange={(e) => { setSearchEngine(e.target.value) }}
                      >
                        <option value="">Select engine</option>
                        <option value="Google">Google</option>
                      </select>

                      <span style={{ "fontSize": "14px", "marginBottom": "50px" }}>Search location</span>
                      <select
                        className="form-control form-control-sm"
                        value={searchLocation}
                        onChange={(e) => { setSearchLocation(e.target.value) }}
                        style={{ "marginTop": "10px", "marginBottom": "10px" }}
                      >
                        <option value="">Select location</option>
                        {arrayCodes.map(code => {

                          return (
                            <option key={code.shortCode} value={code.shortCode}>{code.country}</option>
                          )
                        })}

                      </select>


                      <button
                        className="btn btn-primary btn-fw"
                        onClick={addSearchPhrase}
                        style={{ "marginTop": "10px", "marginBottom": "40px" }}>Add</button>
                    </div>

                    <div>

                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Search Phrases</th>
                            <th>Url</th>
                            <th>Search location</th>
                            <th>Search engine</th>
                            <th>Page</th>
                            <th>Position</th>
                          </tr>
                        </thead>
                        <tbody>
                          {search_phrases.map(item => {
                            let i = 0;

                            return (
                              <tr key={item._id}>
                                <td >
                                 <a onClick={(e)=>{phraseClick(e,item.phrase)}} className="searchPhraseLink" style={{"color":"blue"}}>{item.phrase}</a>
                                
                                </td>
                                <td>{item.website}</td>
                                <td>{item.location}</td>
                                <td>{item.engine}</td>
                                <td>{item.page}</td>
                                <td>{item.position}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default SearchRankPage;