import React from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar';

const AccessibilityBar = ({ percentage }) => {
    const data = [
        {
            id: 'Accessibility',
            data: [
                { x: 'completed', y: percentage },
                { x: '', y: 100 - percentage }
            ]
        }
    ];

    return (
        <div style={{ height: '200px', width: '200px', "marginLeft":"70px","marginBottom":"10px" }}>
            <ResponsiveRadialBar
                data={data}
                startAngle={0}
                endAngle={360}
                innerRadius={0.60}
                padding={0.35}
                colors={{ scheme: 'nivo' }}
                radialAxisStart={null}
                circularAxisOuter={null}
                enableLabels={false}
                enableRadialGrid={false}
                tracksColor="#e0e0e0"
            />
            <div
                style={{
                    position: 'absolute',
                    top: '42%',
                    left: '47%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    color: '#636262'
                }}
            >
                {`${percentage}%`}
            </div>
        </div>
    );
};

export default AccessibilityBar;
