import axios from 'axios';

async function reportError(action, error){
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if(responseMessage === "error logged successfully"){
        //console.log("error repotred successfully");
    }
}


export const Get_SSL_ERRORS = () => async (dispatch) => {
    try {
        const response = await axios.post('api/ssl/geterrors', { withCredentials: true });
        const responseMessage = response.data.message;

        if (responseMessage) {
            dispatch({
                type: 'ERRORS',
                payload: []
            });
        } else {
            dispatch({
                type: 'ERRORS',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GET_SSL_ERRORS", err);
    }
}


export const GetSSLErrorsByDate = (DATE) => async (dispatch) => {
    try {
        const response = await axios.post('api/ssl/geterrorsbydate', { DATE }, { withCredentials: true });
        const responseMessage = response.data.message;
        if (responseMessage === "no ssl errors found") {
            dispatch({
                type: 'ERRORS',
                payload: []
            });
        } else {
            dispatch({
                type: 'ERRORS',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GETSSErrorsByDate", err);
    }
}

export const GetSSLDaysLeft = () => async (dispatch) => {
    
    try {
        // get expiry date
        async function Request() {
            const response = await axios.post('api/ssl/sslinfo', { withCredentials: true });
            const url_ = response.data.websiteUrl;
            const newURL = new URL(`${url_}`);
            //alert(newURL.protocol)
            if (response.data.ssl_expiry === "none") {
                dispatch({
                    type: "SSL_EXPIRY_DATE",
                    payload: "none"
                });
            }
            else if (newURL.protocol === "http:") {
                dispatch({
                    type: "SSL_EXPIRY_DATE",
                    payload: "ssl is not installed"
                });
            } else {
                //console.log(response.data.ssl_expiry_date)
                let date_1 = new Date(`${response.data.ssl_expiry_date}`);
                let date_2 = new Date();
                let difference = date_1.getTime() - date_2.getTime();
                let remainingDays = Math.ceil(difference / (1000 * 3600 * 24));
                dispatch({
                    type: "DAYS_LEFT",
                    payload: remainingDays
                });
            }
        }
        setInterval(Request, 5000);
    }
    catch (err) {
        reportError("GetSSLDaysLeft", err);
    }
}

export const GET_SSL_ERRORS_COUNT = () => async (dispatch) => {
    
    try {
        const response = await axios.post('api/ssl/geterrorscount', { withCredentials: true });
     
        if (response.data.theCount > 0) {
            dispatch({
                type: 'ERRORS_COUNT',
                payload: response.data.theCount
            });
        } else {
            dispatch({
                type: 'ERRORS_COUNT',
                payload: 0
            });
        }

        async function Request(){
            const response = await axios.post('api/ssl/geterrorscount', { withCredentials: true });
            //const responseMessage = response.data.message;

            if (response.data.theCount > 0) {
                dispatch({
                    type: 'ERRORS_COUNT',
                    payload: response.data.theCount
                });
            } else {
                dispatch({
                    type: 'ERRORS_COUNT',
                    payload: 0
                });
            }
        }
        setInterval(Request, 5000);
    }
    catch (err) {
        reportError("GET_SSL_ERRORS_COUNT", err);
    }
}