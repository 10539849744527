import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Login, ActivateForgotPasswordPage, GotoRegisterPage, CleanLoginMessages } from '../actions/auth';
import { DomHandler } from 'primereact/utils';



const LoginPage = () => {

    let navigate = useNavigate();

    //check if the user is logged in 
    const user_state = useSelector(state => state.auth);
    const { currentUser, login_error, registrationSuccess, its_password_reset_message } = user_state
    //check if there was any system error
    const SYSTEM_ERROR = useSelector(state => state.system_errors.system_error);

    const dispatch = useDispatch();

    // redirect the url
    useEffect(() => {
        if (currentUser !== null && currentUser !== undefined) {
            return navigate("/");
        }
    }, []);

    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");

    function login() {

        if (email !== '' && password !== '') {
            const user = {
                email,
                password
            }
            dispatch(Login(user));
        } else {
            alert('Email or Password empty!');
        }
    }

    function goToRegister() {
        // redirect to website pricing 
    }

    function handle_click(){
        dispatch(ActivateForgotPasswordPage());
        return navigate("/forgotpassword");
    }

    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div style={{ "backgroundColor": "#37517e","paddingLeft":"300px" }} className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                        <div className="row flex-grow">
                            <div className="col-lg-8 d-flex align-items-center justify-content-center">
                                <div className="auth-form-transparent text-left p-3">
                                    <div className="brand-logo2" style={{ "marginTop": "10px", "marginBottom": "40px", "paddingLeft":"40px" }}>
                                        <img src="../../images/logo-small.png" width="250px" alt="logo" style={{  }} /><br />
                                    </div>
                                    <div style={{ "backgroundColor": "#fff", "padding": "30px 40px", "borderRadius": "3px", "width": "100%" }}>
                                        {
                                          (SYSTEM_ERROR) 
                                          ?
                                          <div 
                                             style={{"fontSize":"50%", "border":"1px solid red", "padding":"5px",
                                             "backgroundColor":"red","color":"#fff", "borderRadius":"5px",
                                             "marginBottom":"10px"
                                             }}>
                                            System Error has occured, and system has already sent the error to support.
                                            please refresh your browser and try again and see if the error persists.
                                          </div>
                                          :
                                          ""
                                        }
                                        <h4>Login into your Console</h4>
                                        {(its_password_reset_message) 
                                        ? 
                                            <p>Password was successfully reset please check your email use the password send to you"</p>
                                        : 
                                            ""
                                        }

                                        <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                            (registrationSuccess)
                                                ?
                                                <div className="badge badge-success"><span style={{ "fontSize": "16px" }}>Registration Successful please login</span></div> :
                                                ''
                                        }
                                        </div>
                                        <div className="form-group">
                                            <p>Username</p>
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-account-outline"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    value={email}
                                                    onChange={(e) => { setemail(e.target.value) }}
                                                    type="text"
                                                    className="form-control form-control-lg border-left-0"
                                                    id="exampleInputEmail"
                                                    placeholder="Username"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <p>Password</p>
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-lock-outline"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    value={password}
                                                    onChange={(e) => { setpassword(e.target.value) }}
                                                    type="password"
                                                    className="form-control form-control-lg border-left-0"
                                                    id="exampleInputPassword"
                                                    placeholder="Password"
                                                />
                                            </div>
                                        </div>

                                        <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                            (login_error)
                                                ?
                                                <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>Login failed wrong username or password</span></div> :
                                                ''
                                        }
                                        </div>

                                        <button onClick={login} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">LOGIN</button>

                                        <div className="my-2 d-flex justify-content-between align-items-center">
                                            <p><Link  onClick={handle_click} className="auth-link">Forgot password?</Link></p>
                                        </div>

                                        <div className="text-center mt-4 font-weight-light">
                                            <p>Don't have an account? <Link to="/register" className="text-primary text-white" onClick={goToRegister}>Create</Link></p>
                                        </div>

                                    </div>
                                    <div className="col-lg-12 login-half-bg d-flex flex-row">
                                        <br /><br />
                                        <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; 2020  All rights reserved.</p>
                                    </div>


                                </div>
                            </div>
                            
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}
export default LoginPage;