export default (state = { ports: [], port:[] }, action) => {

    if (action.type === "PORTS"){
        return {
            ...state,
            ports: action.payload
        }
    }
    else if(action.type ==="PORT"){
        return {
            ...state,
            port : action.payload
        }
    }
    else {
        return state;
    }
}