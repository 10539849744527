import axios from 'axios';

async function reportError(action, error){
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if(responseMessage === "error logged successfully"){
       // console.log("error repotred successfully");
    }
}


export const GetWebsiteHealthChart = () => async (dispatch) => {
    try {
        const response = await axios.post('api/sitehealth/chart', { withCredentials: true });
        const responseMessage = response.data.message;
        if(responseMessage ==="no results found"){
            dispatch({
                type: 'SITE_HEALTH_CHART',
                payload: []
            });
        }else{
            dispatch({
                type: 'SITE_HEALTH_CHART',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetSearchPhrases", err);
    }
}


export const GetAudits = (category, currentPage) => async (dispatch) => {
    
    try{
        const response = await axios.post('api/sitehealth/audits', {category, page: currentPage, limit : 10 }, { withCredentials: true });

        const responseMessage = response.data.message;
 
        if(responseMessage ==="no audits found"){
            dispatch({
                type: 'AUDITS',
                payload: []
            });
        }else{
            dispatch({
                type: 'AUDITS',
                payload: response.data
            });
        }
    }
    catch (err) {
        //console.log(err);
       // reportError("GetPhrases", err);
    }
}





