import axios from 'axios';


async function reportError(action, error) {
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if (responseMessage === "error logged successfully") {
      //  console.log("error repotred successfully");
    }
}

export const AddPort = (obj) => async (dispatch) => {

    try {
        const response = await axios.post('api/ports/addport', { obj }, { withCredentials: true });
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        } else {
            dispatch({
                type: 'PORTS',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("AddPort", err);
    }
}

export const UpdatePort = (obj) => async (dispatch) => {

    try {
        const response = await axios.post('api/ports/updateport', { obj }, { withCredentials: true });
        const responseMessage = response.data.message;
        if(responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000);
        }else{
            dispatch({
                type: 'PORTS',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("UpdatePort", err);
    }
}


export const DeletePort = (id) => async (dispatch) => {

    try {
        const response = await axios.post('api/ports/deleteport', { id: id }, { withCredentials: true });
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        } else {
            dispatch({
                type: 'PORTS',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("DeletePort", err);
    }
}


export const GetPorts = () => async (dispatch ) =>{
    try {
        const response = await axios.post('api/ports/getports', { withCredentials: true });
        const responseMessage = response.data.message;

        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        } else {
            if (responseMessage === "no ports found") {
                dispatch({
                    type: 'PORTS',
                    payload: []
                });
            } else {
                dispatch({
                    type: 'PORTS',
                    payload: response.data
                });
            }
            // Request every hour
            async function Request() {
                const response = await axios.post('api/ports/getports', { withCredentials: true });
                const responseMessage = response.data.message;
                if (responseMessage === "no ports found") {
                    dispatch({
                        type: 'PORTS',
                        payload: []
                    });
                } else {
                    dispatch({
                        type: 'PORTS',
                        payload: response.data
                    });
                }
            }
            setInterval(Request, 5000);
        }
    }
    catch (err) {
        reportError("GetPorts", err);
    }
}

export const GetPort = (id) => async (dispatch, getState) => {
    const currentUser = getState().auth.currentUser;
    try{
        const response = await axios.post('api/ports/getport', { id }, { withCredentials: true });
        const responseMessage = response.data.message;
        if(responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        }else{
            dispatch({
                type: 'PORT',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("GetPort", err);
    }
}


