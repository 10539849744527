export default (state = { links: [], links_chart_data : [0,0]}, action) => {

    if (action.type === "LINKS") {
        return {
            ...state,
            links: action.payload
        }
    }
    else if(action.type === "LINKS_CHART"){
        return{
            ...state,
            links_chart_data : action.payload
        }
    }
    else {
        return state;
    }

}