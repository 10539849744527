import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { AddNumber, GetNumbers, GetNumber, DeleteNumber} from '../actions/phones';

import EditBtn from './children/ports/editbutton';
import EditInputPort from './children/ports/portNumberInput';
import EditInputName from './children/ports/portNameInput';

const PhonesPage = () => {

  const dispatch = useDispatch();
  const phones = useSelector(state => state.phonelist.phones) || [];
  const phone = useSelector(state => state.phonelist.phone) || [];

  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;
  //check if there was any system error
  const SYSTEM_ERROR = useSelector(state => state.system_errors.system_error);


  
  const [Number, setNumber] = useState("");
  const [numberUpdate, setNumberUpdate] = useState("");
  const [Description, setDescription] = useState("");
  const [updateDescription, setDescriptionUpdate] = useState("");
  const [hiddenId, setHiddenId] = useState("");

  /*
  function updatePort() {
    const obj = {
      id: hiddenId,
      port_name: portUpdateName,
      port_number: portNumber,
      hostServer: portUpdateHost,
      selected: port
    }
    if (hiddenId !== "") {
      dispatch(UpdatePort(obj));
    } else {
      console.log("please select the port to update")
    }

  }
  
  /*function firstInput(Value) {
    setportUpdateInputName(Value);
  }
  function secondInput(Value) {
    setportUpdateInputNumber(Value)
  }*/

    function deleteNumber() {
      if (hiddenId !== "") {
       dispatch(DeleteNumber(hiddenId));
       setDescriptionUpdate(" ");
       setNumberUpdate(" ");
      }
    }

  function _retrieveNumber(Value) {

    setHiddenId(Value);
    if (Value !== "no value") {
     dispatch(GetNumber(Value));
    }
  }

  function createNumber() {
    dispatch(AddNumber({
      phone: Number,
      description: Description
    }))
  }
  useEffect(() => {
    dispatch(GetNumbers());
    //if (port.length > 0) {
    //firstInput(port[0].portName)
    //}
  }, []);

  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title">Phones</div>
                  <div className="card-body">
                    <div className="col-md-12">
                      {
                        (SYSTEM_ERROR)
                          ?
                          <div
                            style={{
                              "fontSize": "50%", "border": "1px solid red", "padding": "5px",
                              "backgroundColor": "red", "color": "#fff", "borderRadius": "5px",
                              "marginBottom": "10px"
                            }}>
                            System Error has occured, and system has already sent the error to support.
                            please refresh your browser and try again and see if the error persists.
                          </div>
                          :
                          ""
                      }
                    </div>

                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>

                      <div className="col-md-12">
                        <h4>Add Phone to Monitor</h4>
                      </div>

                      <div className="boxinside" style={{ "marginRight": "15px" }}>


                        <span style={{ "fontSize": "14px" }}>Telphone number(with country code)</span>
                        <input
                          value={Number}
                          onChange={(e) => { setNumber(e.target.value) }}
                          type="input"
                          className="form-control form-control-sm"
                          placeholder="+27781160107"
                        />
                      </div>

                      <div className="boxinside" style={{ "marginRight": "15px" }}>
                        <span style={{ "fontSize": "14px" }}>Description</span>
                        <input
                          value={Description}
                          onChange={(e) => { setDescription(e.target.value) }}
                          type="input"
                          className="form-control form-control-sm"
                          placeholder="e.g Johannesburg reception" />
                      </div>


                      <div className="boxinside">
                        <br />
                        <button type="button" onClick={createNumber} className="btn btn-primary btn-fw">Add</button>
                      </div>

                      <div>

                        <br /><br /><br />
                        <table style={{ "marginTop":"70px"}} className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Number</th>
                              <th>Descrption</th>
                            </tr>
                          </thead>
                          <tbody>
                            {phones.map(item => {

                              return (
                                <tr key={item._id}>
                                  <td>
                                    {item.Number}
                                  </td>
                                  <td>
                                    {item.description}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>


                      </div>
                      <hr style={{ "marginTop": "40px", "marginBottom": "20px" }} />
                      <div className="ports">
                        <span style={{ "fontSize": "14px", "fontWeight": "bold" }}>Edit Port</span>

                        <select
                          className="form-control form-control-sm"
                          id="exampleFormControlSelect3"
                          style={{ "width": "50%", "marginLeft": "2px", "marginTop": "20px" }}
                          onChange={(e) => { _retrieveNumber(e.target.value) }}
                        >
                          <option value="no value">Select phone number</option>

                          {phones.map(item => {
                            return (
                              <option
                                key={item._id}
                                value={item._id}
                              >
                                {item.Number}
                              </option>
                            )
                          })}
                        </select>

                        <input
                          type="text"
                          value={(phone.length > 0) ? phone[0]._id : ""}
                          onChange={(e) => { setHiddenId(e.target.value) }}
                          hidden />
                        {/*<div 
                             style={{ "marginTop": "30px", "width": "50%", "fontWeight": "bold", "fontSize":"16px" }}>
                        {(port.length > 0) ? 'Editing ' + port[0].portName + ":" + port[0].portNumber : ""} </div>*/}

                        <span style={{ "fontSize": "13px" }}>Number</span>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          style={{ "marginTop": "2px", "marginBottom": "10px", "width": "50%" }}
                          value={numberUpdate}
                          onChange={(e) => { setNumberUpdate(e.target.value) }}
                          placeholder={(phone.length > 0) ? phone[0].Number : ""}
                        />
                        <span style={{ "fontSize": "13px" }}>Description</span>
                        <input type="text"
                          className="form-control form-control-sm"
                          style={{ "width": "50%" }}
                          value={updateDescription}
                          // onChange={(e) => { setportUpdateInputNumber(e.target.value) }}
                          onChange={(e) => { setDescriptionUpdate(e.target.value) }}
                          placeholder={(phone.length > 0) ? phone[0].description : ""}
                        />
                     
                        {/*<button onClick={updatePort} type="button" className="btn btn-warning btn-fw" style={{ "marginTop": "30px" }}>Update</button>*/}
                        <button onClick={deleteNumber} type="button" className="btn btn-danger btn-fw" style={{ "marginLeft": "7px", "marginTop": "30px" }}>Delete</button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default PhonesPage;