import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ResponsivePie } from '@nivo/pie';
import { Line, ResponsiveLine } from '@nivo/line'
import { area, curveMonotoneX } from 'd3-shape'
import { generateDrinkStats } from '@nivo/generators'
import { ResponsiveBump } from '@nivo/bump'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Tooltip } from 'primereact/tooltip';
import 'react-tabs/style/react-tabs.css';
import { LinksChart } from '../actions/links';
import { UpTimeChart, TheStatus } from '../actions/uptime';
import { SearchRankChart } from '../actions/search';
import { GetPorts } from '../actions/ports';
import { GetSSLDaysLeft, GET_SSL_ERRORS_COUNT } from '../actions/ssl';


const DashboardFreePlan = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LinksChart());
    dispatch(UpTimeChart());
    dispatch(TheStatus());
    dispatch(SearchRankChart());
    dispatch(GetPorts());
  }, [SearchRankChart]);

  const linksChartData = useSelector(state => state.links_list.links_chart_data);
  const uptimeChartData = useSelector(state => state.uptime_list.uptime_chart_data);
  const uptime_status = useSelector(state => state.uptime_list.uptime_status);
  const rank_data = useSelector(state => state.search_rank_list.rank_data);
  const ports = useSelector(state => state.ports_list.ports);
  const user_state = useSelector(state => state.auth);
  const SSL = useSelector(state => state.ssl_errors_list.expiry);
  const DAYS_LEFT  = useSelector(state => state.ssl_errors_list.days_left); 
  const SSL_ERRORS  = useSelector(state => state.ssl_errors_list.ssl_error_count);
  const { currentUser } = user_state;


  function upChart() {
    return (
      uptimeChartData.slice(0, 13).map(item => {
        return <>
          <div
            style={{ "display": "inline" }}
            className={
              (item.status === "UP") ? "circleUp boxinside" : "circleDown boxinside"
            }
            key={item._id}
          >
            <span
              style={{ "marginTop": "35px", "position": "absolute", "fontSize": "9px", "marginLeft": "1px" }}>
             {new Date(item.createdAt).getHours()}:00
            </span>
          </div>
        </>
      })
    )
  }

  function StatusText() {

    if (uptime_status.length > 0){
      return (
        <>

          <div
            className={(uptime_status[0].status === "UP") ? "circleBlue" : "circleRed"}
          >
          </div>
          <p style={{ "textAlign": "center", "marginBottom": "30px", "marginTop": "40px" }}>
            STATUS : {(uptime_status[0].status === "UP") ? "UP" : "DOWN"}
          </p>
        </>
      )

    } else {
      return (
        <>
          <p style={{ "textAlign": "center", "marginBottom": "20px", "marginTop": "0px" }}>Down</p>
          <div className="circleRed"></div>
        </>
      )
    }
  }

  function getBrokenLinkPieChart() {
    return (
      <div style={{ height: '300px' }}>
        <div style={{ height: '100%', display: 'flex' }}>

          <ResponsivePie
            data={
              [
                {
                  "id": "Pending",
                  "label": "Pending links",
                  "value": linksChartData[1],
                  "color": "hsl(233, 70%, 50%)"
                },
                {
                  "id": "Fixed",
                  "label": "Fixed broken links",
                  "value": linksChartData[0],
                  "color": "hsl(153, 70%, 50%)"
                },


              ]
            }
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.2
                ]
              ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  2
                ]
              ]
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: 'ruby'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'c'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'go'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'python'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'scala'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'lisp'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'elixir'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'javascript'
                },
                id: 'lines'
              }
            ]}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
          />

        </div>
      </div>
    )
  }
  function getBumpGraph() {
    return (
      <div style={{ height: '300px' }}>
        <div style={{ height: '100%', display: 'flex' }}>
          <ResponsiveBump
            data={rank_data}
            colors={{ scheme: 'spectral' }}
            lineWidth={3}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            pointSize={10}
            activePointSize={16}
            inactivePointSize={0}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            activePointBorderWidth={3}
            pointBorderColor={{ from: 'serie.color' }}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -36
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'ranking',
              legendPosition: 'middle',
              legendOffset: -40
            }}
            margin={{ top: 5, right: 100, bottom: 40, left: 60 }}
            axisRight={null}
          />
        </div>
      </div>
    )
  }


  useEffect(() => {
    dispatch(GetSSLDaysLeft());
    dispatch(GET_SSL_ERRORS_COUNT());
  }, []);


  function getSSLInfo(){

    if (SSL === 'none') {
      return (
        <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>Retreiving SSL infomation...</h3>
      )
    }
    else if (SSL === "ssl is not installed") {
     return <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>SSL NOT Detected</h3>
    }
    else {
      return (
        <div className="card-body row">
          <div className='col-md-7'>
            <p className='certificateExpiry'>Certificate  {(DAYS_LEFT > 0) ? " expires in" : ""} </p>
            <div className='expiryText'>
              <p>{(DAYS_LEFT > 0) ? `${DAYS_LEFT} days` : <span style={{ 'color': 'red' }}>Expired</span>}</p>
              <div className={(DAYS_LEFT > 0) ? "underline" : "underline2"}></div>
            </div>
          </div>
          <div className='col-md-4'>
            <p className='certificateErrors'> {(SSL_ERRORS === 0) ? "No errors" : 'Errors found'}</p>
            <div className='ssl-error'>
              {(SSL_ERRORS === 0) ? <i className='mdi mdi-check-circle text-success ml-4'></i> : <span style={{"color":"red", "marginLeft":"35px"}}> {SSL_ERRORS} </span> }
              <div className={(SSL_ERRORS > 0) ? "underline2" : "underline"}></div>
            </div>
          </div>
         {/*<p className='issued'>Certificate issued by <strong>Security Pack</strong> on <strong>12/23/2021</strong></p>*/} 
        </div>
      );
    }



  }

  const ssl_errors = 0;
  const ssl_days = 500;

  return (
    <>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Up Time</p>
            <div className="card-body">

              {StatusText()}

              <hr style={{ "marginTop": "5px" }} />
              <div className='wrapperLine'>
                {upChart()}
              </div>


            </div>
          </div>
        </div>

      

      </div>

  
    </>
  )
}
export default DashboardFreePlan;