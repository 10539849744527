import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { passwordStrength } from 'check-password-strength';
import { ChangePassword, PasswordErrorSame, PasswordNotMatch, PasswordError, PasswordOK, PasswordWarning, checkPasswordstrength } from '../actions/auth';


const SettingsPage = () => {

  const dispatch = useDispatch();
  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;

  const [oldpassword, setOldpassword] = useState("");
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');


  var errorFlag = [];
  const {
    password_not_match,
    password_error,
    password_warning,
    password_ok,
    genereated_password_error,
    password_strength,
    password_short,
    password_error_same,
    old_password_not_match,
    password_change_success
  } = user_state

  function _ChangePassword(e) {
    if (password !== repeatPassword) {
      dispatch(PasswordNotMatch());
      errorFlag.push("password not matching");
    }
    //check password length
    if (password.length < 6) {
      dispatch(PasswordError("The password should have minimum of 6 characters"));
      errorFlag.push("password error");
    }
    if (passwordStrength(`${password}`).value === "Too weak") {
      dispatch(PasswordError("The password is too weak"));
    }
    if (passwordStrength(`${password}`).value === "Medium") {
      dispatch(PasswordWarning());
    }
    /*if (passwordStrength(`${password}`).value === "Strong") {
      dispatch(PasswordOK());
    }*/

    if(oldpassword == password){
      dispatch(PasswordErrorSame());
      errorFlag.push("password error");
    }

    // if error not dispatch action
    if (errorFlag.length === 0) {
      const passwords = {
        oldpassword : oldpassword,
        newpassword : password
    }
      dispatch(ChangePassword(passwords));
    }
  }

  function SET_PASSWORD(e){
    dispatch(checkPasswordstrength(e.target.value));
    setPassword(e.target.value);
  }



  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title">Account settings</div>
                  <div className="card-body">
                    <div className="card-description wrapperLineh" style={{ "marginTop": "10px", "marginBottom": "5px", "paddingTop": "0" }}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className='col-md-9'>
                          <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                (password_change_success)
                                  ?
                                  <div className="badge badge-success"><span style={{ "fontSize": "16px" }}>Password changed Successful</span></div> :
                                  ''
                              }
                              </div>
                            <p>Old password</p>
                            <div className="input-group">
                              <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0">
                                  <i className="mdi mdi-lock-outline text-primary"></i>
                                </span>
                              </div>
                              <input type="password"
                                className="form-control form-control-md border-left-0"
                                id="exampleInputPassword1"
                                placeholder="Enter password"
                                value={oldpassword}
                                onChange={(e) => setOldpassword(e.target.value)}
                              />
                            </div> 
                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                (old_password_not_match)
                                  ?
                                  <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>Password does not match</span></div> :
                                  ''
                              }
                              </div>
                            <br />
                            <div className="form-group">

                              <p>New Password</p>
                              <div className="input-group">
                                <div className="input-group-prepend bg-transparent">
                                  <span className="input-group-text bg-transparent border-right-0">
                                    <i className="mdi mdi-lock-outline text-primary"></i>
                                  </span>
                                </div>
                                <input type="password"
                                  className="form-control form-control-md border-left-0"
                                  id="exampleInputPassword"
                                  placeholder="Enter password"
                                  value={password}
                                  onChange={(e) => SET_PASSWORD(e)}
                                />
                                {(password_strength === "Medium") ? <span style={{ "position": "absolute", "marginLeft": "260px", "zIndex": "10", "marginTop": "20px", "fontSize": "15px" }}><i className="mdi mdi-alert-circle text-warning"></i>Medium</span> : ""}
                                {(password_strength === "Weak") ? <span style={{ "position": "absolute", "marginLeft": "260px", "zIndex": "10", "marginTop": "20px", "fontSize": "15px" }}><i className="mdi mdi-close-circle text-danger"></i>Weak</span> : ""}
                                {(password_short) ? <span style={{ "position": "absolute", "marginLeft": "260px", "zIndex": "10", "marginTop": "20px", "fontSize": "15px" }}><i className="mdi mdi-alert-octagon text-danger"></i>Short</span> : ""}
                              </div>
                              <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                (password_error_same)
                                  ?
                                  <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}></span>Old password and new password must be different</div> :
                                  ''
                              }
                              </div>
                              <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                (password_error)
                                  ?
                                  <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>{genereated_password_error}</span></div> :
                                  ''
                              }
                              </div>
                              <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                (password_warning)
                                  ?
                                  <div className="badge badge-warning"><span style={{ "fontSize": "16px" }}></span>Strength : medium</div> :
                                  ''
                              }
                              </div>
                              <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                (password_ok)
                                  ?
                                  <div className="badge badge-success"><span style={{ "fontSize": "16px" }}></span>Strength : Strong</div> :
                                  ''
                              }
                              </div>
                            </div>
                            <div className="form-group">
                              <p>Repeat Password</p>
                              <div className="input-group">
                                <div className="input-group-prepend bg-transparent">
                                  <span className="input-group-text bg-transparent border-right-0">
                                    <i className="mdi mdi-lock-outline text-primary"></i>
                                  </span>
                                </div>
                                <input type="password"
                                  className="form-control form-control-md border-left-0"
                                  id="repeatInputPassword"
                                  placeholder="Repeat password"
                                  value={repeatPassword}
                                  onChange={(e) => setRepeatPassword(e.target.value)}
                                />
                              </div>
                              <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                (password_not_match)
                                  ?
                                  <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>Password do not match</span></div> :
                                  ''
                              }
                              </div>
                            </div>

                            <button
                              onClick={_ChangePassword}
                              value="hello world"
                              className="btn btn-block btn-primary btn-md font-weight-medium auth-form-btn"
                            >
                              Change Password
                            </button>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h5>-</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title">Subscriptions settings</div>
                  <div className="card-body">
                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>


                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title" id="payment">Payment settings</div>
                  <div className="card-body">
                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default SettingsPage;