import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Logout } from '../actions/auth';




const Header = (props) => {

  const user_state = useSelector(state => state.auth);
  const { currentUser } = user_state

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState('')
  useEffect(() => {
    document.body.classList.toggle('sidebar-icon-only', isOpen);
  }, [isOpen])

  //logout function
  function logout() {
    dispatch(Logout());
  }
  // navigate or redirect to the settings page 
  let navigate = useNavigate();
  function handleOnClickSettings() {
    return navigate("/settings");
  }
  return (

    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex justify-content-center">
        <div className="navbar-brand-inner-wrapper9 d-flex justify-content-between8 align-items-center8">
          <Link className="navbar-brand brand-logo1" to="/"></Link>
          
          <Link className="navbar-brand brand-logo-mini" to="/" style={{"marginLeft":"-70px","marginTop":"15px"}}>
            <img src="../../images/logo-small.png" alt="logo" width="160px"/>
          </Link>
        
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">

        <ul className="navbar-nav navbar-nav-right">

          {/*<li className="nav-item dropdown mr-4">
            <a className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center notification-dropdown" id="notificationDropdown" href="#" data-toggle="dropdown">
              <i className="mdi mdi-bell mx-0"></i>
              <span className="count"></span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="notificationDropdown">
              <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>
              <a className="dropdown-item">
                <div className="item-thumbnail">
                  <div className="item-icon bg-success">
                    <i className="mdi mdi-information mx-0"></i>
                  </div>
                </div>
                <div className="item-content">
                  <h6 className="font-weight-normal">Application Error</h6>
                  <p className="font-weight-light small-text mb-0 text-muted">
                    Just now
                  </p>
                </div>
              </a>
              <a className="dropdown-item">
                <div className="item-thumbnail">
                  <div className="item-icon bg-warning">
                    <i className="mdi mdi-settings mx-0"></i>
                  </div>
                </div>
                <div className="item-content">
                  <h6 className="font-weight-normal">Settings</h6>
                  <p className="font-weight-light small-text mb-0 text-muted">
                    Private message
                  </p>
                </div>
              </a>
              <a className="dropdown-item">
                <div className="item-thumbnail">
                  <div className="item-icon bg-info">
                    <i className="mdi mdi-account-box mx-0"></i>
                  </div>
                </div>
                <div className="item-content">
                  <h6 className="font-weight-normal">New user registration</h6>
                  <p className="font-weight-light small-text mb-0 text-muted">
                    2 days ago
                  </p>
                </div>
              </a>
            </div>
          </li>*/}
          <li className="nav-item nav-profile dropdown">
            <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              {/*<img src="images/faces/face5.jpg" alt="profile" />*/}
              <span className="nav-profile-name" style={{"fontSize":"17px"}}>{currentUser.company}</span><span className='dashboardplan'>{props.plan} plan</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <div className="dropdown-item" onClick={handleOnClickSettings}>
                <i className="mdi mdi-settings text-primary"></i>
                Settings
              </div>
              <div className="dropdown-item" onClick={logout} >
                <i className="mdi mdi-logout text-primary"></i>
                Logout
              </div>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  )
}

export default Header;