import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { GetPhrases } from '../actions/search';

const PhrasePage = () => {

    const phrases = useSelector(state => state.search_rank_list.phrases);
    const user_state = useSelector(state => state.auth);
    const { plan } = user_state.currentUser;

    return (
        <>
            <Header plan={plan} />
            <div className="container-fluid page-body-wrapper">
                <Sidebar plan={plan} />
                <div className="main-panel">
                    <div className="content-wrapper">

                        <div className="row">
                            <div className="col-md-12 stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <p className="card-title">Phrase</p>
                                        <div className="boxinside">
                                            <p><a Link to="/searchranking">Back</a></p>
                                        </div>
                                        <div>
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Search Phrases</th>
                                                        <th>Url</th>
                                                        <th>Search location</th>
                                                        <th>Search engine</th>
                                                        <th>Page</th>
                                                        <th>Position</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {phrases.map(item => {
                                                        let i = 0;
                                                        return (
                                                            <tr key={item._id}>
                                                                <td>{item.phrase}</td>
                                                                <td>{item.website}</td>
                                                                <td>{item.location}</td>
                                                                <td>{item.engine}</td>
                                                                <td>{item.page}</td>
                                                                <td>{item.position}</td>
                                                                <td>{item.captured}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* main-panel ends */}
            </div>{/* page-body-wrapper ends */}
        </>
    )
}

export default PhrasePage;