import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { GenerateClientTrackingtCode, GetClientTrackingCode, IsTracking, Visualize } from '../actions/heatmaps';
import { HeatmapOverlay } from './children/heatmap/heatmap.js';

const HeatMapsPage = () => {

    const dispatch = useDispatch();
    const user_state = useSelector(state => state.auth);
    const { plan } = user_state.currentUser;
    //check if there was any system error
    const SYSTEM_ERROR = useSelector(state => state.system_errors.system_error);
    const heatMapTrackingInfo = useSelector(state => state.heatmap.client_tracking_info);
    const showVisualize = useSelector(state => state.heatmap.show_visualize);
    const is_tracking = useSelector(state => state.heatmap.is_tracking);

    function visualizeData() {

        if (heatMapTrackingInfo.hasOwnProperty('trackingCode') && heatMapTrackingInfo.hasOwnProperty('pageUrl')) {
            console.log("Object has both 'name' and 'surname' properties.");
            dispatch(Visualize(heatMapTrackingInfo));
        } 
    }

    function _show_visualization() {
        if(showVisualize) {
            return (
                <div>
                    <h1>Heatmap Visualization</h1>
                    <HeatmapOverlay clientId={heatMapTrackingInfo.trackingCode} pageUrl={heatMapTrackingInfo.pageUrl} />
                </div>
            )
        } else {
            return <></>
        }
    }

    function _generateClientCode() {
        dispatch(GenerateClientTrackingtCode({}));
    }

    function StaticHtmlComponent() {
        const htmlContent = `
        <pre>
(function () {
    const clientId = "${heatMapTrackingInfo.trackingCode}"; // Your tracking code

    // Function to send interaction data to the server
    function sendInteraction(eventType, element, coordinates, additionalData = {}) {
        const pageUrl = window.location.href; // Get the current page URL

        fetch('https://app.proittools.com/api/heatmap/track', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ clientId, eventType, element, pageUrl, coordinates, additionalData }),
        })
        .then(response => response.json())
        .then(data => {
            console.log('Interaction successfully sent:', data);
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
    }

    // Track clicks
    document.addEventListener('click', function (event) {
        const element = event.target.tagName.toLowerCase();
        const rect = event.target.getBoundingClientRect();
        const coordinates = { x: rect.left + window.scrollX, y: rect.top + window.scrollY };
        const additionalData = { id: event.target.id, classes: event.target.className };
        sendInteraction('click', element, coordinates, additionalData);
    });

    // Track mouse movements with throttling
    let lastMoveTime = 0;
    document.addEventListener('mousemove', function (event) {
        const now = Date.now();
        if (now - lastMoveTime > 100) { // Throttle to send data every 100ms
            lastMoveTime = now;
            const coordinates = { x: event.pageX, y: event.pageY };
            sendInteraction('mousemove', 'mousemove', coordinates);
        }
    });
})();
        </pre>`;



        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }


    useEffect(() => {
        dispatch(GetClientTrackingCode());
        dispatch(IsTracking());
    }, []);

    return (
        <>
            <Header plan={plan} />
            <div className="container-fluid page-body-wrapper">
                <Sidebar plan={plan} />
                <div className="main-panel">
                    <div className="content-wrapper">

                        <div className="row">
                            <div className="col-md-12 stretch-card">
                                <div className="card">
                                    <div className="card-title">Heat Maps</div>
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            {
                                                (SYSTEM_ERROR)
                                                    ?
                                                    <div
                                                        style={{
                                                            "fontSize": "50%", "border": "1px solid red", "padding": "5px",
                                                            "backgroundColor": "red", "color": "#fff", "borderRadius": "5px",
                                                            "marginBottom": "10px"
                                                        }}>
                                                        System Error has occured, and system has already sent the error to support.
                                                        please refresh your browser and try again and see if the error persists.
                                                    </div>
                                                    :
                                                    ""
                                            }
                                        </div>

                                        <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>


                                            {(heatMapTrackingInfo.hasOwnProperty('trackingCode') && heatMapTrackingInfo.hasOwnProperty('pageUrl'))

                                                ?
                                                <div className="col-md-12">

                                                    {(is_tracking)
                                                        ?

                                                        <>
                                                            <div className="heatmapSuccessTracking" style={{ "padding": "10px", "color": "#fff" }}>The system is monitoring user interactions on your website</div>
                                                            <hr />
                                                            <button onClick={visualizeData}>Visualize</button>
                                                        </>
                                                        :

                                                        <>
                                                            <div className="heatmapErrorTracking" style={{ "padding": "10px", "color": "#fff" }}>
                                                                The system cannot detect the tracking code on your website : Copy the code below and paste it in your footer inside script tag just before closing body tage
                                                            </div>
                                                            {StaticHtmlComponent()}
                                                        </>

                                                    }
                                                </div>

                                                :

                                                <div className="boxinside">
                                                    <br />
                                                    <button type="button" onClick={_generateClientCode} className="btn btn-primary btn-fw">Generate code</button>
                                                </div>
                                            }



                                        </div>

                                        <div className="col-md-12">
                                            {_show_visualization()}
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>{/* main-panel ends */}
            </div>{/* page-body-wrapper ends */}
        </>
    )
}

export default HeatMapsPage;