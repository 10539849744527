import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import { AddUrl, GetBrokenLinks, UpdateBrokenLink, GetLinkByStatus, ScanBrokenLinks } from "../actions/links";

const LinksPage = () => {

  const [webUrl, setUrl] = useState("");
  const user_state = useSelector(state => state.auth);
  const links = useSelector(state => state.links_list.links);
  //check if there was any system error
  const SYSTEM_ERROR = useSelector(state => state.system_errors.system_error);
  const { currentUser } = user_state;
  const { plan } = currentUser;



  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(GetBrokenLinks());
  }, []);

  function addUrl() {
    if (webUrl !== "") {
      dispatch(AddUrl(webUrl));
    }
  }

  function scanLinks() {
    dispatch(ScanBrokenLinks());
  }

  function updateLinkStatus(e, status) {
    console.log(e.target.value);
    dispatch(UpdateBrokenLink(e.target.value, status))

  }

  function getLinkByStatus(e, status) {
    //console.log(e.target.value);
    //console.log(status);
    dispatch(GetLinkByStatus(e.target.value, status));
  }



  return (
    <>
      <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <p className="card-title">Broken Links</p>
                  <div className="card-body">
                    {
                      (SYSTEM_ERROR)
                        ?
                        <div
                          style={{
                            "fontSize": "50%", "border": "1px solid red", "padding": "5px",
                            "backgroundColor": "red", "color": "#fff", "borderRadius": "5px",
                            "marginBottom": "10px"
                          }}>
                          System Error has occured, and system has already sent the error to support.
                          please refresh your browser and try again and see if the error persists.
                        </div>
                        :
                        ""
                    }
                    {(currentUser.websiteUrl !== "")
                      ?
                      <div>
                        <h5> Website url :  <span className="card-description">{currentUser.websiteUrl}</span> </h5>
                        <p onClick={scanLinks} style={{ "marginTop": "30px" }}><button className="btn btn-primary">Scan</button></p>
                      </div>
                      :
                      <div className="table-responsive">
                        <div className="form-group">
                          <p>Enter url</p>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <i className="mdi mdi-account-outline text-primary"></i>
                              </span>
                            </div>
                            <input
                              value={webUrl}
                              onChange={(e) => { setUrl(e.target.value) }}
                              type="text"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Add website"
                            />
                            <button onClick={addUrl} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">Add</button>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>

              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">

                    <p className="card-description">

                      {/*<label className="badge badge-info" style={{ "marginRight" : "10px"}}>All</label>*/}
                      <button value={[currentUser.email]} onClick={(e) => { getLinkByStatus(e, "all") }} className="btn btn-inverse-warning btn-fw" style={{ "marginRight": "10px" }}>All</button>
                      <button value={[currentUser.email]} onClick={(e) => { getLinkByStatus(e, true) }} className="btn btn-inverse-success btn-fw" style={{ "marginRight": "10px" }}>Fixed</button>
                      <button value={[currentUser.email]} onClick={(e) => { getLinkByStatus(e, false) }} className="btn btn-inverse-danger btn-fw">Pending</button>
                    </p>
                    <div>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th >broken links</th>
                            <th>Source</th>
                            <th>Code </th>
                            {/*<th>Date detected</th>*/}
                            <td>Status</td>
                          </tr>
                        </thead>
                        <tbody>

                          {links.map(item => {
                            return (
                              <tr key={item._id}>
                                <td> {(item.status === true) ? <span><a href={item.brokenlink} target="_blank" style={{}}>{item.brokenlink}</a></span> : <span><a href={item.brokenlink} target="_blank" style={{}}>{item.brokenlink}</a></span>}</td>
                                <td> {(item.status === true) ? <span><a style={{}}>{item.source}</a></span> : <span style={{ "width": "15px" }}><a>{item.source}</a></span>}</td>
                                <td> {(item.status === true) ? <span style={{}}>{item.httpcode}</span> : <span style={{}}>{item.httpcode}</span>}</td>
                                {/*<td> {(item.status === true) ? <span style={{}}>{item.captured}</span> : <span style={{}}>{item.captured}</span>}</td>*/}
                                <td> {(item.status === true) ? <span style={{ "color": "green" }} ><button className="btn btn-outline-success btn-fw" value={[item._id]} onClick={(e) => { updateLinkStatus(e, true) }}>fixed</button></span> : <span style={{ "color": "red" }}><button className="btn btn-outline-danger btn-fw" value={[item._id]} onClick={(e) => { updateLinkStatus(e, false) }}>pending</button></span>}</td>
                              </tr>
                            )
                          })}

                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>




            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default LinksPage;