import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import SidebarGettingStarted from '../components/sidebar2';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { Update } from '../actions/users';




const GetStartedPage = (props) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [webUrl, setWebUrl] = useState("");
    const [notificationNumber, setNotificationNumber] = useState("");

    const user_state = useSelector(state => state.auth);
    //console.log(user_state.currentUser.websiteUrl)
    const { plan, websiteUrl } =  user_state.currentUser;
    

    useEffect(() => {
        if (user_state.currentUser.websiteUrl !== ""){
            // redirect
            console.log("redirecting....")
            return navigate("/");
        }
    },[]);

    function _submitFn() {
        if (webUrl !== "" && notificationNumber !== "") {
            dispatch(Update({
                weburl: webUrl,
                notification_num: notificationNumber
            }));
        }else{
            alert("Website or phone number is missing");
        }
    }
    return (
        <>
            <Header plan={plan} />
            <div className="container-fluid page-body-wrapper">
                <SidebarGettingStarted setup={props} />
                <div className="main-panel">
                    <div className="content-wrapper">

                        <div className="row">
                            <div className="col-md-12 stretch-card">
                                <div className="card">
                                    <p className="card-title">Getting started</p>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <p>Add website url to be monitored</p>
                                            <div className="boxinside">
                                                <input
                                                    type="input"
                                                    value={webUrl}
                                                    onChange={(e) => { setWebUrl(e.target.value) }}
                                                    className="form-control form-control-md"
                                                    placeholder="e.g www.example.com"
                                                    required
                                                />
                                            </div>
                                            {(user_state.getting_started_checking === true)

                                                ?
                                                <div className="badge badge-info ml-2">
                                                    <span style={{ "fontSize": "16px" }}>
                                                        Please wait while we check for your website.....
                                                    </span>
                                                </div>
                                                :
                                                ""
                                            }

                                            {(user_state.getting_started_url_invalid === true)

                                                ?
                                                <div className="badge badge-danger ml-2">
                                                    <span style={{ "fontSize": "16px" }}>
                                                        The url provided is invalid
                                                    </span>
                                                </div>
                                                :
                                                ""
                                            }

                                        </div>
                                    </div>


                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <p>SMS/Whatsapp number (for system notifications, include country code)</p>
                                            <div className="boxinside">
                                                <input
                                                    type="input"
                                                    className="form-control form-control-md"
                                                    value={notificationNumber}
                                                    onChange={(e) => { setNotificationNumber(e.target.value) }}
                                                />
                                            </div>
                                        </div>
                                        <button
                                            style={{ "marginTop": "20px" }}
                                            onClick={_submitFn}
                                            className="btn btn-primary btn-md">Add</button>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>{/* main-panel ends */}
            </div > {/* page-body-wrapper ends */}
        </>
    )
}

export default GetStartedPage;