import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Logout } from '../actions/auth';




const HeaderEmpty = (props) => {

  return (
    <></> 
  )
}

export default HeaderEmpty;