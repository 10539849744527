import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import h337 from 'heatmap.js';

export const HeatmapOverlay = ({ clientId, pageUrl }) => {
    const heatmapData = useSelector(state => state.heatmap.heatmapData);

    useEffect(() => {
        if (heatmapData && heatmapData.length > 0) {
            const iframe = document.getElementById('client-website');
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

            const heatmapInstance = h337.create({
                container: iframeDocument.body,
                radius: 50, // Customize the heatmap radius
                maxOpacity: 0.6, // Customize the max opacity
            });

            heatmapInstance.setData({
                max: Math.max(...heatmapData.map(d => d.value)),
                data: heatmapData
            });
        }
    }, [heatmapData]);

    return (
        <iframe
            id="client-website"
            src={pageUrl}
            style={{ width: '100%', height: '100vh', border: 'none', position: 'relative' }}
        ></iframe>
    );
};
