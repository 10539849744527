import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';
import { GetUpTime, GetUpTIMEByStatus } from '../actions/uptime';

const UptimePage = () => {


  const [status, setStatus] = useState('all');
  const [startDate, setStartDate] = useState(new Date());

  const dispatch = useDispatch();
  const uptimes = useSelector(state => state.uptime_list.uptimes);

  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;

 

  function getUpTimesByStatus(e, status) {
    //dispatch(GetUpTime());
    console.log(e.target.value, status)
  }

  function generateReport() {

    dispatch(GetUpTIMEByStatus(status, startDate));
  }

  useEffect(() => {
    dispatch(GetUpTime());
  }, []);


  return (
    <>
       <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan} />
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                <p className="card-title">Uptime</p>
                  <div className="card-body">
                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>
                      <div className="boxinside">
                        <span style={{ "fontSize": "14px" }}>Status</span>
                        <select value={status} onChange={(e) => setStatus(e.target.value)} className="form-control form-control-sm" id="exampleFormControlSelect3">
                          <option value="all">Select status</option>
                          <option>all</option>
                          <option>UP</option>
                          <option>DOWN</option>
                        </select>
                      </div>
                      <div className="boxinside" style={{"marginLeft" : "10px", "marginRight":"10px"}}>
                        <span style={{ "fontSize": "14px" }} >Date</span><br />
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <div className="boxinside">
                        <br />
                        <button type="button" onClick={generateReport} className="btn btn-inverse-primary btn-fw">Search</button>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uptimes.map(item => {
                            return (
                              <tr key={item._id}>
                                <td>{(item.status === "UP") ? <span style={{ "color": "green" }}>Up</span> : <span style={{ "color": "red" }}>Down</span>} </td>
                                <td>{item.captured}</td>
                                <td>{item.time}:00</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                 

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default UptimePage;