export default (state = { seach_phrases: [], port: [], phrases: [], rank_data: [] }, action) => {

    if (action.type === "SEARCH_PHRASES") {
        return {
            ...state,
            seach_phrases: action.payload
        }
    }
    else if (action.type === "PHRASES") {
        return {
            ...state,
            phrases: action.payload
        }
    }
    else if (action.type === "SEARCH_RANK_CHART") {
        return {
            ...state,
            rank_data: action.payload
        }
    }
    else {
        return state;
    }

}