import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import HeaderEmpty from "../components/header-empty";
import { useNavigate } from 'react-router-dom';
import { EmailConfirmationError, doesEmailExist, ItsPasswordResetMessage } from "../actions/auth";
// registration 2 
const ForgotPassword = () => {

    let navigate = useNavigate();

    //instatiate useDispatch hook
    const dispatch = useDispatch();
    const registration_step_one_data = useSelector(state => state.auth.registration_step_one);
    const confirmation_email_match = useSelector(state => state.auth.confirmation_email_match);
    // Set state
    const [email, setEmail] = useState("");
    const user_state = useSelector(state => state.auth);
    const { email_confirmation_error, email_exists } = user_state;
    var errorFlag = [];

    // Handle on click register button
    function _Reset() {

        if (email === "") {
            //dispatch(EmailResetPasswordError("Email cannot be empty"));
            errorFlag.push("email forgot password empty");
        } else {
            // check if email exists
            dispatch(doesEmailExist(email));
        }


        /*if (confirmation_email_match === "wrong") {
            console.log("wrong code provided.............................")
            dispatch(EmailConfirmationError("Wrong code provided"));
            errorFlag.push("email confirmation error");
        }*/
        if (email_exists === false) {
            //dispatch(EmailforgotPasswordError("Wrong password provided"));
            errorFlag.push("email confirmation error");
        }
        if (errorFlag.length === 0) {
            if (email_exists === true) {
                //ItsPasswordResetMessage will be used in login screen to 
                //indicate it's login after reset 
                //dispatch(ItsPasswordResetMessage());
                return navigate("/login");
            } else {
                //console.log("email does not exist")
            }
        }

    }
    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg" style={{ "backgroundColor": "#37517e", "paddingLeft": "100px" }}>
                        <div className="row flex-grow">
                            <div className="col-lg-9 d-flex align-items-center justify-content-left">
                                <div className="auth-form-transparent text-left p-3">
                                    <div className="brand-logo2" style={{ "marginTop": "10px", "marginBottom": "40px", "paddingLeft": "40px" }}>
                                        <img src="../../images/logo-small.png" width="250px" alt="logo" style={{}} /><br />
                                    </div>
                                    <div style={{ "backgroundColor": "#fff", "padding": "30px 40px", "borderRadius": "3px", "width": "90%" }}>
                                        <h4>Forgot Password</h4>
                                        <p>Please enter the email address which was used to register your account.
                                            After you click Reset button the new password will send to the same email.You can change the new password after you login.
                                        </p>
                                        <br />

                                        <input
                                            type="text"
                                            required
                                            className="form-control form-control-lg border-left-2"
                                            placeholder="Enter your email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />

                                        <br />
                                        <button
                                            onClick={_Reset}
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                        >
                                            RESET PASSWORD
                                        </button>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;