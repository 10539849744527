export default (state = { errors: [], expiry: "", days_left: "" }, action) => {
    if (action.type === "ERRORS") {
        return {
            ...state,
            errors: action.payload
        }
    }
    else if (action.type === "SSL_EXPIRY_DATE") {
        return {
            ...state,
            expiry: action.payload
        }
    }
    else if (action.type === "DAYS_LEFT") {
        return {
            ...state,
            days_left: action.payload
        }
    }
    else if (action.type === "ERRORS_COUNT"){
        return {
            ...state,
            ssl_error_count : action.payload
        }
    }
    else {
        return state;
    }
}