export default (state = { loading : false, paymentsucess : false, paymentSuccess: false }, action) => {
    
    if (action.type === "LOADING"){ 
        return{
            ...state,
            loading : action.payload
        }
    }
    if (action.type === "PAYMENT_SUCCESS"){
        return {
            ...state,
            paymentsucess : action.payload,
            loading : false,
            paymentSuccess : true
        }
    }
    if (action.type === "PAYMENT_FALSE"){
        return {
            ...state,
            paymentSuccess : false
        }
    }
    else {
        return state;
    }

}