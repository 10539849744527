import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ResponsivePie } from '@nivo/pie';
//import { Line, ResponsiveLine } from '@nivo/line'
//import { area, curveMonotoneX } from 'd3-shape'
//import { generateDrinkStats } from '@nivo/generators'
import { ResponsiveBump } from '@nivo/bump'
import { ResponsiveRadialBar } from '@nivo/radial-bar'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Tooltip } from 'primereact/tooltip';
import 'react-tabs/style/react-tabs.css';
import { LinksChart } from '../actions/links';
import { UpTimeChart, TheStatus } from '../actions/uptime';
import { SearchRankChart } from '../actions/search';
import { GetPorts } from '../actions/ports';
import { GetSSLDaysLeft, GET_SSL_ERRORS_COUNT } from '../actions/ssl';
import { GetWebsiteHealthChart, GetAudits } from '../actions/sitehealth';
import PerformanceBar from './dashboradhelpers/performancebar';
import AccessibilityBar from './dashboradhelpers/accessibilitybar';
import BestPractises from './dashboradhelpers/bestpractisesbar';
import SeoBar from './dashboradhelpers/seobar';




const DashboardCompletePlan = (props) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LinksChart());
    dispatch(UpTimeChart());
    dispatch(TheStatus());
    dispatch(SearchRankChart());
    dispatch(GetPorts());
    dispatch(GetWebsiteHealthChart());
    dispatch(GetSSLDaysLeft());
    dispatch(GET_SSL_ERRORS_COUNT());
  }, [SearchRankChart]);

  // useEffect(() => {
  //dispatch(GetSSLDaysLeft());
  // dispatch(GET_SSL_ERRORS_COUNT());
  // }, []);

  const linksChartData = useSelector(state => state.links_list.links_chart_data);
  const uptimeChartData = useSelector(state => state.uptime_list.uptime_chart_data);
  const uptime_status = useSelector(state => state.uptime_list.uptime_status);
  const site_health_data = useSelector(state => state.sitehealth.site_health_data);

  const rank_data = useSelector(state => state.search_rank_list.rank_data);
  const ports = useSelector(state => state.ports_list.ports);
  const user_state = useSelector(state => state.auth);
  const SSL = useSelector(state => state.ssl_errors_list.expiry);
  const DAYS_LEFT = useSelector(state => state.ssl_errors_list.days_left);
  const SSL_ERRORS = useSelector(state => state.ssl_errors_list.ssl_error_count);
  const { currentUser } = user_state;


  function upChart() {
    return (
      uptimeChartData.slice(0, 13).map(item => {
        return (
          <div
            key={item._id}
            style={{ "display": "inline" }}
            className={
              (item.status === "UP") ? "circleUp boxinside" : "circleDown boxinside"
            }>
            <span
              style={{ "marginTop": "35px", "position": "absolute", "fontSize": "9px", "marginLeft": "1px" }}>
              {new Date(item.createdAt).getHours()}:00
            </span>
          </div>
        )
      })
    )
  }

  function StatusText() {

    if (uptime_status.length > 0) {
      return (
        <>

          <div
            className={(uptime_status[0].status === "UP") ? "circleBlue" : "circleRed"}
          >
          </div>
          <p style={{ "textAlign": "center", "marginBottom": "30px", "marginTop": "40px" }}>
            STATUS : {(uptime_status[0].status === "UP") ? "UP" : "DOWN"}
          </p>
        </>
      )

    } else {
      return (
        <>
          <p style={{ "textAlign": "center", "marginBottom": "20px", "marginTop": "0px" }}>Down</p>
          <div className="circleRed"></div>
        </>
      )
    }
  }

  function getBrokenLinkPieChart() {
    return (
      <div style={{ height: '300px' }}>
        <div style={{ height: '100%', display: 'flex' }}>

          <ResponsivePie
            data={
              [
                {
                  "id": "Pending",
                  "label": "Pending links",
                  "value": linksChartData[1],
                  "color": "hsl(233, 70%, 50%)"
                },
                {
                  "id": "Fixed",
                  "label": "Fixed broken links",
                  "value": linksChartData[0],
                  "color": "hsl(153, 70%, 50%)"
                },


              ]
            }
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0}
            cornerRadius={0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  0.2
                ]
              ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [
                [
                  'darker',
                  2
                ]
              ]
            }}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              }
            ]}
            fill={[
              {
                match: {
                  id: 'ruby'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'c'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'go'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'python'
                },
                id: 'dots'
              },
              {
                match: {
                  id: 'scala'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'lisp'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'elixir'
                },
                id: 'lines'
              },
              {
                match: {
                  id: 'javascript'
                },
                id: 'lines'
              }
            ]}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
          />

        </div>
      </div>
    )
  }

  function viewPerfomanceAudits(e) {
    e.preventDefault();
    dispatch(GetAudits("performance"));
    navigate("/audits");
  }

  function viewAccessibilityAudits(e) {
    e.preventDefault();
    dispatch(GetAudits("accessibility"));
    navigate("/audits");
  }

  function viewSeoAudits(e) {
    e.preventDefault();
    dispatch(GetAudits("seo"));
    navigate("/audits");
  }

  function viewBestPractiseAudits(e) {
    e.preventDefault();
    dispatch(GetAudits("bestPractices"));
    navigate("/audits");
  }
  function getWebsiteHealh() {

    return (
      <div className="row">
        <div className="col-md-3">
          <a onClick={(e) => { viewPerfomanceAudits(e) }}>
            <PerformanceBar percentage={(site_health_data !== undefined) ? site_health_data.performance : 0} />
          </a>
          <h5 style={{ "marginLeft": "32%", "paddingTop": "10px", "paddingBottom": "10px", "color": "rgb(141 141 141)" }}>Performance</h5>
        </div>
        <div className="col-md-3">
          <a onClick={(e) => { viewAccessibilityAudits(e) }}>
            <AccessibilityBar percentage={(site_health_data !== undefined) ? site_health_data.accessibility : 0} />
          </a>
          <h5 style={{ "marginLeft": "32%", "paddingTop": "10px", "color": "rgb(141 141 141)" }}>Accessibility</h5>
        </div>
        <div className="col-md-3">
          <a onClick={(e) => { viewSeoAudits(e) }}>
            <SeoBar percentage={(site_health_data !== undefined) ? site_health_data.seo : 0} />
          </a>
          <h5 style={{ "marginLeft": "40%", "paddingTop": "10px", "paddingBottom": "10px", "color": "rgb(141 141 141)" }}>SEO</h5>
        </div>
        <div className="col-md-3">
          <a onClick={(e) => { viewBestPractiseAudits(e) }}>
            <BestPractises percentage={(site_health_data !== undefined) ? site_health_data.bestPractices : 0} />
          </a>
          <h5 style={{ "marginLeft": "32%", "paddingTop": "10px", "paddingBottom": "10px", "color": "rgb(141 141 141)" }}>Best Practises</h5>
        </div>
      </div>
    );
  }
  function getBumpGraph() {
    return (
      <div style={{ height: '300px' }}>
        <div style={{ height: '100%', display: 'flex' }}>

          <ResponsiveBump
            data={rank_data}
            /*data={[
              {
                "id": "Lesoth revenue authority",
                "data": [
                  {
                    "x": "Jan",
                    "y": 10
                  },
                  {
                    "x": "Feb",
                    "y": 10
                  },
                  {
                    "x": "Mar",
                    "y": 1
                  },
                  {
                    "x": "Apr",
                    "y": 6
                  },
                  {
                    "x": "May",
                    "y": 6
                  }
                ]
              },
              {
                "id": "File tax clearance",
                "data": [
                  {
                    "x": "Jan",
                    "y": 11
                  },
                  {
                    "x": "Feb",
                    "y": 12
                  },
                  {
                    "x": "Mar",
                    "y": 6
                  },
                  {
                    "x": "Apr",
                    "y": 7
                  },
                  {
                    "x": "May",
                    "y": 4
                  }
                ]
              },
  
              {
                "id": "Kingdom of Lesotho",
                "data": [
                  {
                    "x": "Jan",
                    "y": 3
                  },
                  {
                    "x": "Feb",
                    "y": 7
                  },
                  {
                    "x": "Mar",
                    "y": 7
                  },
                  {
                    "x": "Apr",
                    "y": 3
                  },
                  {
                    "x": "May",
                    "y": 2
                  }
                ]
              },
              {
                "id": "Import and export procedures",
                "data": [
                  {
                    "x": "Jan",
                    "y": 7
                  },
                  {
                    "x": "Feb",
                    "y": 1
                  },
                  {
                    "x": "Mar",
                    "y": 3
                  },
                  {
                    "x": "Apr",
                    "y": 10
                  },
                  {
                    "x": "May",
                    "y": 5
                  }
                ]
              },
  
  
            ]}*/
            colors={{ scheme: 'spectral' }}
            lineWidth={3}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            pointSize={10}
            activePointSize={16}
            inactivePointSize={0}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            activePointBorderWidth={3}
            pointBorderColor={{ from: 'serie.color' }}
            axisTop={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -36
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'ranking',
              legendPosition: 'middle',
              legendOffset: -40
            }}
            margin={{ top: 5, right: 100, bottom: 40, left: 60 }}
            axisRight={null}
          />
        </div>
      </div>
    )
  }





  function getSSLInfo() {

    if (SSL === 'none') {
      return (
        <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>Retreiving SSL infomation...</h3>
      )
    }
    else if (SSL === "ssl is not installed") {
      return <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>SSL NOT Detected</h3>
    }
    else {
      return (
        <div className="card-body row">
          <div className='col-md-7'>
            <p className='certificateExpiry'>Certificate  {(DAYS_LEFT > 0) ? " expires in" : ""} </p>
            <div className='expiryText'>
              <p>{(DAYS_LEFT > 0) ? <span style={{ "marginLeft": "60px", "paddingTop": "10px" }}>{`${DAYS_LEFT} days`}</span> : <span style={{ 'color': 'red' }}>Expired</span>}</p>
              <div className={(DAYS_LEFT > 0) ? "certificate_expires_underline" : "underline2"}></div>
            </div>
          </div>
          <div className='col-md-5'>
            <p className='certificateErrors'> {(SSL_ERRORS === 0) ? <span style={{ "marginLeft": "5px" }}>No errors</span> : 'Errors found'}</p>
            <div className='ssl-error'>
              {(SSL_ERRORS === 0) ? <i className='mdi mdi-check-circle text-success ml-4'></i> : <span style={{ "color": "red", "marginLeft": "35px" }}> {SSL_ERRORS} </span>}
              <div className={(SSL_ERRORS > 0) ? "underline2" : "certificate_expires_no_errors"}></div>
            </div>
          </div>
          {/*<p className='issued'>Certificate issued by <strong>Security Pack</strong> on <strong>12/23/2021</strong></p>*/}
        </div>
      );
    }
  }

  const ssl_errors = 0;
  const ssl_days = 500;

  return (
    <>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Up Time</p>
            <div className="card-body">

              {

                (props.status === "active") ?

                  <>
                    {StatusText()}
                    < hr style={{ "marginTop": "5px" }} />
                    <div className='wrapperLine'>
                      {upChart()}
                    </div>
                  </>
                  :
                  <di>
                    <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                      <span>Uptime service is not running</span>
                      <i
                        style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                        className="mdi mdi-alert-circle text-warning">
                      </i>
                    </p>

                    <p
                      style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                    >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                  </di>

              }


            </div>
          </div>
        </div>

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Broken links</p>
            <div className="card-body">
              {(props.status === "active") ?

                <>
                  {getBrokenLinkPieChart()}
                </>
                :
                <di>
                  <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                    <span>Broken links service is not running</span>
                    <i
                      style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                      className="mdi mdi-alert-circle text-warning">
                    </i>
                  </p>

                  <p
                    style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                  >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                </di>

              }
            </div>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Search keyword Ranking</p>
            <div className="card-body">
              <div id="cash-deposits-chart-legend" className="d-flex justify-content-center pt-3"></div>

              {(props.status === "active") ?

                <>
                  {getBumpGraph()}
                </>
                :
                <di>
                  <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                    <span>Search keyword service is not running</span>
                    <i
                      style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                      className="mdi mdi-alert-circle text-warning">
                    </i>
                  </p>

                  <p
                    style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                  >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                </di>

              }
            </div>
          </div>
        </div>

      </div>

      <div className="row">

        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Website Health</p>
            <div className="card-body">
              <div id="cash-deposits-chart-legend" className="d-flex justify-content-center pt-3"></div>

              {(props.status === "active") ?

                <>
                  {getWebsiteHealh()}
                </>
                :
                <di>
                  <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                    <span>Search keyword service is not running</span>
                    <i
                      style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                      className="mdi mdi-alert-circle text-warning">
                    </i>
                  </p>

                  <p
                    style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                  >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                </di>

              }
            </div>
          </div>
        </div>

      </div>


      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Ports</p>
            <div className="card-body">
              {

                (props.status === "active") ?

                  <>
                    <div className="col-md-12">
                      <div className="row">

                        {ports.map(item => {
                          return (
                            <div className='wrapperLine col-md-6' key={item._id}>
                              <div style={{ "borderBottom": "1px solid #fff", "marginBottom": "10px" }}>
                                <p
                                  className={(item.status === "true") ? "statusUp" : "statusDown"}
                                  style={{
                                    "fontSize": "14px",
                                    "padding": "25px",
                                    "paddingLeft": "15px",
                                  }}>
                                 <span style={{"color":"#fff"}}> <i className="mdi mdi-server mr-1 icon-md"></i></span> {item.portName} : {item.portNumber}
                                </p>
                              </div>
                            </div>
                          )
                        })}

                        <div className='col-md-12'></div>
                        <div className='row' style={{ "marginTop": "50px", "borderTop": "1px solid #f4f4f4", "paddingTop": "20px" }}>
                          <hr />
                          <div className='MyrecTGreen'></div><span style={{ "fontSize": "12px", "marginRight": "10px", "marginTop": "2px", "fontWeight": "100" }}>Service up</span>
                          <div className='MyrecTRed'></div><span style={{ "fontSize": "12px", "marginRight": "10px", "marginTop": "2px", "fontWeight": "100" }}>Service down</span>
                        </div>
                      </div>
                    </div>
                  </>

                  :
                  <di>
                    <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                      <span>Ports service is not running</span>
                      <i
                        style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                        className="mdi mdi-alert-circle text-warning">
                      </i>
                    </p>

                    <p
                      style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                    >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                  </di>


              }


            </div>
          </div>
        </div>

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Secure Sockets Layer (SSL)</p>

            {(props.status === "active") ?

              <>
                {getSSLInfo()}
              </>
              :
              <di>
                <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                  <span>SSL service is not running</span>
                  <i
                    style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                    className="mdi mdi-alert-circle text-warning">
                  </i>
                </p>

                <p
                  style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
              </di>

            }
          </div>
        </div>
      </div>
    </>
  )
}
export default DashboardCompletePlan;