import axios from 'axios';


async function reportError(action, error) {
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if (responseMessage === "error logged successfully") {
        //console.log("error repotred successfully");
    }
}

export const AddUrl = (webUrl) => async (dispatch) => {
    try {
        const response = await axios.post('api/account/updateAccount', { url: webUrl }, {withCredentials: true});
        const responseMessage = response.data.message;
        if(responseMessage === "error occured"){
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 9000)
        }else{
            if(responseMessage === "url added successfull") {
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: response.data
                });
            }
        }
    }
    catch (err) {
        reportError("AddUrl", err);
    }

    return (dispatch) => {
        // dispatch({
        //   type: 'CLEAR_LOGIN_UI_MESSAGES',
        // payload: false
        //})
    }

}

export const GetBrokenLinks = () => async (dispatch) => {
    try {
        const response = await axios.post('api/links/getlinks', {withCredentials: true});
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 90000)
        } else {
            // here 
            if (responseMessage === "no links found") {
                dispatch({
                    type: 'LINKS',
                    payload: []
                });
                // for dashboard chart
                dispatch({
                    type: 'LINKS_CHART',
                    payload: []
                });
            } else {
                dispatch({
                    type: 'LINKS',
                    payload: response.data
                });
                // for dashboard chart
                dispatch({
                    type: 'LINKS_CHART',
                    payload: response.data
                });
            }
            // Request every hour
            async function Request() {
                const response = await axios.post('api/links/getlinks', {withCredentials: true});
                const responseMessage = response.data.message;

                if (responseMessage === "no links found") {
                    dispatch({
                        type: 'LINKS',
                        payload: []
                    });
                } else {
                    dispatch({
                        type: 'LINKS',
                        payload: response.data
                    });
                }
                // for the chart 
            }
            setInterval(Request, 50000);
        }

    }
    catch (err) {
        reportError("GetBrokenLinks", err);
    }
}

export const UpdateBrokenLink = (id, status) => async (dispatch) => {
    try {
        const response = await axios.post('api/links/updatelinks', { id, status }, {withCredentials: true});
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 9000)
        } else {
            dispatch({
                type: 'LINKS',
                payload: response.data
            });
        }
    }
    catch (err) {
        reportError("UpdateBrokenLink", err);
    }
}

export const GetLinkByStatus = (id, status) => async (dispatch) => {
   
    try {
        const response = await axios.post('api/links/getlinksbystatus', { id, status }, {withCredentials: true});
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            dispatch({
                type: 'LINKS',
                payload: []
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 9000)
        } else {
            if (responseMessage === "no links found") {
                dispatch({
                    type: 'LINKS',
                    payload: []
                });
            } else {
                dispatch({
                    type: 'LINKS',
                    payload: response.data
                });
            }
        }
    }
    catch (err) {
        reportError("GetLinkByStatus", err);
    }
}

export const LinksChart = () => async (dispatch) => {
    try {
        const response = await axios.post('api/links/linkchart', {withCredentials: true});
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 9000)
        } else {

            if (responseMessage === "no links found") {
                dispatch({
                    type: 'LINKS_CHART',
                    payload: []
                });
            } else {
                dispatch({
                    type: 'LINKS_CHART',
                    payload: response.data
                });
            }
            // Request every hour
            async function Request() {
                const response = await axios.post('api/links/linkchart', {withCredentials: true});
                const responseMessage = response.data.message;

                if (responseMessage === "no links found") {
                    dispatch({
                        type: 'LINKS_CHART',
                        payload: []
                    });
                } else {
                    dispatch({
                        type: 'LINKS_CHART',
                        payload: response.data
                    });
                }
            }
            setInterval(Request, 5000);
        }
    }
    catch (err) {
        reportError("LinksChart", err);
    }
}


export const ScanBrokenLinks = () => async (dispatch) => {
    try {
        const response = await axios.post('api/links/linkscan', {withCredentials: true});
        const responseMessage = response.data.message;
        if (responseMessage === "error occured") {
            dispatch({
                type: 'TECHNICAL_SYSTEM_ERROR',
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'TECHNICAL_SYSTEM_ERROR',
                    payload: false
                })
            }, 9000)
        }
        /*if(responseMessage === "no links found"){
            dispatch({
                type: 'LINKS_CHART',
                payload: []
            });
        }else{
            dispatch({
                type: 'LINKS_CHART',
                payload: response.data
            });
        }*/
    }
    catch (err) {
        reportError("ScanBrokenLinks", err);
    }
}