export default (state = { uptimes: [], uptime_chart_data : [], uptime_status:[]}, action) => {

    if (action.type === "UPTIMES") {
        return {
            ...state,
            uptimes: action.payload
        }
    }
    else if(action.type === "UPTIME_CHART"){
        return{
            ...state,
            uptime_chart_data : action.payload
        }
    }
    else if(action.type === "UPTIME_STATUS"){
        return{
            ...state,
            uptime_status : action.payload
        }
    }
    else {
        return state;
    }

}