import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Register, RegistrationStepOne } from "../actions/auth";
import HeaderEmpty from "../components/header-empty";
import { useNavigate } from 'react-router-dom';
// registration 2 
const PaymentPage = () => {

    let navigate = useNavigate();

    //instatiate useDispatch hook
    const dispatch = useDispatch();
    // Set state
    const [billing, setBilling] = useState("Billing");

    //  Get auth state and destructure 
    const user_state = useSelector(state => state.auth);
    const { registration_step_one } = user_state;

    // handle on change billing 
    function _handleOnChangeBilling(e) {
        setBilling(e.target.value);
        // add billing to the step one registration data
        registration_step_one["billing"] = e.target.value;
        dispatch(RegistrationStepOne(registration_step_one));
    }
    // Handle on click register button
    function _handleRegister() {
        alert("pay now....")
        dispatch(Register(registration_step_one));
        return navigate("/login");
    }
    return (
        <>
            <HeaderEmpty heading="Registration" />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg" style={{ "backgroundColor": "#37517e", "paddingLeft": "100px" }}>
                        <div className="row flex-grow">
                            <div className="col-lg-9 d-flex align-items-center justify-content-left">
                                <div className="auth-form-transparent text-left p-3">
                                    <div className="brand-logo2" style={{ "marginTop": "10px", "marginBottom": "40px", "paddingLeft": "40px" }}>
                                        <img src="../../images/logo-small.png" width="250px" alt="logo" style={{}} /><br />
                                    </div>
                                    <div style={{ "backgroundColor": "#fff", "padding": "30px 40px", "borderRadius": "3px", "width": "90%" }}>
                                        
                                        <h4>Payment step</h4>
                                        <h6 className="font-weight-lightb"></h6>
                                        <hr />
                                        <div className="form-group">
                                            <label>Your plan</label>
                                            <input type="text"
                                                className="form-control border-left-1"
                                                id="repeatInputPassword"
                                                value={registration_step_one.plan}
                                                disabled
                                            />
                                        </div>


                                        <div className="form-group">
                                            <label>Billed</label>
                                            <select className="form-control form-control-lg"
                                                id="exampleFormControlSelect2">
                                                <option>3 months</option>
                                                <option>6 months</option>
                                                <option>12 months</option>
                                            </select>
                                        </div>
                                        <p>Select Method Payment</p>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" />
                                                    Quick pay
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" />
                                                    Ozow payment
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" />
                                                    Smasung pay
                                                    <i className="input-helper"></i></label>
                                            </div>
                                            <div className="form-check">
                                                <label className="form-check-label">
                                                    <input type="radio" className="form-check-input" name="optionsRadios" id="optionsRadios1" value="option1" />
                                                    Quick Pay
                                                    <i className="input-helper"></i></label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="form-group">
                                            <div className="col-md-12 grid-margin stretch-card5">
                                                <h3 className="card-title">Total Due</h3>
                                                <h3>$ 28835</h3>
                                                <div id="total-sales-chart-legend"></div>
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <button
                                                onClick={_handleRegister}
                                                className="tn btn-block btn-primary btn-sm font-weight-medium auth-form-btn">Pay Now</button>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default PaymentPage;