import React, { useState, useEffect } from "react";
//import { useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from "../components/header";
import Sidebar from "../components/sidebar";
import Stastics from "../components/stasticsHeader";
import Main from "../components/main";
import GetStartedPage from './gettingstarted';
import DashboardCompletePlan from "../components/dashboardCompletePlan";
import DashboardAdvancedPlan from "../components/dashboardAdvancedPlan";
import DashboardBasicPlan from "../components/dashboardBasicPlan";
import DashboardFreePlan from "../components/dashboardFreePlan";
import { GetUpdatedCurrentUser } from "../actions/users";


export default function Dashboard() {


    const dispatch = useDispatch();

    // const location = useLocation();
    // const [queryParameters] = useSearchParams();
    const user_state = useSelector(state => state.auth);
    const { plan, websiteUrl, trialDaysleft, status, trial } = user_state.currentUser;



    // load main page for each plan
    function loadDashboardPlan() {
        if (plan === "free") {
            console.log("free plan loaded")
            return (
                <DashboardFreePlan status={status} />
            )
        }
        if (plan === "basic") {
            console.log("basic plan loaded")
            return (
                <DashboardBasicPlan status={status}/>
            )
        }
        if (plan === "advanced") {
            console.log("advanced plan loaded")
            return (
                <DashboardAdvancedPlan status={status}/>
            )
        }
        if (plan === "complete") {
            return (
                <DashboardCompletePlan status={status}/>
            )
        }
    }

    function isFirstUser() {
        let website = "";
        if (websiteUrl === "") {

            return (
                <GetStartedPage setup={website} />
            )

        } else {
            return (
                <>
                    <Header plan={plan} />
                    <div className="container-fluid page-body-wrapper">
                        <Sidebar plan={plan} />
                        <div className="main-panel">
                            <div className="content-wrapper">
                                {
                                    (trial===true && plan !== "free")
                                        ?
                                        (trialDaysleft === 0)
                                            ?
                                            <div style={{ "fontSize": "18px", "backgroundColor": "orange", "padding": "10px 15px 5px 10px", "color": "#f4f4f4", "marginBottom": "10px" }}>
                                                <p>Trial period has ended and for system to continue monitoring please <Link to="/payment">click here </Link>to upgrade your subscription.</p>
                                            </div>
                                            :
                                            <div style={{ "fontSize": "16px", "backgroundColor": "orange", "padding": "10px 15px 5px 10px", "color": "#f4f4f4", "marginBottom": "10px" }}>
                                                <p>Your trial peroid ends in {trialDaysleft} days.</p>
                                            </div>
                                        :
                                        ""
                                }

{
                                (status==="inactive" && plan !== "free")
                                        ?
                                        (trialDaysleft === 0)
                                            ?
                                            <div style={{ "fontSize": "18px", "backgroundColor": "orange", "padding": "10px 15px 5px 10px", "color": "#f4f4f4", "marginBottom": "10px" }}>
                                                <p>Trial period has ended and for system to continue monitoring please <Link to="/payment">click here </Link>to upgrade your subscription.</p>
                                            </div>
                                            :
                                            <div style={{ "fontSize": "16px", "backgroundColor": "orange", "padding": "10px 15px 5px 10px", "color": "#f4f4f4", "marginBottom": "10px" }}>
                                                <p>Your trial peroid ends in {trialDaysleft} days.</p>
                                            </div>
                                        :
                                        ""
                                }


                                <Stastics />
                                {loadDashboardPlan()}
                            </div>
                        </div>{/* main-panel ends */}
                    </div > {/* page-body-wrapper ends */}
                </>
            )
        }
    }

    useEffect(() => {
        dispatch(GetUpdatedCurrentUser());
    }, []);

    return (
        <>
            {isFirstUser()}
        </>
    )
}

