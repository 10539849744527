import axios from 'axios';


async function reportError(action, error) {
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if (responseMessage === "error logged successfully") {
       // console.log("error repotred successfully");
    }
}

export const VerifyPayment = (ref) => async (dispatch, getState) => {

    dispatch({
        type : 'LOADING',
        payload : true
    });
    dispatch({
        type:"PAYMENT_FALSE",
    });

    const currentUser = getState().auth.currentUser;
    try {
        const response = await axios.post('api/payments/verifypayment', {reference: ref },  {withCredentials: true});
        const responseMessage = response.data.message;
        if(responseMessage === "Payment was successfully verified"){
            dispatch({
                type:"PAYMENT_SUCCESS",
                payload:true
            });
            dispatch({
                type:"LOGIN_SUCCESS",
                payload:response.data.USER
            });
           
        }
    }
    catch (err) {
       // console.log(err)
        //reportError("AddUrl", err);
    }
    return (dispatch) => {
        // dispatch({
        //   type: 'CLEAR_LOGIN_UI_MESSAGES',
        // payload: false
        //})
    }

}

