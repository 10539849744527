import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import countryCodes from "country-codes-list";
import { continents, countries, languages, getCountryCode } from 'countries-list'
import { passwordStrength } from 'check-password-strength';
import { Tooltip } from 'react-tooltip'

import {
    GotoLoginPage,
    PasswordNotMatch,
    PasswordError,
    PasswordOK,
    PasswordWarning,
    CompanyError,
    CountryError,
    EmailError,
    PlanError,
    RegistrationStepOne,
    password_warning,
    password_ok,
    checkPasswordstrength,
} from '../actions/auth';
import HeaderEmpty from "../components/header-empty";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';



const RegisterPage = (props) => {
    const Arraycountries = []
    for (const key in countries) {
        if (countries.hasOwnProperty(key)) {
            //console.log(`${key}: ${countries[key]}`);
            Arraycountries.push(countries[key])
        }
    }
    Arraycountries.map(country => {
        //console.log(country)
    })

    useEffect(() => {
        var arry = [];
        const codes = countryCodes.customList('countryCode');
        for (let Key in codes) {
            const obj = { shortCode: Key, country: codes[Key] }
            arry.push(obj)
            setArrayCodes(arry);
        }
    }, []);

    // Get plan
    const { plan } = props;



    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState('Select your Country');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [showTerms, setShowterm] = useState(false);
    const [arrayCodes, setArrayCodes] = useState([]);
    const user_state = useSelector(state => state.auth);

    //setChoosenplan(plan)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const {
        password_not_match,
        password_error,
        password_warning,
        password_ok,
        genereated_password_error,
        company_error,
        country_error,
        email_error,
        genereated_email_errors,
        password_strength,
        password_short,
        plan_error,
        genereated_plan_errors,
    } = user_state

    var errorFlag = [];

    function _Register(e) {
        //check if password match
        if (password !== repeatPassword) {
            dispatch(PasswordNotMatch());
            errorFlag.push("password not matching");
        }
        //check password length
        if (password.length < 6) {
            dispatch(PasswordError("The password should have minimum of 6 characters"));
            errorFlag.push("password error");
        }
        if (passwordStrength(`${password}`).value === "Too weak") {
            dispatch(PasswordError("The password is too weak"));
        }

        if (passwordStrength(`${password}`).value === "Medium") {
            dispatch(PasswordWarning());
        }
        if (passwordStrength(`${password}`).value === "Strong") {
            dispatch(PasswordOK());
        }

        // check if the company is not empty
        if (company === "") {
            dispatch(CompanyError());
            errorFlag.push("company error");
        }
        if (country === "Select your Country") {
            dispatch(CountryError());
            errorFlag.push("country error");
        }
        //Check if the email is not empty and valid
        if (email === "") {
            dispatch(EmailError("Email is required"));
            errorFlag.push("email error");
        }
        if (plan === "") {
            dispatch(PlanError("plan is required"));
            errorFlag.push("plan error");
        }

        if (plan !== "free") {
            if (plan !== "basic") {
                if (plan !== "advanced") {
                    if (plan !== "complete") {
                        dispatch(PlanError("plan is unknown"));
                        errorFlag.push("plan error");
                    }
                }
            }
        }

        if (errorFlag.length === 0) {
            const data = {
                company: company,
                email: email,
                country: country,
                password: password,
                plan: plan,
                status: "trial",
                trialDaysleft: 14
            }

            //console.log(data)

            dispatch(RegistrationStepOne(data));
            //return navigate("/confirmation");
        }
    }

    function SET_PASSWORD(e) {
        dispatch(checkPasswordstrength(e.target.value));
        setPassword(e.target.value);
    }

    function goToLoginPage() {
        // dispatch(GotoLoginPage());
    }
    function handleHideTerms(e) {
        setShowterm(!showTerms);
    }

    return (
        <>
            <HeaderEmpty heading="Registration" />
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg" style={{ "backgroundColor": "#37517e", "paddingLeft": "100px" }}>
                        <div className="row flex-grow">
                            <div className="col-lg-9 d-flex align-items-center justify-content-left">
                                <div className="auth-form-transparent text-left p-3">
                                    <div className="brand-logo2" style={{ "marginTop": "10px", "marginBottom": "40px", "paddingLeft": "40px" }}>
                                        <img src="../../images/logo-small.png" width="250px" alt="logo" style={{}} /><br />
                                    </div>
                                    <div style={{ "backgroundColor": "#fff", "padding": "30px 40px", "borderRadius": "3px", "width": "90%" }}>
                                        <h4>Register Your Company</h4>
                                        <hr />
                                        <div className="form-group">
                                            <p>Selected plan <span style={{ "fontSize": "13px", "marginLeft": "21px" }}><Link onClick={handleShow}>(Click here if you want to change plan)</Link></span></p>
                                            <div className="input-group">
                                                <input type="text"
                                                    className="form-control form-control-md border-left-1"
                                                    id="exampleInputPassword"
                                                    value={plan}
                                                    disabled
                                                />
                                            </div>
                                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                                (plan_error)
                                                    ?

                                                    <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>{genereated_plan_errors}</span></div>

                                                    :
                                                    ''
                                            }
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <label>Company name</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-account-outline text-primary"></i>
                                                    </span>
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-md border-left-0"
                                                    placeholder="Enter your business name"
                                                    value={company}
                                                    onChange={(e) => setCompany(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                                (company_error)
                                                    ?
                                                    <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>Company name is required</span></div> :
                                                    ''
                                            }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='row'>
                                                <p className='col-md-2'>Email</p>
                                                <p className='col-md-2' style={{ "fontSize": "17px", "padding": "2px 0 0 0", "marginLeft": "-10px", "cursor": "pointer" }}>
                                                    <span
                                                        data-tooltip-content="Use business email rather than personal email "
                                                        data-tooltip-id="email-tooltip">
                                                        <i class="bx bx-info-circle"></i>
                                                    </span>
                                                </p>
                                            </div>
                                            <Tooltip id="email-tooltip" />
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-email-outline text-primary"></i>
                                                    </span>
                                                </div>
                                                <input type="email"
                                                    className="form-control form-control-md border-left-0"
                                                    placeholder="Enter company email address"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                                (email_error)
                                                    ?

                                                    <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>{genereated_email_errors}</span></div>

                                                    :
                                                    ''
                                            }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className='row'>
                                                <p className='col-md-2'>Country</p>
                                                <p className='col-md-2' style={{ "fontSize": "17px", "padding": "2px 0 0 2px", "cursor": "pointer" }}>
                                                    <span
                                                        data-tooltip-content="country where business is located"
                                                        data-tooltip-id="country-tooltip">
                                                        <i style={{ "fontSize": "6px" }} className="bx bx-info-circle"></i>
                                                    </span>
                                                </p>
                                            </div>
                                            <Tooltip id="country-tooltip" />
                                            <select
                                                className="form-control form-control-md"
                                                id="exampleFormControlSelect2"
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                                style={{ "marginTop": "10px", "marginBottom": "10px" }}
                                            >
                                                <option value="">Select Country</option>
                                                <option value="ZA">South Africa</option>
                                                {Arraycountries.map(country => {
                                                    return (
                                                        <option key={getCountryCode(country.name)} value={getCountryCode(country.name)}>{country.name} </option>
                                                    )
                                                })}

                                            </select>
                                        </div>
                                        <div style={{ "marginTop": "-10px", "marginBottom": "10px" }}>{
                                            (country_error)
                                                ?
                                                <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>Please select country</span></div> :
                                                ''
                                        }
                                        </div>
                                        <div className="form-group">
                                            <div className='row'>
                                                <p className='col-md-2'>Password</p>
                                                <p className='col-md-2' style={{ "cursor": "pointer", "fontSize": "17px", "padding": "2px 0 0 13px" }}>
                                                    <span
                                                        data-tooltip-content="Password should include caps,small letters and minimum characters is 7"
                                                        data-tooltip-id="password-tooltip">
                                                        <i class="bx bx-info-circle"></i>
                                                    </span>
                                                </p>
                                            </div>
                                            <Tooltip id="password-tooltip" />
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-lock-outline text-primary"></i>
                                                    </span>
                                                </div>
                                                <input type="password"
                                                    className="form-control form-control-md border-left-0"
                                                    id="exampleInputPassword"
                                                    placeholder="Enter password"
                                                    value={password}
                                                    onChange={(e) => SET_PASSWORD(e)}
                                                />
                                                {(password_strength === "Medium") ? <span style={{ "position": "absolute", "marginLeft": "260px", "zIndex": "10", "marginTop": "20px", "fontSize": "15px" }}><i className="mdi mdi-alert-circle text-warning"></i>Medium</span> : ""}
                                                {(password_strength === "Weak") ? <span style={{ "position": "absolute", "marginLeft": "260px", "zIndex": "10", "marginTop": "20px", "fontSize": "15px" }}><i className="mdi mdi-close-circle text-danger"></i>Weak</span> : ""}
                                                {(password_short) ? <span style={{ "position": "absolute", "marginLeft": "260px", "zIndex": "10", "marginTop": "20px", "fontSize": "15px" }}><i className="mdi mdi-alert-octagon text-danger"></i>Short</span> : ""}
                                            </div>

                                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                                (password_error)
                                                    ?
                                                    <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>{genereated_password_error}</span></div> :
                                                    ''
                                            }
                                            </div>
                                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                                (password_warning)
                                                    ?
                                                    <div className="badge badge-warning"><span style={{ "fontSize": "16px" }}></span>Strength : medium</div> :
                                                    ''
                                            }
                                            </div>
                                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                                (password_ok)
                                                    ?
                                                    <div className="badge badge-success"><span style={{ "fontSize": "16px" }}></span>Strength : Strong</div> :
                                                    ''
                                            }
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <p>Repeat Password</p>
                                            <div className="input-group">
                                                <div className="input-group-prepend bg-transparent">
                                                    <span className="input-group-text bg-transparent border-right-0">
                                                        <i className="mdi mdi-lock-outline text-primary"></i>
                                                    </span>
                                                </div>
                                                <input type="password"
                                                    className="form-control form-control-md border-left-0"
                                                    id="repeatInputPassword"
                                                    placeholder="Repeat password"
                                                    value={repeatPassword}
                                                    showTerms onChange={(e) => setRepeatPassword(e.target.value)}
                                                />
                                            </div>
                                            <div style={{ "marginTop": "5px", "marginBottom": "10px" }}>{
                                                (password_not_match)
                                                    ?
                                                    <div className="badge badge-danger"><span style={{ "fontSize": "16px" }}>Password do not match</span></div> :
                                                    ''
                                            }
                                            </div>
                                        </div>
                                        <div className="mb-1 mt-2">
                                            <p style={{ "fontSize": "14px" }}>By clicking on Register you agreeing on terms and conditions.</p>
                                            <p>{(showTerms === false)
                                                ? <a style={{ "color": "blue", "cursor": "pointer" }} onClick={handleHideTerms}>View terms and conditions</a>
                                                : <a style={{ "color": "blue", "cursor": "pointer" }} onClick={handleHideTerms}>Hide terms and conditions</a>
                                            }
                                            </p>

                                        </div>
                                        <div>
                                            <button
                                                onClick={_Register}
                                                value="hello world"
                                                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            >
                                                REGISTER
                                            </button>
                                            <p className="text-center mt-4 font-weight-light">
                                                Already have an account? <Link to="/login" className="text-primary" onClick={goToLoginPage}>Login</Link>
                                            </p>
                                            <br />
                                        </div>
                                        <div id="termsandconditions" className={(showTerms === false) ? 'hideterms' : 'showterms'}>
                                            <h6>Terms and conditions</h6>

                                            <hr />
                                            <div class="section-title">
                                                <h5>Terms and Conditions ("Terms")</h5>
                                                <p>Our Terms and Conditions were last updated on 19 November 2023.</p>
                                                <p>Please read these terms and conditions carefully before using Our Service.</p>
                                            </div>
                                            <div style={{ "padding": "10px", "fontWeight": "100" }}>
                                                <h5><strong>Interpretation and Definitions</strong></h5>
                                                <h6><strong>Interpretation</strong></h6>
                                                <p>
                                                    The words of which the initial letter is capitalized have meanings defined under the following conditions.
                                                    The following definitions shall have the same meaning regardless of whether they appear in singular or in
                                                    plural.
                                                </p>
                                                <h6><strong>Definitions</strong></h6>
                                                <p>For the purposes of these Terms and Conditions:</p>
                                                <ul>
                                                    <li>"Affiliate" means an entity that controls, is controlled by or is under common control with a party,
                                                        where "control" means ownership of 50% or more of the shares, equity interest or other securities
                                                        entitled to vote for election of directors or other managing authority.
                                                    </li>
                                                    <li>
                                                        "Company" (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                                                        [COMPANY_INFORMATION].
                                                    </li>
                                                    <li>"Country" refers to [COMPANY_COUNTRY].</li>
                                                    <li>"Device" means any device that can access the Service such as a computer, a cell phone or a
                                                        digital tablet.
                                                    </li>
                                                    <li>"Feedback" means feedback, innovations or suggestions sent by You regarding the attributes,
                                                        performance or features of our Service.
                                                    </li>
                                                    <li>"Service" refers to the Website.</li>
                                                    <li>"Terms and Conditions" (also referred as "Terms") mean these Terms and Conditions that form the
                                                        entire agreement between You and the Company regarding the use of the Service. This Terms and
                                                        Conditions Agreement was generated by TermsFeed Terms and Conditions Generator.</li>
                                                    <li>"Website" refers to [WEBSITE_NAME], accessible from [WEBSITE_URL]</li>
                                                    <li>
                                                        "You" means the individual accessing or using the Service, or the company, or other legal entity on
                                                        behalf of which such individual is accessing or using the Service, as applicable.
                                                    </li>
                                                </ul>
                                                <h6><strong>Acknowledgment</strong></h6>
                                                <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates
                                                    between You and the Company. These Terms and Conditions set out the rights and obligations of all users
                                                    regarding the use of the Service.
                                                </p>
                                                <p>
                                                    Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
                                                    Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or
                                                    use the Service.
                                                </p>
                                                <p>
                                                    By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
                                                    with any part of these Terms and Conditions then You may not access the Service.
                                                </p>
                                                <p>
                                                    You represent that you are over the age of 18. The Company does not permit those under 18 to use the
                                                    Service.
                                                </p>
                                                <p>
                                                    Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
                                                    Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection,
                                                    use and disclosure of Your personal information when You use the Application or the Website and tells You
                                                    about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before
                                                    using Our Service.
                                                </p>
                                                <h6><strong>Intellectual Property</strong></h6>
                                                <p>
                                                    The Service and its original content (excluding Content provided by You or other users), features and
                                                    functionality are and will remain the exclusive property of the Company and its licensors.
                                                </p>
                                                <p>
                                                    The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
                                                </p>
                                                <p>
                                                    Our trademarks and trade dress may not be used in connection with any product or service without the prior
                                                    written consent of the Company.
                                                </p>
                                                <h6><strong>Your Feedback to Us</strong></h6>
                                                <p>
                                                    You assign all rights, title and interest in any Feedback You provide the Company. If for any reason such
                                                    assignment is ineffective, You agree to grant the Company a non-exclusive, perpetual, irrevocable, royalty
                                                    free, worldwide right and license to use, reproduce, disclose, sub-license, distribute, modify and exploit
                                                    such Feedback without restriction.
                                                </p>
                                                <h6><strong>Links to Other Websites</strong></h6>
                                                <p>
                                                    Our Service may contain links to third-party web sites or services that are not owned or controlled by the
                                                    Company.
                                                </p>
                                                <p>
                                                    The Company has no control over, and assumes no responsibility for, the content, privacy policies, or
                                                    practices of any third party web sites or services. You further acknowledge and agree that the Company
                                                    shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
                                                    caused by or in connection with the use of or reliance on any such content, goods or services available on
                                                    or through any such web sites or services.
                                                </p>
                                                <p>
                                                    We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or
                                                    services that You visit.
                                                </p>
                                                <h6><strong>Termination</strong></h6>
                                                <p>
                                                    We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason
                                                    whatsoever, including without limitation if You breach these Terms and Conditions.
                                                </p>
                                                <p>
                                                    Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your
                                                    Account, You may simply discontinue using the Service.
                                                </p>
                                                <h6><strong>Limitation of Liability</strong></h6>
                                                <p>
                                                    Notwithstanding any damages that You might incur, the entire liability of the Company and any of its
                                                    suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be
                                                    limited to the amount actually paid by You through the Service or 100 USD if You haven't purchased
                                                    anything through the Service.
                                                </p>
                                                <p>
                                                    To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable
                                                    for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to,
                                                    damages for loss of profits, loss of data or other information, for business interruption, for personal injury,
                                                    loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party
                                                    software and/or third-party hardware used with the Service, or otherwise in connection with any provision of
                                                    this Terms), even if the Company or any supplier has been advised of the possibility of such damages and
                                                    even if the remedy fails of its essential purpose.
                                                </p>
                                                <p>
                                                    Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
                                                    consequential damages, which means that some of the above limitations may not apply. In these states,
                                                    each party's liability will be limited to the greatest extent permitted by law.
                                                </p>
                                                <h6><strong>"AS IS" and "AS AVAILABLE" Disclaimer</strong></h6>
                                                <p>
                                                    The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without
                                                    warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own
                                                    behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly
                                                    disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service,
                                                    including all implied warranties of merchantability, fitness for a particular purpose, title and
                                                    non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or
                                                    trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and
                                                    makes no representation of any kind that the Service will meet Your requirements, achieve any intended
                                                    results, be compatible or work with any other software, applications, systems or services, operate without
                                                    interruption, meet any performance or reliability standards or be error free or that any errors or defects can
                                                    or will be corrected.
                                                </p>
                                                <p>
                                                    Without limiting the foregoing, neither the Company nor any of the company's provider makes any
                                                    representation or warranty of any kind, express or implied: (i) as to the operation or availability of the
                                                    Service, or the information, content, and materials or products included thereon; (ii) that the Service will be
                                                    uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content
                                                    provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on
                                                    behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other
                                                    harmful components.
                                                </p>
                                                <p>
                                                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
                                                    statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You.
                                                    But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest
                                                    extent enforceable under applicable law.
                                                </p>
                                                <h6><strong>Governing Law</strong></h6>
                                                <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
                                                    Service. Your use of the Application may also be subject to other local, state, national, or international laws.
                                                </p>
                                                <h6><strong>Disputes Resolution</strong></h6>
                                                <p>
                                                    If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally
                                                    by contacting the Company.
                                                </p>
                                                <h6><strong>Severability and Waiver</strong></h6>
                                                <p>
                                                    If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
                                                    interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable
                                                    law and the remaining provisions will continue in full force and effect.
                                                </p>
                                                <h6><strong>Waiver</strong></h6>
                                                <p>
                                                    Except as provided herein, the failure to exercise a right or to require performance of an obligation under
                                                    these Terms shall not effect a party's ability to exercise such right or require such performance at any time
                                                    thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
                                                </p>
                                                <h6><strong>Changes to These Terms and Conditions</strong></h6>
                                                <p>
                                                    We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
                                                    material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
                                                    effect. What constitutes a material change will be determined at Our sole discretion.
                                                </p>
                                                <p>
                                                    By continuing to access or use Our Service after those revisions become effective, You agree to be bound
                                                    by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the
                                                    website and the Service.
                                                </p>
                                                <h6><strong>Contact Us</strong></h6>
                                                <p>
                                                    If you have any questions about these Terms and Conditions, You can contact us:
                                                </p>
                                                <ul>
                                                    <li>By visiting this page on our website: [WEBSITE_CONTACT_PAGE_URL]</li>
                                                    <li>By sending us an email: [WEBSITE_CONTACT_EMAIL]</li>
                                                </ul>
                                            </div>
                                            <hr />
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose} size={'xl'}>
                <Modal.Header closeButton>
                    <Modal.Title>Select your plan and click save button at the bottom</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="pricing" class="pricing">
                        <div className="row">
                            <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                                <div className="box">
                                    <h3>FREE</h3>
                                    <h4>
                                        <span className="currency">$</span>
                                        <span className="amount">0</span><span style={{ "fontsize": "18px", "color": "#ccc" }}> / month</span>
                                        <span className="billed">Free to use.</span>
                                        <span className="billed">For individuals.</span>
                                    </h4>

                                    <div className="divider"></div>
                                    <ul style={{ "fontSize": "100%" }}>
                                        <li><i className="bx bx-check-circle"></i>3 uptime checks per day with 4 hours interval</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>Check SSL errors & expiry date.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>Monitor ports and servers.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>Search keyword monitoring.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>Check broken links.</li>
                                        <li><i className="bx bx-check-circle"></i>Email notifications.</li>
                                        <li><i className="bx bx-check-circle"></i>Monthly Reports.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>SMS notifications.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>System support</li>
                                    </ul>

                                    <div className="btn-container">
                                        <Link to="/register?plan=free" class="buy-btn" style={{ "cursor": "pointer" }}>Select</Link>
                                    </div>
                                    <h4><span class="creditCardNotRequired">Register For Free</span></h4>
                                </div>
                            </div>


                            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                                <div className="box">
                                    <h3>BASIC</h3>
                                    <h4>
                                        <span className="currency">$</span>
                                        <span className="amount">10.99</span><span style={{ "font-size": "18px", "color": "#ccc" }}> / month</span>
                                        <span className="billed">Billed anually.</span>
                                        <span className="billed">Good for very small team.</span>
                                    </h4>

                                    <div className="divider"></div>
                                    <ul style={{ "fontSize": "98%" }}>
                                        <li><i className="bx bx-check-circle"></i> Unlimited uptime checks with 10 min interval.</li>
                                        <li><i className="bx bx-check-circle"></i>Check SSL errors & expiry date.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>Monitor ports and servers.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>Search keyword monitoring.</li>
                                        <li className="na"><i class="bx bx-x-circle"></i>Check broken links.</li>
                                        <li><i className="bx bx-check-circle"></i>Email and SMS notifications.</li>
                                        <li><i className="bx bx-check-circle"></i>Monthly Reports.</li>
                                        <li><i className="bx bx-check-circle"></i>50 SMSs per month</li>
                                        <li><i className="bx bx-check-circle"></i>Email support only</li>
                                    </ul>
                                    <div className="btn-container">
                                        <Link to="/register?plan=basic" class="buy-btn" style={{ "cursor": "pointer" }}>Select</Link>
                                    </div>
                                    <h4 style={{ marginTop: "4px", "marginBottom": "0px" }}><span className="creditCardNotRequired">14 days trial.</span></h4>
                                    <h4><span className="creditCardNotRequired">No credit card required.</span></h4>
                                </div>
                            </div>

                            <div className="col-lg-3 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
                                <div className="box featured">
                                    <h3>ADVANCED</h3>
                                    <h4>
                                        <span className="currency">$</span>
                                        <span className="amount">20.99</span><span style={{ "fontSize": "18px", "color": "#ccc" }}> / month</span>
                                        <span className="billed">Billed annually or quarterly.</span>
                                        <span className="billed">For medium businesses.</span>
                                    </h4>
                                    <div className="divider"></div>
                                    <ul style={{ "fontSize": "98%" }}>
                                        <li><i className="bx bx-check-circle"></i> Unlimited Uptime checks with 60 seconds interval.</li>
                                        <li><i className="bx bx-check-circle"></i>Check SSL errors & expiry date.</li>
                                        <li><i className="bx bx-check-circle"></i>Monitor ports and servers.</li>
                                        <li className="na"><i className="bx bx-x-circle"></i>Search keyword monitoring.</li>
                                        <li><i className="bx bx-check-circle"></i>Check broken links.</li>
                                        <li><i className="bx bx-check-circle"></i>Email and SMS notifications</li>
                                        <li><i className="bx bx-check-circle"></i>Monthly Reports.</li>
                                        <li><i className="bx bx-check-circle"></i>100 SMSs per month</li>
                                        <li><i className="bx bx-check-circle"></i>Email and call support.</li>
                                    </ul>

                                    <div className="btn-container">
                                        <Link to="/register?plan=advanced" class="buy-btn" style={{ "cursor": "pointer" }}>Select</Link>
                                    </div>
                                    <h4 style={{ marginTop: "4px", "marginBottom": "0px" }}><span className="creditCardNotRequired">14 days trial.</span></h4>
                                    <h4><span className="creditCardNotRequired">No credit card required.</span></h4>
                                </div>
                            </div>

                            <div className="col-lg-3 mt-3 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
                                <div className="box">
                                    <h3>COMPLETE</h3>
                                    <h4>
                                        <span className="currency">$</span>
                                        <span className="amount">49.99</span><span style={{ "fontSize": "18px", "color": "#ccc" }}> / month</span>
                                        <span className="billed">Billed annually or quarterly.</span>
                                        <span className="billed">For large organizations. </span>
                                    </h4>
                                    <div class="divider"></div>
                                    <ul style={{ "fontSize": "98%" }}>
                                        <li><i className="bx bx-check-circle"></i>Unlimited Uptime checks with 60 seconds interval.</li>
                                        <li><i className="bx bx-check-circle"></i>Check SSL errors & expiry date.</li>
                                        <li><i className="bx bx-check-circle"></i>Monitor ports and servers.</li>
                                        <li><i className="bx bx-check-circle"></i>Search keyword monitoring.</li>
                                        <li><i className="bx bx-check-circle"></i>Check broken links.</li>
                                        <li style={{ "fontSize": "100%" }}><i class="bx bx-check-circle"></i>All notifications & whatsapp</li>
                                        <li><i className="bx bx-check-circle"></i>Monthly Reports.</li>
                                        <li><i className="bx bx-check-circle"></i>Unlimited SMS Notifications</li>
                                        <li><i className="bx bx-check-circle"></i>Email and call support.</li>
                                    </ul>
                                    <div className="btn-container">
                                        <Link to="/register?plan=complete" class="buy-btn" style={{ "cursor": "pointer" }}>Select</Link>
                                    </div>
                                    <h4 style={{ marginTop: "4px", "marginBottom": "0px" }}><span className="creditCardNotRequired">14 days trial.</span></h4>
                                    <h4><span className="creditCardNotRequired">No credit card required.</span></h4>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RegisterPage;