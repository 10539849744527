import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../components/sidebar';
import Stastics from '../components/stasticsHeader';
import Header from '../components/header';

const SystemStatusPage = () => {


  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;

  return (
    <>
       <Header plan={plan} />
      <div className="container-fluid page-body-wrapper">
      <Sidebar plan={plan} />
      <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <div className="card-title">System status</div>
                  <div className="card-body">
                    <div className="card-description wrapperLine" style={{ "marginTop": "10px", "marginBottom": "20px", "height": "auto" }}>

                      
                      
                     



                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default SystemStatusPage;