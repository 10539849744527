import axios from 'axios';

async function reportError(action, error){
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if(responseMessage === "error logged successfully"){
       // console.log("error repotred successfully");
    }
}


const isValidUrl = urlString => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e){
        return false;
    }
}



export const GetUpdatedCurrentUser = (obj) => async (dispatch, getState) => {

    //alert("called")
   const currentUser = getState().auth.currentUser

  // const response = await axios.post('/api/account/getUser', { user:currentUser });
   const response = await axios.post('/api/account/getUser', { user:currentUser });
   if(response.message ==="user retrival successfuly"){
     
    // console.log(response.user)
   }
}


// Login action
export const Update = (obj) => async (dispatch, getState) => {
    const currentUser = getState().auth.currentUser;

    dispatch({
        type: "GETTING_STARTED_CHECKING"
    });
     
    try {
        //console.log(obj)
        function formatURL(theUrl) {
            //console.log(isValidUrl(weburl)); 
            let text = `${theUrl}`
            let position = text.search("https://");
            if (position === -1) {
                //check if starts with http
                let position2 = text.search("http://");
                if (position2 === -1) {
                    let position3 = text.search("www.");
                    if (position3 === -1) {
                        // url is invalid 
                        return "invalid url"
                    } else {
                        // add http to the url
                        return `https://${text}`
                    }
                } else {
                    // final url
                    return text;
                }
            } else {
                // final url
                return text;
            }
        }

        const final_url = formatURL(obj.weburl);

        if (final_url !== "invalid url") {
            const response = await axios.post('api/account/updateAccount', { currentUser, url: final_url, phone : obj.notification_num });
            const responseMessage = response.data.message;

            if (responseMessage === 'invalid url') {
                // invalid url
                dispatch({
                    type: "GETTING_STARTED_URL_INVALID",
                    payload: true
                });
                setTimeout(() => {
                    dispatch({
                        type: 'GETTING_STARTED_URL_INVALID',
                        payload: false
                    })
                }, 7000)
            }

            if (responseMessage === 'adding url failed') {
                // could not find the website
                dispatch({
                    type: "GETTING_STARTED_URL_INVALID",
                    payload: true
                });
                setTimeout(() => {
                    dispatch({
                        type: 'GETTING_STARTED_URL_INVALID',
                        payload: false
                    })
                }, 7000);
            }

            if (responseMessage === "login failed") {
                dispatch({
                    type: 'GETTING_STARTED_SUCCESS',
                });
                setTimeout(() => {
                    dispatch({ type: 'CLEAR_LOGIN_UI_MESSAGES' })
                }, 3000)
            }
            if (responseMessage === "user updated successful") {
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    payload: response.data.updatedUser
                });
            }
        }
        if (final_url === "invalid url") {
            // url invalid
            dispatch({
                type: "GETTING_STARTED_URL_INVALID",
                payload: true
            });
            setTimeout(() => {
                dispatch({
                    type: 'GETTING_STARTED_URL_INVALID',
                    payload: false
                })
            }, 7000)
        }
    }
    catch (err) {
        reportError("Update", err);
    }
}
