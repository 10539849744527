export default (state = { down_report : {}, links_report : {}, ports_report : {}}, action) => {
    if (action.type === "DOWN_REPORT") {
        return {
            ...state,
            down_report: action.payload
        }
    }
    else if(action.type === "LINKS_REPORT") {
        return {
            ...state,
            links_report: action.payload
        }
    }
    else if(action.type === "PORTS_REPORT") {
        return {
            ...state,
            ports_report: action.payload
        }
    }
    else {
        return state;
    }
}