export default (state = { phones: [], phone:[] }, action) => {

    if (action.type === "PHONES"){
        return {
            ...state,
            phones: action.payload
        }
    }
    else if(action.type ==="PHONE"){
        return {
            ...state,
            phone : action.payload
        }
    }
    else {
        return state;
    }
}