export default (state = {
site_health_data: {
        performance: 0,
        accessibility: 0,
        seo: 0,
        bestPractices: 0
    },
    audits : []

}, action) => {
    if (action.type === "SITE_HEALTH_CHART"){
        return {
            ...state,
            site_health_data: action.payload
        }
    }
    else if (action.type === "AUDITS") {
        return {
            ...state,
            audits: action.payload
        }
    }
    else {
        return state;
    }

}