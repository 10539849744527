import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ResponsivePie } from '@nivo/pie';
import { Line, ResponsiveLine } from '@nivo/line'
import { area, curveMonotoneX } from 'd3-shape'
import { generateDrinkStats } from '@nivo/generators'
import { ResponsiveBump } from '@nivo/bump'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Tooltip } from 'primereact/tooltip';
import 'react-tabs/style/react-tabs.css';
import { LinksChart } from '../actions/links';
import { UpTimeChart, TheStatus } from '../actions/uptime';
import { SearchRankChart } from '../actions/search';
import { GetPorts } from '../actions/ports';
import { GetSSLDaysLeft, GET_SSL_ERRORS_COUNT } from '../actions/ssl';


const DashboardBasicPlan = (props) => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LinksChart());
    dispatch(UpTimeChart());
    dispatch(TheStatus());
    dispatch(SearchRankChart());
    dispatch(GetPorts());
  }, [SearchRankChart]);

  const linksChartData = useSelector(state => state.links_list.links_chart_data);
  const uptimeChartData = useSelector(state => state.uptime_list.uptime_chart_data);
  const uptime_status = useSelector(state => state.uptime_list.uptime_status);
  const rank_data = useSelector(state => state.search_rank_list.rank_data);
  const ports = useSelector(state => state.ports_list.ports);
  const user_state = useSelector(state => state.auth);
  const SSL = useSelector(state => state.ssl_errors_list.expiry);
  const DAYS_LEFT = useSelector(state => state.ssl_errors_list.days_left);
  const SSL_ERRORS = useSelector(state => state.ssl_errors_list.ssl_error_count);
  const { currentUser } = user_state;


  function upChart() {
    return (
      uptimeChartData.slice(0, 13).map(item => {
        return <>
          <div
            style={{ "display": "inline" }}
            className={
              (item.status === "UP") ? "circleUp boxinside" : "circleDown boxinside"
            }
            key={item._id}
          >
            <span
              style={{ "marginTop": "35px", "position": "absolute", "fontSize": "9px", "marginLeft": "1px" }}>
              {new Date(item.createdAt).getHours()}:00
            </span>
          </div>
        </>
      })
    )
  }

  function StatusText() {

    if (uptime_status.length > 0) {
      return (
        <>

          <div
            className={(uptime_status[0].status === "UP") ? "circleBlue" : "circleRed"}
          >
          </div>
          <p style={{ "textAlign": "center", "marginBottom": "30px", "marginTop": "40px" }}>
            STATUS : {(uptime_status[0].status === "UP") ? "UP" : "DOWN"}
          </p>
        </>
      )

    } else {
      return (
        <>
          <p style={{ "textAlign": "center", "marginBottom": "20px", "marginTop": "0px" }}>Down</p>
          <div className="circleRed"></div>
        </>
      )
    }
  }

  useEffect(() => {
    dispatch(GetSSLDaysLeft());
    dispatch(GET_SSL_ERRORS_COUNT());
  }, []);


  function getSSLInfo(){

    if (SSL === 'none') {
      return (
        <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>Retreiving SSL infomation...</h3>
      )
    }
    else if (SSL === "ssl is not installed") {
      return <h3 style={{ "color": "#ccc", "textAlign": "center", "marginTop": "40px" }}>SSL NOT Detected</h3>
    }
    else {
      return (
        <div className="card-body row">
          <div className='col-md-7'>
            <p  className='certificateExpiry'>Certificate  {(DAYS_LEFT > 0) ? " expires in" : ""} </p>
            <div className='expiryText'>
              <p>{(DAYS_LEFT > 0) ? <span style={{"marginLeft":"60px", "paddingTop":"10px"}}>{`${DAYS_LEFT} days`}</span> : <span style={{ 'color': 'red' }}>Expired</span>}</p>
              <div className={(DAYS_LEFT > 0) ? "certificate_expires_underline" : "underline2"}></div>
            </div>
          </div>
          <div className='col-md-5'>
            <p className='certificateErrors'> {(SSL_ERRORS === 0) ? <span style={{"marginLeft":"5px"}}>No errors</span> : 'Errors found'}</p>
            <div className='ssl-error'>
              {(SSL_ERRORS === 0) ? <i className='mdi mdi-check-circle text-success ml-4'></i> : <span style={{ "color": "red", "marginLeft": "35px" }}> {SSL_ERRORS} </span>}
              <div className={(SSL_ERRORS > 0) ? "underline2" : "certificate_expires_no_errors"}></div>
            </div>
          </div>
          {/*<p className='issued'>Certificate issued by <strong>Security Pack</strong> on <strong>12/23/2021</strong></p>*/}
        </div>
      );
    }
  }

  const ssl_errors = 0;
  const ssl_days = 500;

  return (
    <>
      <div className="row">
        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Up Time</p>
            <div className="card-body">

              {

                (props.status === "active") ?

                  <>
                    {StatusText()}
                    < hr style={{ "marginTop": "5px" }} />
                    <div className='wrapperLine'>
                      {upChart()}
                    </div>
                  </>
                  :
                  <di>
                    <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                      <span>Uptime service is not running</span>
                      <i
                        style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                        className="mdi mdi-alert-circle text-warning">
                      </i>
                    </p>

                    <p
                      style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                    >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
                  </di>

              }


            </div>
          </div>
        </div>

        <div className="col-md-6 grid-margin stretch-card">
          <div className="card">
            <p className="card-title">Secure Sockets Layer (SSL)</p>
            {(props.status === "active") ?

              <>
                {getSSLInfo()}
              </>
              :
              <di>
                <p style={{ textAlign: "center", fontSize: "20px", padding: "90px 0px 1px 0", color: "#ffa500" }}>
                  <span>SSL service is not running</span>
                  <i
                    style={{ textAlign: "center", fontSize: "22px", padding: "0px 0px 0px 2px" }}
                    className="mdi mdi-alert-circle text-warning">
                  </i>
                </p>

                <p
                  style={{ textAlign: "center", fontSize: "14px", padding: "0px 0px 100px 0", color: "#747474" }}
                >either trial period has ended or subscription has not been paid, <Link to="/payment">click here </Link> to go to payments.</p>
              </di>

            }
          </div>
        </div>

      </div>
    </>
  )
}
export default DashboardBasicPlan;