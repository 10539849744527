import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import Sidebar from '../../components/sidebar';
import Stastics from '../../components/stasticsHeader';
import Header from '../../components/header';
import { GetReport, GetLinksReport, GetPortsReport } from '../../actions/reports';
import { GetPorts } from '../../actions/ports';

const ReportsPageSSL = () => {


  const [status, setStatus] = useState('all');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [server, setServer] = useState("select server");
  const [brokenLinksStartDate, setbrokenLinksStartDate] = useState(new Date());
  const [brokenLinksEndDate, setbrokenLinksEndDate] = useState(new Date());

  const dispatch = useDispatch();
  const downtimereport = useSelector(state => state.report.down_report);
  const linksreport = useSelector(state => state.report.links_report);
  const portsreport = useSelector(state => state.report.ports_report);
  const ports = useSelector(state => state.ports_list.ports);

  const user_state = useSelector(state => state.auth);
  const { plan } = user_state.currentUser;
  

  if(portsreport.length > 0){
    portsreport.map( portreport => {
      console.log(portreport)
    })
  }

  function generateReport() {
    dispatch(GetReport(startDate, endDate));
  }
  function generateLinksReport() {
    dispatch(GetLinksReport(brokenLinksStartDate, brokenLinksEndDate));
  }
  function generatePortReport(){
    if(server !== "select server"){
      dispatch(GetPortsReport(brokenLinksStartDate, brokenLinksEndDate, server));
    }else{
      alert("Please select server")
    }
    
  }

  

  function getTotalDownTime(){
    if (Object.keys(downtimereport).length !== 0) {
      var DAYS, HOURS, MINUTES = ""
      if (downtimereport.TotalResponseTime.days == 0) {
        DAYS = ""
      } else {
        DAYS = `${downtimereport.TotalResponseTime.day}days `
      }
      if (downtimereport.TotalResponseTime.hours == 0) {
        HOURS = ""
      } else {
        HOURS = `${downtimereport.TotalResponseTime.hours}hrs `
      }
      if (downtimereport.TotalResponseTime.minutes == 0) {
        MINUTES = ""
      } else {
        MINUTES = `${downtimereport.TotalResponseTime.minutes}min `
      }
      const combined = DAYS + HOURS + MINUTES

      return (
        <>
          {combined}
        </>
      )
    }
  }


  function getAverageTotalDownTime() {
    if (Object.keys(downtimereport).length !== 0) {
      var DAYS, HOURS, MINUTES = ""
      if (downtimereport.AverageResponseTime.days == 0) {
        DAYS = ""
      } else {
        DAYS = `${downtimereport.AverageResponseTime.day}days `
      }
      if (downtimereport.AverageResponseTime.hours == 0) {
        HOURS = ""
      } else {
        HOURS = `${downtimereport.AverageResponseTime.hours}hrs `
      }
      if (downtimereport.AverageResponseTime.minutes == 0) {
        MINUTES = ""
      } else {
        MINUTES = `${downtimereport.AverageResponseTime.minutes}min `
      }
      const Combined = DAYS + HOURS + MINUTES

      return (
        <>
          {Combined}
        </>
      )
    }
  }


  useEffect(() => {
    dispatch(GetPorts());
    //if (port.length > 0) {
      //firstInput(port[0].portName)
    //}
  }, []);


  return (
    <>
      <Header plan={plan}/>
      <div className="container-fluid page-body-wrapper">
        <Sidebar plan={plan}/>
        <div className="main-panel">
          <div className="content-wrapper">

            <div className="row">
              <div className="col-md-12 stretch-card">
                <div className="card">
                  <p className="card-title">SSL Report</p>
                  <div className="card-body">
                    <div className="table-responsive">


                      <div className="boxinside">
                        <span style={{ "fontSize": "14px" }}>Start date</span><br />
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <div className="boxinside" style={{ "marginLeft": "10px", "marginRight": "10px" }} >
                        <span style={{ "fontSize": "14px" }}>End date</span><br />
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" dateFormat="yyyy/MM/dd" />
                      </div>

                      <p className="boxinside"  style={{"marginTop":"15px"}}>
                        <br />
                        <button type="button" onClick={generateReport} className="btn btn-inverse-primary btn-fw">Generate Report</button>
                      </p>

                    </div>

                    <div style={{ "BorderBottom": " 1px solid #ccc", "margin": "50px 0" }}>

                      <h6>Total Down Time : {getTotalDownTime()}
                      </h6>




                      { /*
                      <h5>
                       Average Down Time : {getAverageTotalDownTime()} 
                      </h5>
                      */}
                    </div>

                    <div style={{ "marginTop ": "60px" }} className="table-responsive">
                      <table className="table table-bordered table-striped">
                      <thead>
                          <tr>
                            <th>
                              <div style={{ "position": "relative" }}>
                                <div style={{ "border": "0px", "height": "5px", "marginLeft": "40px", "position": "absolute", "top": "8px" }}>Down</div>
                              </div>
                              <div style={{ "border": "0px" }}>
                                <div style={{ "backgroundColor": "red", "height": "30px", "width": "30px", "borderRadius": "15px" }}>
                                </div>
                              </div>
                            </th>
                            <th>
                              <div style={{ "position": "relative" }}>
                              <div style={{ "border": "0px", "height": "5px", "marginLeft": "40px", "position": "absolute", "top": "8px" }}>UP</div>
                              </div>
                              <div style={{ "border": "0px" }}>
                                <div style={{ "backgroundColor": "green", "height": "30px", "width": "30px", "borderRadius": "15px" }}>
                                </div>
                              </div>
                            </th>
                            <th><div style={{ "border": "0px" }}>Down Time</div></th>

                          </tr>
                        </thead>
                        <tbody>
                          {(Object.keys(downtimereport).length === 0 && downtimereport.constructor === Object)
                            ?
                            <></>
                            :
                            downtimereport.downtimers.map(item => {
                              const { Added, created } = item;

                              const localtime_added = new Date(Added);
                              const ly = localtime_added.getFullYear();
                              const lm = parseInt(localtime_added.getMonth()) + 1;
                              const ld = localtime_added.getDate();
                              const lh = localtime_added.getHours()
                              const lmi = localtime_added.getMinutes()

                              const localtime_created = new Date(created);
                              const cy = localtime_created.getFullYear();
                              const cm = parseInt(localtime_created.getMonth()) + 1;
                              //const cd = localtime_created.getDay();
                              const cd = localtime_created.getDate()
                              const ch = localtime_created.getHours();
                              const cmi = localtime_created.getMinutes();
                              localtime_created.getHours();
                              localtime_created.getMinutes();

                              return (
                                <tr key={item._id}>
                                  <td>{`Date ${cd}/${cm}/${cy} `} {`${"     "}`}   {`Time ${ch}:${cmi}`}</td>
                                  <td>{`Date ${ld}/${lm}/${ly}`} {`${"     "}`}   {`Time ${lh}:${lmi}`}</td>
                                  <td>
                                    {(item.Duration.days == 0) ? "" : `${item.Duration.days} days `}
                                    {(item.Duration.hours == 0) ? "" : `${item.Duration.hours} hrs `}
                                    {(item.Duration.minutes == 0) ? "" : `${item.Duration.minutes} min `}
                                    {(item.Duration.seconds == 0) ? "" : `${item.Duration.seconds} sec`}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>{/* main-panel ends */}
      </div>{/* page-body-wrapper ends */}
    </>
  )
}

export default ReportsPageSSL;