import axios from 'axios';

async function reportError(action, error){
    const response = await axios.post('/api/errors/report', {
        action: action,
        err: error
    });
    const responseMessage = response.data.message;
    if(responseMessage === "error logged successfully"){
        //console.log("error repotred successfully");
    }
}

export const GetStastics = () => async (dispatch) => {

    try {
        const response = await axios.post('api/stastics/get', { withCredentials: true });
        //const responseMessage = response.data.AddUrlMessage;
        //console.log(responseMessage);
        //console.log(response.data)

        dispatch({
            type: 'STASTICS',
            payload: response.data
        });

    }
    catch (err) {
        reportError("GetStastics", err);
    }
}

